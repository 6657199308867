import React, { useEffect, useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import Index from "../../index";
import { agencyLogout } from "../../../redux-toolkit/slice/agency-slice/AgencySlice";
import { logout } from "../../../redux-toolkit/slice/admin-slice/AdminSlice";
import PagesIndex from "../../../container/PageIndex";
import { ADMIN_IMAGE_ENDPOINT } from "../../../config/DataService";
import { Badge } from "@mui/material";

export default function Header(props) {
  // for open in header profile menu
  const dispatch = PagesIndex.useDispatch();
  const navigate = PagesIndex.useNavigate();
  const { adminLoginData } = PagesIndex.useSelector(
    (state) => state.AdminSlice
  );

  const unreadNotificationCount = localStorage.getItem("notificationcount");

  const [anchorEl, setAnchorEl] = React.useState(null);
  // const [unreadNotificationCount, setUnreadNotificationCount] = React.useState("");
  const [notification, setNotification] = React.useState([]);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const StyledMenu = styled((props) => (
    <Index.Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 3,
      marginTop: theme.spacing(1),
      minWidth: 258,
      paddingTop: 30,
      paddingBottom: 0,
      paddingLeft: 23,
      marginTop: 38,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow: "0px 3px 6px #00000029",
      "& .MuiMenu-list": {
        padding: "0px 0",
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        "&:active": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  }));

  const handleAdminlogout = () => {
    dispatch(logout());
    PagesIndex.toast.success("Logged out successfully");
    localStorage.removeItem("AdminToken");
    navigate("/admin");
  };


  const handleNotification = () => {
    navigate("/admin/notification")
  }

  const getNotification = async () => {
    try {
      const res = await PagesIndex.DataService.get(
        PagesIndex.Api.Admin.GET_NOTIFICATION_HISTORY
      );
      if (res.status === 200) {
        setNotification(res?.data?.data);
        let unreadCount = res?.data?.data.filter((data) => !data?.isRead)?.length
        localStorage.setItem("notificationcount", unreadCount)
        // setUnreadNotificationCount(
        //   res?.data?.data.filter((data) => !data?.isRead)?.length
        // );
      } else {
        // PagesIndex.toast.error(res?.message);
      }
    } catch (error) {
      PagesIndex.toast.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    if (adminLoginData?.roleId?.Permission_name == "Admin") {

      getNotification();
    }
  }, []);

  // console.log("updateCount",updateCount)
  return (
    <>
      <Index.Box
        className={`admin-header-main ${props.open ? "active" : "admin-header-deactive"
          }`}
      >
        <Index.Box className="admin-header-left">
          <Index.Box className="admin-header-logo-main">
            <img
              src={PagesIndex.Png.logo}
              className="admin-sidebar-logo"
              alt="logo"
            />
            <Index.Button
              className="admin-bergur-button"
              onClick={() => {
                props.setOpen(!props.open);
                document.body.classList[props.open ? "add" : "remove"](
                  "body-overflow"
                );
              }}
            >
              <img
                src={PagesIndex.Svg.berguremenu}
                className="bergurmenu-icon"
                alt="dashboard logo"
              />
            </Index.Button>
          </Index.Box>
          <Index.Box className="admin-header-right">

            {adminLoginData?.roleId?.Permission_name == "Admin" ? (
              <Index.Button className="btn icon-box" onClick={handleNotification}>
                <Badge
                  badgeContent={unreadNotificationCount ? `${unreadNotificationCount}` : "0"}
                  color={unreadNotificationCount !== 0 ? "secondary" : "primary"}
                >
                  <img
                    src={PagesIndex.Svg.notification}
                    className="admin-header-icon"
                    alt="dashboard logo"
                  />
                </Badge>
              </Index.Button>
            ) : null}



            {/* <Index.Link
              to="/admin/notification"
              className="admin-header-icon-box"
            >
              <img
                src={PagesIndex.Svg.notification}
                className="admin-header-icon"
                alt="dashboard logo"
              />
            </Index.Link> */}
            <Index.Box className="admin-header-drop-main">
              <Index.Box
                disableRipple
                className="drop-header-btn"
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                <Index.Box className="flex-drop-main">
                  <img
                    src={
                      adminLoginData?.image
                        ? `${ADMIN_IMAGE_ENDPOINT}${adminLoginData?.image}`
                        : PagesIndex.Png.usericon
                    }
                    className="admin-header-profile-icon"
                    alt="dashboard bell icon"
                  />
                  <Index.Box className="title-admin-drop">
                    <Index.Typography
                      variant="h5"
                      component="h5"
                      className="admin-header-drop"
                    >
                      {adminLoginData?.name}
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            {/* <Index.Menu
              className="drop-header-menu"
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            > */}
            {/* <Index.MenuItem className="drop-header-menuitem"
                onClick={() => {
                  handleClose();
                  navigate("/admin/adminprofile");
                }}
              >
                <img
                  src={PagesIndex.Svg.profileConfig}
                  className="drop-header"
                  alt=""
                />
                Profile
              </Index.MenuItem>
              <Index.MenuItem
                onClick={() => {
                  handleClose();
                  handleAdminlogout();
                }}
                className="drop-header-menuitem"
              >
                <img src={PagesIndex.Svg.exit} className="drop-header" alt="" />
                Sign Out
              </Index.MenuItem> */}
            {/* </Index.Menu> */}
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
