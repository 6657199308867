import React, { useEffect, useState } from 'react';
import Index from '../../..';
import Loader from '../../../../common/loader/Loader';
import DataService from '../../../../config/DataService';
import PagesIndex from '../../../../components/PagesIndex';
import ChatModal from '../agencyjobmanagement/jobmodal/ChatModal';


export default function JobchatHistory() {
  const [loading, setLoading] = useState(false);
  const [chatHistory, setChatHistory] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [chatId, setChatId] = useState("");
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };


  const fetchChatHistory = async () => {
    try {
      setLoading(true);
      const response = await DataService.get(
        `${PagesIndex.Api.Agency.CHAT_HISTORY}`);
      if (response?.data?.status === 200) {
        setLoading(false);
        setChatHistory(response?.data?.data)
      }
    } catch (error) {
      setLoading(false);
      console.log("Error", error);
    }
  }

  useEffect(() => {
    fetchChatHistory();
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Index.Box className="tabpanel-main tabpanel-2">
          {chatHistory.length ? (
            chatHistory.map((item, index) => (
              <>
                <Index.Box className="common-card job-mamangment-card">
                  <Index.Box className="admin-sub-flex">
                    <Index.Box className="admin-sub-title-main admin-sub-title-flex">
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="admin-sub-title"
                      >
                        Job Title :&nbsp;
                      </Index.Typography>
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="admin-sub-title-value-text"
                      >
                        {item?.jobName}
                      </Index.Typography>
                    </Index.Box>

                    <Index.Box className="common-btn-flex">
                      <Index.Box className="progress-job-box">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="status_tag"
                        // className={
                        //   item?.status === "APPROVED"
                        //     ? "status_tag"
                        //     : item?.status === "REJECTED"
                        //       ? "status_red"
                        //       : "status_blue"
                        // }
                        >
                          {item?.status || "-"}
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box>
                        <Index.PrimaryButton
                          btnLabel="Chat"
                          onClick={() => {
                            toggleModal();
                            setChatId(item?.jobId);
                            setSelectedData(item);
                          }}
                          className="btn-primary"
                        />
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>

                </Index.Box>
              </>
            ))
          ) : (
            <PagesIndex.NoDataFound />
          )}
          {isModalOpen && (
            <ChatModal
              open={isModalOpen}
              onClose={toggleModal}
              chatId={chatId}
              setChatId={setChatId}
              tabName={3}
              selectedData={selectedData}
            />
          )}
        </Index.Box>
      )}

    </>
  )

}
