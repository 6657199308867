import React, { useEffect, useRef, useState } from "react";
import Index from "../../..";
import PagesIndex from "../../../PageIndex";
import Loader from "../../../../common/loader/Loader";
import {AddRemarkSchema } from "../../../../validation/FormikSchema"

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
};

const RejectProfileModel = (props) => {
    const {
        pandingRequestopen,
        handleClosePandingRequest,
        approveRejectHandle,
    } = props;

    const formRef = useRef();
    const [loading, setLoading] = useState(false);
    const NUMERIC_REGEX_ONLY = /^[a-zA-Z ]+$/;
    const initialValues = {
        textarea: ""
    };

    return (
        <>
            <Index.Modal
                open={pandingRequestopen}
                onClose={handleClosePandingRequest}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="modal"
            >
                <PagesIndex.Formik
                    initialValues={initialValues}
                    innerRef={(f) => (formRef.current = f)}
                    // onSubmit={handleApproveRequest}
                    onSubmit={() => approveRejectHandle(false, "REJECTED", formRef.current.values.textarea.trim())}
                    validationSchema={AddRemarkSchema}
                >
                    {({
                        values,
                        errors,
                        handleChange,
                        handleBlur,
                        touched,
                        handleSubmit,
                        setFieldValue,
                        setFieldTouched,
                    }) => (
                        <form onSubmit={handleSubmit}>
                            {console.log(values, "fatmama")}
                            {console.log(errors, "errors")}
                            <Index.Box sx={style} className="add-user-modal-inner-main modal-inner">
                                {loading ? (
                                    <Loader />
                                ) : (
                                    <Index.Box>
                                        <Index.Box className="modal-header">
                                            <Index.Typography
                                                id="modal-modal-title"
                                                className="modal-title"
                                                variant="h6"
                                                component="h2"
                                            >
                                                Add Reason
                                            </Index.Typography>
                                            <img
                                                src={PagesIndex.Svg.closeblack}
                                                className="modal-close-icon"
                                                onClick={handleClosePandingRequest}
                                                alt=""
                                            />
                                        </Index.Box>
                                        <Index.Box className="modal-body">
                                                <Index.Box className="form-group">
                                                    <Index.TextField
                                                        className="form-control-textarea"
                                                        name="textarea"
                                                        value={values.textarea}
                                                        onChange={handleChange}
                                                        //  onKeyDown={(e) => {
                                                        //   if (e.key === " " && e.target.value.trim() === "") {
                                                        //     e.preventDefault(); // Prevent space as the first character
                                                        //   } else if (
                                                        //     e.target.value.trim().split(" ").length >= 2 &&
                                                        //     e.key === " "
                                                        //   ) {
                                                        //     e.preventDefault(); // Prevent adding additional spaces between words
                                                        //   } else if (
                                                        //     e.key === " " &&
                                                        //     e.target.value.endsWith(" ")
                                                        //   ) {
                                                        //     e.preventDefault(); // Prevent additional spaces at the end
                                                        //   }
                                                        //   if (!NUMERIC_REGEX_ONLY.test(e.key)) {
                                                        //     e.preventDefault(); // Prevent non-numeric characters
                                                        //   }
                                                        // }}
                                                        error={
                                                            errors.textarea && touched.textarea ? true : false
                                                        }
                                                        helperText={
                                                            errors.textarea && touched.textarea
                                                                ? errors.textarea
                                                                : null
                                                        }
                                                    />
                                                </Index.Box>
                                                <Index.Box className="modal-footer">
                                                    <Index.Box className="modal-footer-btn-flex">
                                                        <Index.PrimaryButton
                                                            btnLabel="Submit"
                                                            className="btn-primary bg-approve"
                                                            type="submit"
                                                        // onClick={() => handleApproveRequest("REJECTED",pendingjobId?._id)}
                                                        // disabled={isLoading}
                                                        >
                                                            {/* {isLoading ? <Loader /> : "Click Me"} */}
                                                        </Index.PrimaryButton>
                                                        <Index.PrimaryButton
                                                            btnLabel="Cancel"
                                                            className="btn-primary bg-suspend"
                                                            onClick={handleClosePandingRequest}
                                                        />
                                                    </Index.Box>
                                                </Index.Box>
                                        </Index.Box>
                                    </Index.Box>
                                )}
                            </Index.Box>
                        </form>
                    )}
                </PagesIndex.Formik>
            </Index.Modal>
        </>
    );
};

export default RejectProfileModel;
