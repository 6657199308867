import React, { useEffect, useState } from "react";
import Index from "../../../..";
import PagesIndex from "../../../../PageIndex";
import dayjs from "dayjs";
import Loader from "../../../../../common/loader/Loader";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};
const PendingJobModal = (props) => {
  const {
    pandingRequestopen,
    handleClosePandingRequest,
    pendingjobId,
    getJobListData,
    handleSupervisorRequest,
    handleEditPandingRequest
  } = props;
  const { agencyLoginData } = PagesIndex.useSelector(
    (state) => state.AgencySlice
  );

  const AgencyPermission = agencyLoginData?.roleId?.AgencyPermission;
  const roleName = agencyLoginData?.roleId?.Permission_name;

  const [jobList, setJobList] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [approveRejectStatus, setapproveRejectStatus] = useState(false);
  const paymentType = localStorage.getItem(`${agencyLoginData?._id}/agencypayment`);

  useEffect(() => {
    setLoading(true);
    if (pandingRequestopen) {
      PagesIndex.DataService.get(
        PagesIndex.Api.Agency.GET_SINGLE_JOB_LIST + `/${pendingjobId?._id}`
      )
        .then((res) => {
          if (res?.data?.status === 200) {
            setJobList(res?.data?.data);
          }
          setLoading(false);
        })
        .catch((err) => {
          PagesIndex.toast.error(err?.response?.data?.message);
        });
    }
  }, [pendingjobId]);
  //pendingjobId , pandingRequestopen

  // job approverd / Rejected
  const handleApproveRequest = (status) => {
    // if (!paymentType && roleName == "Department") {
    //   PagesIndex?.toast?.error("Please select your payment type from profile section");
    // }

    // return false;

    setIsLoading(true);
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("id", pendingjobId?._id);
    urlEncoded.append("status", status);
    PagesIndex.DataService.post(
      PagesIndex.Api.Agency.JOB_APPROVE_REJECT,
      urlEncoded
    )
      .then((res) => {
        if (res?.data?.status === 200) {
          setapproveRejectStatus(true);
          PagesIndex?.toast?.success(res?.data?.message);
          handleClosePandingRequest();
          getJobListData();
          setIsLoading(false);
        }
      })
      .catch((err) => {
        PagesIndex?.toast?.error(err?.response?.data?.mes);
        setIsLoading(false);
      });
  };

  return (
    <>
      <Index.Modal
        open={pandingRequestopen}
        onClose={handleClosePandingRequest}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal"
      >
        <Index.Box sx={style} className="add-user-modal-inner-main modal-inner">
          {loading ? (
            <Loader />
          ) : (
            <Index.Box>
              <Index.Box className="modal-header">
                <Index.Typography
                  id="modal-modal-title"
                  className="modal-title"
                  variant="h6"
                  component="h2"
                >
                  Pending Job Requests (From Users)
                </Index.Typography>
                <img
                  src={PagesIndex.Svg.closeblack}
                  className="modal-close-icon"
                  onClick={handleClosePandingRequest}
                  alt=""
                />
              </Index.Box>
              <Index.Box className="modal-body">
                <Index.Box className="card-left">
                  <Index.Box className="work-title-flex">
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-title-lable"
                    >
                      Job Title :&nbsp;
                    </Index.Typography>
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-value-text"
                    >
                      {jobList?.title}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="work-title-flex">
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-title-lable"
                    >
                      Job Id :&nbsp;
                    </Index.Typography>
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-value-text"
                    >
                      {jobList?.aliasJobId}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="work-title-flex">
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-title-lable"
                    >
                      Posted By :&nbsp;
                    </Index.Typography>
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-value-text"
                    >
                      {jobList?.userId?.name}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="work-title-flex">
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-title-lable"
                    >
                      Posted On :&nbsp;
                    </Index.Typography>
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-value-text"
                    >
                      {PagesIndex.moment(jobList?.createdAt).format(
                        "MM-DD-YYYY"
                      )}
                    </Index.Typography>
                  </Index.Box>

                  {
                    jobList?.totalOfficer !== "" ? (
                      <Index.Box className="work-title-flex">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="work-title-lable"
                        >
                          Total officer :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="work-value-text"
                        >
                          {jobList?.totalOfficer}
                        </Index.Typography>
                      </Index.Box>
                    ) : null
                  }

                  {
                    jobList?.totalOfficer !== "" ? (
                      <Index.Box className="work-title-flex">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="work-title-lable"
                        >
                          Add the number of officers:&nbsp;
                        </Index.Typography>

                        {/* {jobList?.supervisor > 0 ||
                          jobList?.nonSupervisor > 0 ? null : ( */}
                        <Index.PrimaryButton
                          btnLabel={jobList?.supervisor > 0 ||
                            jobList?.nonSupervisor > 0 ? "Edit No Of Officer" : "Add No Of Officer"}
                          onClick={() =>
                            handleSupervisorRequest(jobList)
                          }
                          className="btn-primary"
                        />
                        {/* )} */}
                      </Index.Box>
                    ) : null
                  }

                  {
                    jobList?.supervisor > 0 ? (
                      <Index.Box className="work-title-flex">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="work-title-lable"
                        >
                          Total Supervisor officer :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="work-value-text"
                        >
                          {jobList?.supervisor || 0}
                        </Index.Typography>
                      </Index.Box>
                    ) : null
                  }

                  {
                    jobList?.nonSupervisor > 0 ? (
                      <Index.Box className="work-title-flex">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="work-title-lable"
                        >
                          Total Non-Supervisor officer :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="work-value-text"
                        >
                          {jobList?.nonSupervisor || 0}
                        </Index.Typography>
                      </Index.Box>
                    ) : null
                  }

                  {
                    jobList?.amount !== "" ? (
                      <Index.Box className="work-title-flex">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="work-title-lable"
                        >
                          Total Payment :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="work-value-text"
                        >
                          {jobList?.amount}
                        </Index.Typography>
                      </Index.Box>
                    ) : null
                  }

                  {
                    jobList?.paymentIntentId !== "" ? (
                      <Index.Box className="work-title-flex">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="work-title-lable"
                        >
                          Transaction Id  :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="work-value-text"
                        >
                          {jobList?.paymentIntentId.join(" ")}
                        </Index.Typography>
                      </Index.Box>
                    ) : null
                  }

                  <Index.Box className="work-title-flex">
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-title-lable"
                    >
                      Phone Number :&nbsp;
                    </Index.Typography>
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-value-text"
                    >
                      {jobList?.mobileNumber}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="work-title-flex">
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-title-lable"
                    >
                      Email Address :&nbsp;
                    </Index.Typography>
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-value-text"
                    >
                      {jobList?.userId?.email}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="work-title-flex">
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-title-lable"
                    >
                      Point Of Contact Name :&nbsp;
                    </Index.Typography>
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-value-text"
                    >
                      {jobList?.pointOfContactName}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="work-title-flex">
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-title-lable"
                    >
                      Point Of Contact Number :&nbsp;
                    </Index.Typography>
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-value-text"
                    >
                      {jobList?.pointOfContactNumber || "-"}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="work-title-flex">
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-title-lable"
                    >
                      Location :&nbsp;
                    </Index.Typography>
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-value-text"
                    >
                      {jobList?.location}
                    </Index.Typography>
                  </Index.Box>

                  <Index.Box className="work-title-flex">
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-title-lable"
                    >
                      Service :&nbsp;
                    </Index.Typography>
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-value-text"
                    >
                      {jobList?.service}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="work-title-flex">
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-title-lable"
                    >
                      Department :&nbsp;
                    </Index.Typography>
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-value-text"
                    >
                      {jobList?.department}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="work-title-flex">
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-title-lable"
                    >
                      Zip Code :&nbsp;
                    </Index.Typography>
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-value-text"
                    >
                      {jobList?.zipCode}
                    </Index.Typography>
                  </Index.Box>

                  <Index.Box className="work-title-flex">
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-title-lable"
                    >
                      Date/Time :&nbsp;
                    </Index.Typography>
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-value-text"
                      sx={{ whiteSpace: "break-spaces" }}
                    >

                      {PagesIndex.showDateTime(jobList)}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="work-title-flex">
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-title-lable"
                    >
                      Type :&nbsp;
                    </Index.Typography>
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-value-text"
                    >
                      {jobList?.jobType?.jobType}
                      {console.log(jobList, "jobListjobListjobList")}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="work-title-flex">
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-title-lable"
                    >
                      No of Officer  :&nbsp;
                    </Index.Typography>
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-value-text"
                    >
                      {jobList?.totalOfficer}
                    </Index.Typography>
                  </Index.Box>
                  {/* <Index.Box className="work-title-flex">
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-title-lable"
                    >
                      Information :&nbsp;
                    </Index.Typography>
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-value-text"
                    >
                      {jobList?.information}
                    </Index.Typography>
                  </Index.Box> */}
                  <Index.Box className="work-title-flex">
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-title-lable"
                    >
                      Total Hours :&nbsp;
                    </Index.Typography>
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-value-text"
                    >
                      {PagesIndex.timeDifferenceCalculation(jobList)} hour
                      {/* {jobList?.jobSchedule !== undefined  ? PagesIndex.timeDifferenceCalculation(
                                    jobList?.jobSchedule[0]?.jobStartTime,
                                    jobList?.jobSchedule[0]?.jobEndTime) : 0} */}
                    </Index.Typography>
                  </Index.Box>
                  {jobList?.rate == undefined ? null : (
                    <Index.Box className="work-title-flex">
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="work-title-lable"
                      >
                        Pay Rate (Per hour) :&nbsp;
                      </Index.Typography>
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="work-value-text"
                      >
                        {jobList?.rate ? "$" : null}
                        {jobList?.rate}
                      </Index.Typography>
                    </Index.Box>
                  )}
                  <Index.Box
                    // className="job-title-text"
                    className="work-title-flex"
                  >
                    <Index.Typography
                      component="p"
                      variant="p"
                      // className="job-title-label"
                      className="work-title-lable"
                    >
                      Description :&nbsp;
                    </Index.Typography>
                    {/* <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                      >
                          $100
                      </Index.Typography> */}
                    <Index.Typography
                      component="p"
                      variant="p"
                      // className="job-title-text-label"
                      className="work-value-text"
                    >
                      {jobList?.description}
                    </Index.Typography>
                  </Index.Box>

                  {/* Add Rate */}
                  {
                    <Index.Box className="work-title-flex">
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="work-title-lable"
                      >
                        Add Rate:&nbsp;
                      </Index.Typography>

                      {/* {jobList?.supervisor > 0 ||
                          jobList?.nonSupervisor > 0 ? null : ( */}
                      <Index.PrimaryButton
                        btnLabel={jobList?.rate ? "Edit Rate" : "Add Rate"}
                        onClick={() =>
                          handleEditPandingRequest(jobList)
                          // handleSupervisorRequest(jobList)
                        }
                        className="btn-primary"
                      />
                      {/* )} */}
                    </Index.Box>
                  }
                </Index.Box>
              </Index.Box>
              <Index.Box className="modal-footer">
                <Index.Box className="modal-footer-btn-flex">
                  {/* jobList.isClientPayment */}
                  {jobList?.isRate === true && jobList?.status !== "APPROVED" && jobList?.status !== "REJECTED" && !jobList?.officerId?.length ? (
                    <>
                      <Index.PrimaryButton
                        btnLabel={jobList?.status === "APPROVED" ? "Approved" : "Approve"}
                        disabled={isLoading || jobList?.status === "REJECTED"}
                        className="btn-primary bg-approve"
                        onClick={() => handleApproveRequest("APPROVED")}
                      >
                        {isLoading ? <Loader /> : "Click Me"}
                      </Index.PrimaryButton>

                      <Index.PrimaryButton
                        btnLabel={jobList?.status === "REJECTED" ? "Rejected" : "Reject"}
                        disabled={jobList?.status === "APPROVED"}
                        className="btn-primary bg-suspend"
                        onClick={() => handleApproveRequest("REJECTED")}
                      >
                        {isLoading ? <Loader /> : "Click Me"}
                      </Index.PrimaryButton>
                    </>
                  ) : null}

                </Index.Box>
              </Index.Box>
            </Index.Box>
          )}
        </Index.Box>
      </Index.Modal>
    </>
  );
};

export default PendingJobModal;
