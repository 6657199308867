import React, { useEffect, useState, useRef } from "react";
import Index from "../../../..";
import PagesIndex from "../../../../PageIndex";
import { adminAddAgentSchema } from "../../../../../validation/FormikSchema";
import { useFormikContext } from 'formik';
import {
  getAgencyRoleList,
  getCityList,
  getStateList,
} from "../../../../../redux-toolkit/slice/agency-slice/AgencyServices";
import DataService from "../../../../../config/DataService";
import {
  getRoleMasterList,
  getZipCodeData,
} from "../../../../../redux-toolkit/slice/admin-slice/AdminServices";
import Loader from "../../../../../common/loader/Loader";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};
const AddAdminAgentModal = (props) => {
  const { addAgentModalhandleClose, addAgentModalopen, getAgentListData } =
    props;
  const [isLoading, setIsLoading] = useState(false);
  // us phone number functionality
  const NUMERIC_REGEX_ONLY = /^[a-zA-Z ]+$/;
  const ONLY_NUMBER_NOT_SPACE_SPACIALCHAR = /\D/g;
  // states
  const dispatch = PagesIndex.useDispatch();
  const [roleList, setRoleList] = useState();
  // const [stateList, setStateList] = useState();
  // const [cityList, setCityList] = useState();
  // Role
  // const [roleMasterList, setRoleMasterList] = useState();

  // zip code
  // const [zipCodeList, setZipCodeList] = useState();

  // const [getstateShortName, setGetstateShortName] = useState("");
  // validation regex

  // password and confirm password
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const formRef = useRef(null)
  const addressInputRef = useRef(null);
  const [address, setAddress] = useState("");
  // const [state, setState] = useState("");
  // const [city, setCity] = useState("");
  // const [zipCode, setZipCode] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  // const [stateList, setStateList] = useState([]); // Populate this based on your requirements
  // const [cityList, setCityList] = useState([]); // Populate this based on your requirements
  // const [zipCodeList, setZipCodeList] = useState([]); // Populate this based on your requirements

  const [isSuggestionSelected, setIsSuggestionSelected] = useState({
    state: false,
    city: false,
    zipCode: false
  })
  const handlePhoneChange = (e, setFieldValue) => {
    const input = e.target.value;
    
    // Remove all non-digit characters
    const cleaned = input.replace(/\D/g, "");
    
    // Check if the first digit is 0
    if (cleaned.length > 0 && cleaned[0] === '0') {
      return; // Do nothing if the first digit is 0
    }
    
    // Set the raw input to Formik state
    setFieldValue("mobileNumber", cleaned);
};
//   const handlePhoneChange = (e, setFieldValue) => {
//     const input = e.target.value;
//     const cursorPosition = e.target.selectionStart;
//     const previousValue = mobileNumber;
    
//     // Remove all non-digit characters
//     const cleaned = input.replace(/\D/g, "");
    
//     // Check if the first digit is 0
//     if (cleaned.length > 0 && cleaned[0] === '0') {
//       return; // Do nothing if the first digit is 0
//     }
    
//     // Set the raw input to Formik state
//     setFieldValue("mobileNumber", cleaned);
    
//     let formattedNumber;
//     if (cleaned.length === 10) {
//       formattedNumber = formatPhoneNumber(cleaned);
//     } else {
//       formattedNumber = formatPartialPhoneNumber(cleaned);
//     }
    
//     setmobileNumber(formattedNumber);
//     // Adjust cursor position
//     setTimeout(() => {
//       const newCursorPosition = calculateCursorPosition(previousValue, formattedNumber, cursorPosition);
//       e.target.setSelectionRange(newCursorPosition, newCursorPosition);
//     }, 0);
// };


//   const formatPartialPhoneNumber = (input) => {
//     if (input.length <= 3) return input;
//     if (input.length <= 6) return `(${input.slice(0, 3)}) ${input.slice(3)}`;
//     return `(${input.slice(0, 3)}) ${input.slice(3, 6)}-${input.slice(6)}`;
//   };

//   const calculateCursorPosition = (previousValue, newValue, previousPosition) => {
//     const beforeCursor = previousValue.slice(0, previousPosition);
//     const digitsBeforeCursor = beforeCursor.replace(/\D/g, "").length;
//     const newValueDigits = newValue.replace(/\D/g, "");
//     let newPosition = 0;
//     let digitCount = 0;

//     for (let i = 0; i < newValue.length; i++) {
//       if (/\d/.test(newValue[i])) {
//         digitCount++;
//       }
//       if (digitCount > digitsBeforeCursor) {
//         break;
//       }
//       newPosition++;
//     }

//     return newPosition;
//   };

//   const formatPhoneNumber = (input) => {
//     const strInput = String(input); // Ensure input is a string
//     const match = strInput?.match(/^(\d{3})(\d{3})(\d{4})$/);
//     if (match) {
//       return "(" + match[1] + ") " + match[2] + "-" + match[3];
//     }
//     return strInput;
//   };

  const handleAddressChange = async (e) => {

    setAddress(e.target.value);
    formRef.current.setFieldValue("address", e.target.value);
    if (e.target.value?.length <= 0) {
      formRef.current.setFieldValue("address", "");
      formRef.current.setFieldValue("state", "");
      formRef.current.setFieldValue("city", "");
      formRef.current.setFieldValue("zipCode", "");
      setIsSuggestionSelected({
        state: false,
        city: false,
        zipCode: false
      })
    }
    if (e.target.value.length > 3) {
      try {
        const response = await fetch(
          `https://nominatim.openstreetmap.org/search?format=json&addressdetails=1&q=${e.target.value}`
        );
        const data = await response.json();
        setSuggestions(data);
      } catch (error) {
        console.error('Error fetching address:', error);
        setSuggestions([]);
      }
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestion, setFieldValue) => {
    setAddress(suggestion.display_name);
    let stateName = suggestion.address.state || suggestion.address.state_district || "";
    let cityName = suggestion.address.city || suggestion.address.town || suggestion.address.village || "";
    let zipCode = suggestion.address.postcode || "";
    formRef.current.setFieldValue("address", suggestion.display_name);
    formRef.current.setFieldValue("state", stateName);
    formRef.current.setFieldValue("city", cityName);
    formRef.current.setFieldValue("zipCode", zipCode);
    setIsSuggestionSelected({
      state: stateName?.length ? true : false,
      city: cityName?.length ? true : false,
      zipCode: zipCode?.length ? true : false
    })
    setSuggestions([]);
  };

  // const handleSuggestionClick = (suggestion) => {
  //   setAddress(suggestion.display_name);
  //   setState(
  //     suggestion.address.state || suggestion.address.state_district || ""
  //   );
  //   setCity(suggestion.address.city || suggestion.address.town || suggestion.address.village || "");
  //   setZipCode(suggestion.address.postcode || "");
  //   setSuggestions([]);
  // };



  const handleClickShowPassword = () => {
    setShowPassword((show) => !show);
  };
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword((show) => !show);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const initialValues = {
    roleName: "",
    agencyName: "",
    email: "",
    mobileNumber: "",
    zipCode: "",
    state: "",
    city: "",
    address: "",
    password: "",
    confirmPassword: "",
  };

  const getRole = () => {
    dispatch(getAgencyRoleList()).then((res) => {
      if (res?.payload?.status === 200) {
        let finalData = res?.payload?.data?.filter(data => {
          return data.Permission_name === "Department" || data.Permission_name === "Agency";
        });
        setRoleList(finalData);
      }
    });
  };

  const handleAddNewAgent = async (values) => {
    setIsLoading(true);
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("roleId", values?.roleName.trim());
    urlEncoded.append("agencyName", values?.agencyName.trim());
    urlEncoded.append("email", values?.email?.toLowerCase().trim());
    urlEncoded.append("mobileNumber", values?.mobileNumber.trim());
    urlEncoded.append("zipCode", values?.zipCode.trim());
    urlEncoded.append("state", values?.state.trim());
    urlEncoded.append("city", values?.city.trim());
    urlEncoded.append("address", values?.address.trim());
    urlEncoded.append("password", values?.password.trim());
 
    try {
      const response = await DataService.post(
        PagesIndex.Api.Admin.ADD_AGENT,
        urlEncoded
      );
      if (response?.data?.status == 201 || response?.data?.status == 200) {
        PagesIndex.toast.success(response?.data?.message);
        getAgentListData();
        addAgentModalhandleClose();
        setShowPassword(false);
        setShowConfirmPassword(false);
        setAddress("")
        // setState("")
        // setCity("")
        // setZipCode("")
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      }
    } catch (error) {
      PagesIndex.toast.error(error?.response?.data?.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getRole();
  }, []);

  useEffect(() => {
    return () => {
      setShowPassword(false);
      setShowConfirmPassword(false);
    };
  }, [addAgentModalhandleClose]);


  return (
    <>
      <Index.Modal
        open={addAgentModalopen}
        onClose={addAgentModalhandleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal"
      >
        <PagesIndex.Formik
          innerRef={formRef}
          initialValues={initialValues}
          onSubmit={handleAddNewAgent}
          validationSchema={adminAddAgentSchema}
        >
          {({
            values,
            errors,
            handleChange,
            handleBlur,
            touched,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            handleReset
          }) => (
            <form onSubmit={handleSubmit}>
              <Index.Box
                sx={style}
                className="add-user-modal-inner-main modal-inner extra-delete"
              >
                <Index.Box className="modal-header">
                  <Index.Typography
                    id="modal-modal-title"
                    className="modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Add New Agency/Department
                  </Index.Typography>
                  <img
                    src={PagesIndex.Svg.closeblack}
                    className="modal-close-icon"
                    onClick={addAgentModalhandleClose}
                    alt=""
                  />
                </Index.Box>
                <Index.Box className="modal-body">
                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Select Role
                    </Index.FormHelperText>
                    <Index.Box className="dropdown-box">
                      <Index.FormControl className="form-control drop-form-control">
                        <Index.Select
                          className="dropdown-select drop-select"
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          name="roleName"
                          onBlur={handleBlur}
                          renderValue={
                            values?.roleName
                              ? undefined
                              : () => "Select role"
                          }
                          value={values?.roleName}
                          // onFocus={() => setFieldTouched("roleName", true)}
                          onChange={(e) => {
                            setFieldValue("roleName", e?.target?.value);
                          }}
                          error={
                            errors.roleName && touched.roleName ? true : false
                          }
                          helperText={
                            errors.roleName && touched.roleName
                              ? errors.roleName
                              : null
                          }
                        >
                          {roleList && roleList.length > 0
                            ? roleList.map((data, index) => {
                              return (
                                <Index.MenuItem
                                  key={index}
                                  value={data?._id}
                                  className="drop-menuitem"
                                >
                                  <div>{data?.Permission_name}</div>
                                </Index.MenuItem>
                              );
                            })
                            : null}
                        </Index.Select>
                      </Index.FormControl>
                      <Index.FormHelperText error>
                        {errors.roleName && touched.roleName
                          ? errors.roleName
                          : null}
                      </Index.FormHelperText>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Add Agency/Department Name
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        className="form-control"
                        placeholder="Please enter agency/department name"
                        name="agencyName"
                        value={values.agencyName}
                        onChange={(e) => {
                          handleChange(e);
                          setFieldValue("agencyName", e.target.value);
                        }}
                        InputProps={{
                          inputProps: {
                            maxLength: 32,
                          },
                        }}
                        onBlur={handleBlur}
                        error={
                          errors.agencyName && touched.agencyName ? true : false
                        }
                        helperText={
                          errors.agencyName && touched.agencyName
                            ? errors.agencyName
                            : null
                        }
                        onKeyDown={(e) => {
                          if (e.key === " " && e.target.value.trim() === "") {
                            e.preventDefault(); // Prevent space as the first character
                          }
                          // else if (
                          //   e.target.value.trim().split(" ").length >= 2 &&
                          //   e.key === " "
                          // ) {
                          //   e.preventDefault(); // Prevent adding additional spaces between words
                          // } else if (
                          //   e.key === " " &&
                          //   e.target.value.endsWith(" ")
                          // ) {
                          //   e.preventDefault(); // Prevent additional spaces at the end
                          // }
                          // if (!NUMERIC_REGEX_ONLY.test(e.key)) {
                          //   e.preventDefault(); // Prevent non-numeric characters
                          // }
                        }}
                      />
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Add Email Address
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        className="form-control"
                        placeholder="Please enter email address"
                        name="email"
                        value={values.email}
                        onChange={(e) => {
                          handleChange(e);
                          // setFieldTouched("email", true);
                        }}
                        inputProps={{ maxLength: 60 }}
                        onKeyDown={(event) => {
                          if (event.key == " ") {
                            event.preventDefault();
                          }
                        }}
                        onBlur={handleBlur}
                        error={errors.email && touched.email ? true : false}
                        helperText={
                          errors.email && touched.email ? errors.email : null
                        }
                      />

                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Add Phone Number
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        // type="tel"
                        fullWidth
                        id="fullWidth"
                        className="form-control"
                        placeholder="Please enter phone number"
                        name="mobileNumber"
                        onBlur={handleBlur}
                        value={values?.mobileNumber}
                        onChange={(e) => {
                          handlePhoneChange(e, setFieldValue);
                        }}
                        inputProps={{ maxLength: 10 }}
                        error={
                          errors.mobileNumber && touched.mobileNumber
                            ? true
                            : false
                        }
                        helperText={
                          errors.mobileNumber && touched.mobileNumber
                            ? errors.mobileNumber
                            : null
                        }
                      />
                    </Index.Box>
                  </Index.Box>

                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">Address</Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextareaAutosize
                        fullWidth
                        id="fullWidth"
                        name="address"
                        className="form-control-textarea hgt-address"
                        placeholder="Please enter address"
                        value={address}
                        onChange={handleAddressChange}
                        error={
                          touched.address && errors.address
                            ? true
                            : false
                        }
                        helperText={
                          touched.address && errors.address
                            ? true
                            : false
                        }
                      />
                      {suggestions.length > 0 && (
                        <ul className="address-list">
                          {suggestions.map((suggestion, index) => (
                            <li
                              key={index}
                              onClick={() => handleSuggestionClick(suggestion, setFieldValue)}
                            >
                              {suggestion.display_name}
                            </li>
                          ))}
                        </ul>
                      )}
                    </Index.Box>
                    {touched.address && errors.address && (
                      <Index.FormHelperText error>
                        {errors.address}
                      </Index.FormHelperText>
                    )}
                  </Index.Box>

                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">State</Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        id="fullWidth"
                        className="form-control w-100-full"
                        name="state"
                        labelId="stateName-placeholder"
                        placeholder="Please enter state"
                        // value={address.length > 0 ? state : values.state}
                        value={values.state}
                        disabled={isSuggestionSelected?.state ? true : false}
                        onChange={(e) => {
                          if (e.target.value?.length <= 30) {
                            setFieldValue("state", e.target.value)
                          }
                        }}
                        error={
                          touched.state && errors.state
                            ? true
                            : false
                        }
                        helperText={
                          touched.state && errors.state
                            ? true
                            : false
                        }
                      >
                      </Index.TextField>
                      {touched.state && errors.state && (
                        <Index.FormHelperText error>
                          {errors.state}
                        </Index.FormHelperText>
                      )}
                    </Index.Box>
                  </Index.Box>

                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">City</Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        id="fullWidth"
                        className="form-control w-100-full"
                        name="city"
                        labelId="stateName-placeholder"
                        placeholder="Please enter city"
                        // value={address.length > 0 ? city : values.city}
                        value={values.city}
                        disabled={isSuggestionSelected?.city ? true : false}
                        // onChange={handleChange}
                        onChange={(e) => {
                          if (e.target.value?.length <= 30) {
                            setFieldValue("city", e.target.value)
                          }

                        }}
                        error={
                          touched.city && errors.city
                            ? true
                            : false
                        }
                        helperText={
                          touched.city && errors.city
                            ? true
                            : false
                        }
                      >
                      </Index.TextField>
                      {touched.city && errors.city && (
                        <Index.FormHelperText error>
                          {errors.city}
                        </Index.FormHelperText>
                      )}
                    </Index.Box>
                  </Index.Box>

                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">Zip Code</Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        id="fullWidth"
                        // type="number"
                        className="form-control w-100-full"
                        name="zipCode"
                        labelId="stateName-placeholder"
                        placeholder="Please enter zip code"
                        value={values.zipCode}
                        disabled={isSuggestionSelected?.zipCode ? true : false}
                        onChange={(e) => {
                          const numberPattern = /^[0-9-]+$/
                          if (e.target?.value?.length <= 0) {
                            setFieldValue("zipCode", "")
                          }
                          if (numberPattern.test(e.target.value) && e.target.value?.length <= 9
                          ) {
                            setFieldValue("zipCode", e.target.value)
                          }
                        }}
                        error={
                          touched.zipCode && errors.zipCode
                            ? true
                            : false
                        }
                        helperText={
                          touched.zipCode && errors.zipCode
                            ? true
                            : false
                        }
                      />
                    </Index.Box>
                    {touched.zipCode && errors.zipCode && (
                      <Index.FormHelperText error>
                        {errors.zipCode}
                      </Index.FormHelperText>
                    )}
                  </Index.Box>

                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Add Password
                    </Index.FormHelperText>

                    <Index.Box className="form-group pwd-icon-btn">
                      {/* <Index.OutlinedInput */}
                      <Index.OutlinedInput
                        fullWidth
                        id="fullWidth"
                        className="form-control-eye"
                        name="password"
                        placeholder="Please enter password"
                        onBlur={handleBlur}
                        value={values.password}
                        onChange={(e) => {
                          const newValue = e.target.value.replace(/\s/g, "");
                          setFieldValue("password", newValue);
                          // setFieldTouched("password", true);
                        }}
                        inputProps={{ maxLength: 16 }}
                        error={
                          errors.password && touched.password ? true : false
                        }
                        helperText={
                          errors.password && touched.password
                            ? errors.password
                            : null
                        }
                        type={showPassword ? "text" : "password"}
                        endAdornment={
                          <Index.InputAdornment position="end">
                            <Index.IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              // onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {!showPassword ? (
                                <Index.VisibilityOff />
                              ) : (
                                <Index.Visibility />
                              )}
                            </Index.IconButton>
                          </Index.InputAdornment>
                        }
                      />
                    </Index.Box>
                    {touched.password && errors.password && (
                      <Index.FormHelperText error>
                        {errors.password}
                      </Index.FormHelperText>
                    )}
                  </Index.Box>
                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Add Confirm password
                    </Index.FormHelperText>
                    <Index.Box className="form-group pwd-icon-btn">
                      {/* <Index.OutlinedInput */}
                      <Index.OutlinedInput
                        fullWidth
                        id="fullWidth"
                        className="form-control-eye"
                        name="confirmPassword"
                        placeholder="Please enter confirm password"
                        onBlur={handleBlur}
                        value={values.confirmPassword}
                        inputProps={{ maxLength: 16 }}
                        onChange={(e) => {
                          const newValue = e.target.value.replace(/\s/g, "");
                          setFieldValue("confirmPassword", newValue);
                          // setFieldTouched("confirmPassword", true);
                        }}
                        error={
                          errors.confirmPassword && touched.confirmPassword
                            ? true
                            : false
                        }
                        helperText={
                          errors.confirmPassword && touched.confirmPassword
                            ? errors.confirmPassword
                            : null
                        }
                        type={showConfirmPassword ? "text" : "password"}
                        endAdornment={
                          <Index.InputAdornment position="end">
                            <Index.IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowConfirmPassword}
                              // onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {!showConfirmPassword ? (
                                <Index.VisibilityOff />
                              ) : (
                                <Index.Visibility />
                              )}
                            </Index.IconButton>
                          </Index.InputAdornment>
                        }
                      />
                    </Index.Box>
                    {touched.confirmPassword && errors.confirmPassword && (
                      <Index.FormHelperText error>
                        {errors.confirmPassword}
                      </Index.FormHelperText>
                    )}
                  </Index.Box>
                </Index.Box>
                <Index.Box className="modal-footer">
                  <Index.Box className="modal-footer-btn-flex">
                    <Index.PrimaryButton
                      btnLabel="Add"
                      className="btn-primary bg-approve"
                      type="submit"
                      disabled={isLoading}
                    >
                      {isLoading ? <Loader /> : "Click Me"}
                    </Index.PrimaryButton>
                    <Index.PrimaryButton
                      btnLabel="Cancel"
                      className="btn-primary bg-suspend"
                      onClick={() => {
                        addAgentModalhandleClose();
                        setAddress("")
                        // setState("")
                        // setCity("")
                        // setZipCode("")
                        handleReset();
                      }}
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </form>
          )}
        </PagesIndex.Formik>
      </Index.Modal>
    </>
  );
};

export default AddAdminAgentModal;
