import React, { useEffect, useState, useRef } from "react";
import PagesIndex from "../../../PageIndex";
import Index from "../../../index";
import { Formik } from "formik";
import { addRoleMaster } from "../../../../redux-toolkit/slice/admin-slice/AdminServices";
import {
  zipManagement,
  jobTypeManagement,
  agencyManagement,
  clientManagement,
  officerManagement,
  jobManagement,
  skillManagement,
  cmsManagement,
  RatingAndReviewManagement,
} from "../../../../config/CommonRoleMaster";
import { Checkbox, FormControlLabel } from "@mui/material";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
const displayValue = (value) => {
  if (value === undefined || value === "") {
    return false;
  } else {
    return value;
  }
};
function Edit() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formRef = useRef();
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const NUMERIC_REGEX_ONLY = /^[a-zA-Z ]+$/;
  // edit data
  let editData = state?.row;
  const { Permission_name, SubAdminPermission } = editData;

  console.log(SubAdminPermission, "SubAdminPermission");
  if (editData) {
    editData.id = editData?._id;
  }
  console.log(editData?._id, "editDataiddd");
  let initialValues = {};
  initialValues = {
    roleName: Permission_name || "",
    zipManagement: {
      value: SubAdminPermission?.zip_code?.value || false,
      All: SubAdminPermission?.zip_code?.permission?.All || false,
      add: SubAdminPermission?.zip_code?.permission?.add || false,
      edit: SubAdminPermission?.zip_code?.permission?.edit || false,
      delete: SubAdminPermission?.zip_code?.permission?.delete || false,
    },
    jobTypeManagement: {
      value: SubAdminPermission?.Job_Type?.value || false,
      All: SubAdminPermission?.Job_Type?.permission?.All || false,
      add: SubAdminPermission?.Job_Type?.permission?.add || false,
      read: SubAdminPermission?.Job_Type?.permission?.read || false,
      edit: SubAdminPermission?.Job_Type?.permission?.edit || false,
      delete: SubAdminPermission?.Job_Type?.permission?.delete || false,
    },
    skillManagement: {
      value: SubAdminPermission?.Skills?.value || false,
      All: SubAdminPermission?.Skills?.permission?.All || false,
      add: SubAdminPermission?.Skills?.permission?.add || false,
      Suspend: SubAdminPermission?.Skills?.permission?.Suspend || false,
      read: SubAdminPermission?.Skills?.permission?.read || false,
      edit: SubAdminPermission?.Skills?.permission?.edit || false,
      delete: SubAdminPermission?.Skills?.permission?.delete || false,
    },
    agencyManagement: {
      value: SubAdminPermission?.Agency?.value || false,
      All: SubAdminPermission?.Agency?.permission?.All || false,
      add: SubAdminPermission?.Agency?.permission?.add || false,
      Suspend: SubAdminPermission?.Agency?.permission?.Suspend || false,
      read: SubAdminPermission?.Agency?.permission?.read || false,
      edit: SubAdminPermission?.Agency?.permission?.edit || false,
      delete: SubAdminPermission?.Agency?.permission?.delete || false,
    },
    clientManagement: {
      value: SubAdminPermission?.Client?.value || false,
      All: SubAdminPermission?.Client?.permission?.All || false,
      add: SubAdminPermission?.Client?.permission?.add || false,
      Suspend: SubAdminPermission?.Client?.permission?.Suspend || false,
      read: SubAdminPermission?.Client?.permission?.read || false,
      edit: SubAdminPermission?.Client?.permission?.edit || false,
      delete: SubAdminPermission?.Client?.permission?.delete || false,
    },
    officerManagement: {
      value: SubAdminPermission?.officer?.value || false,
      All: SubAdminPermission?.officer?.permission?.All || false,
      add: SubAdminPermission?.officer?.permission?.add || false,
      Suspend: SubAdminPermission?.officer?.permission?.Suspend || false,
      read: SubAdminPermission?.officer?.permission?.read || false,
      edit: SubAdminPermission?.officer?.permission?.edit || false,
      delete: SubAdminPermission?.officer?.permission?.delete || false,
    },
    cmsManagement: {
      value: SubAdminPermission?.CMS?.value || false,
      All: SubAdminPermission?.CMS?.permission?.All || false,
      add: SubAdminPermission?.CMS?.permission?.add || false,
      Suspend: SubAdminPermission?.CMS?.permission?.Suspend || false,
      read: SubAdminPermission?.CMS?.permission?.read || false,
      edit: SubAdminPermission?.CMS?.permission?.edit || false,
      delete: SubAdminPermission?.CMS?.permission?.delete || false,
    },
    RatingAndReviewManagement: {
      value: SubAdminPermission?.Rating_Reviews?.value || false,
      All: SubAdminPermission?.Rating_Reviews?.permission?.All || false,
      add: SubAdminPermission?.Rating_Reviews?.permission?.add || false,
      Suspend: SubAdminPermission?.Rating_Reviews?.permission?.Suspend || false,
      read: SubAdminPermission?.Rating_Reviews?.permission?.read || false,
      edit: SubAdminPermission?.Rating_Reviews?.permission?.edit || false,
      delete: SubAdminPermission?.Rating_Reviews?.permission?.delete || false,
    },
    jobManagement: {
      value: SubAdminPermission?.Jobs?.value || false,
    },
  };

  const handleFormSubmit = async (values) => {
    setLoading(true);

    let urlencoded = {
      id: editData?._id,
      Permission_name: values?.roleName.trim(),
      SubAdminPermission: {
        Client: {
          value: values.clientManagement.value,
          permission: {
            All: values.clientManagement.All,
            add: values.clientManagement.add,
            Suspend: values.clientManagement.Suspend,
            read: values.clientManagement.read,
            edit: values.clientManagement.edit,
            delete: values.clientManagement.delete,
          },
        },
        Agency: {
          value: values.agencyManagement.value,
          permission: {
            All: values.agencyManagement.All,
            add: values.agencyManagement.add,
            Suspend: values.agencyManagement.Suspend,
            read: values.agencyManagement.read,
            edit: values.agencyManagement.edit,
            delete: values.agencyManagement.delete,
          },
        },
        Job_Type: {
          value: values.jobTypeManagement.value,
          permission: {
            All: values.jobTypeManagement.All,
            add: values.jobTypeManagement.add,
            Suspend: values.jobTypeManagement.Suspend,
            read: values.jobTypeManagement.read,
            edit: values.jobTypeManagement.edit,
            delete: values.jobTypeManagement.delete,
          },
        },
        Skills: {
          value: values.skillManagement.value,
          permission: {
            All: values.skillManagement.All,
            add: values.skillManagement.add,
            read: values.skillManagement.read,
            edit: values.skillManagement.edit,
            delete: values.skillManagement.delete,
          },
        },
        Jobs: {
          value: values.jobManagement.value,
          permission: {
            All: values.jobManagement.All,
            add: values.jobManagement.add,
            Suspend: values.jobManagement.Suspend,
            read: values.jobManagement.read,
            edit: values.jobManagement.edit,
            delete: values.jobManagement.delete,
          },
        },
        zip_code: {
          value: values.zipManagement.value,
          permission: {
            All: values.zipManagement.All,
            add: values.zipManagement.add,
            Suspend: values.zipManagement.Suspend,
            read: values.zipManagement.read,
            edit: values.zipManagement.edit,
            delete: values.zipManagement.delete,
          },
        },
        CMS: {
          value: values.cmsManagement.value,
          permission: {
            All: values.cmsManagement.All,
            add: values.cmsManagement.add,
            read: values.cmsManagement.read,
            edit: values.cmsManagement.edit,
            delete: values.cmsManagement.delete,
          },
        },
        officer: {
          value: values.officerManagement.value,
          permission: {
            All: values.officerManagement.All,
            add: values.officerManagement.add,
            Suspend: values.officerManagement.Suspend,
            read: values.officerManagement.read,
            edit: values.officerManagement.edit,
            delete: values.officerManagement.delete,
          },
        },
        Rating_Reviews: {
          value: values.RatingAndReviewManagement.value,
          permission: {
            All: values.RatingAndReviewManagement.All,
            add: values.RatingAndReviewManagement.add,
            Suspend: values.RatingAndReviewManagement.Suspend,
            read: values.RatingAndReviewManagement.read,
            edit: values.RatingAndReviewManagement.edit,
            delete: values.RatingAndReviewManagement.delete,
          },
        },
      },
    };
    dispatch(addRoleMaster({ urlencoded, navigate, setLoading }));
  };

  const BackBtn = () => {
    navigate("/admin/role");
  };

  // Check All
  const [zipCheckAll, setZipCheckAll] = useState(false);
  const [jobTypeCheckAll, setJobTypeCheckAll] = useState(false);
  const [skillCheckAll, setSkillCheckAll] = useState(false);
  const [jobCheckAll, setJobCheckAll] = useState(false);
  const [clientCheckAll, setClientCheckAll] = useState(false);
  const [officerCheckAll, setOfficerCheckAll] = useState(false);
  const [agencyCheckAll, setAgencyCheckAll] = useState(false);
  const [cmsCheckAll, setCmsCheckAll] = useState(false);
  const [reviewCheckAll, setReviewCheckAll] = useState(false);

  useEffect(() => {
    if (SubAdminPermission?.zip_code?.permission?.All) {
      setZipCheckAll(true);
    }
    if (SubAdminPermission?.Jobs?.permission?.All) {
      setJobCheckAll(true);
    }
    if (SubAdminPermission?.Skills?.permission?.All) {
      setSkillCheckAll(true);
    }
    if (SubAdminPermission?.Job_Type?.permission?.All) {
      setJobTypeCheckAll(true);
    }
    if (SubAdminPermission?.Agency?.permission?.All) {
      setAgencyCheckAll(true);
    }
    if (SubAdminPermission?.Client?.permission?.All) {
      setClientCheckAll(true);
    }
    if (SubAdminPermission?.officer?.permission?.All) {
      setOfficerCheckAll(true);
    }
    if (SubAdminPermission?.CMS?.permission?.All) {
      setCmsCheckAll(true);
    }
    if (SubAdminPermission?.Rating_Reviews?.permission?.All) {
      setReviewCheckAll(true);
    }
  }, [SubAdminPermission]);

  const handleCheckZipPermission = (e, setFieldValue, values) => {
    const isChecked = e.target.checked;
    setZipCheckAll(isChecked);

    const updatedZipPermission = { ...values.zipManagement };
    zipManagement.forEach((item) => {
      updatedZipPermission[item.tag] = isChecked;
    });

    setFieldValue("zipManagement", updatedZipPermission);
  };

  const handleZipPermissionChecked = (
    tag,
    isChecked,
    setFieldValue,
    values
  ) => {
    const updatedZipPermission = { ...values.zipManagement, [tag]: isChecked };

    // Check if all individual checkboxes are checked
    const allChecked = zipManagement.every(
      (item) => updatedZipPermission[item.tag]
    );

    setZipCheckAll(allChecked);
    setFieldValue("zipManagement", updatedZipPermission);
  };
  // Job Type Permission functions
  const handleCheckJobTypePermission = (e, setFieldValue, values) => {
    const isChecked = e.target.checked;
    setJobTypeCheckAll(isChecked);

    const updatedJobTypePermission = { ...values.jobTypeManagement };
    jobTypeManagement.forEach((item) => {
      updatedJobTypePermission[item.tag] = isChecked;
    });
    setFieldValue("jobTypeManagement", updatedJobTypePermission);
  };

  const handleJobTypePermissionChecked = (
    tag,
    isChecked,
    setFieldValue,
    values
  ) => {
    const updatedJobTypePermission = {
      ...values.jobTypeManagement,
      [tag]: isChecked,
    };

    // Check if all individual checkboxes are checked
    const allChecked = jobTypeManagement.every(
      (item) => updatedJobTypePermission[item?.tag]
    );

    setJobTypeCheckAll(allChecked);
    setFieldValue("jobTypeManagement", updatedJobTypePermission);
  };

  // Skills  Permission functions
  const handleCheckSkillPermission = (e, setFieldValue, values) => {
    const isChecked = e.target.checked;
    setSkillCheckAll(isChecked);
    if (values !== undefined) {
      const updatedSkillPermission = { ...values.skillManagement };
      skillManagement && skillManagement.forEach((item) => {
        updatedSkillPermission[item.tag] = isChecked;
      });
      setFieldValue("skillManagement", updatedSkillPermission);
    }
  };

  const handleSkillPermissionChecked = (
    tag,
    isChecked,
    setFieldValue,
    values
  ) => {
    const updatedSkillPermission = {
      ...values.skillManagement,
      [tag]: isChecked,
    };

    // Check if all individual checkboxes are checked
    const allChecked = skillManagement.every(
      (item) => updatedSkillPermission[item.tag]
    );

    setSkillCheckAll(allChecked);
    setFieldValue("skillManagement", updatedSkillPermission);
  };

  // Cms  Permission functions
  const handleCheckCmsPermission = (e, setFieldValue, values) => {
    const isChecked = e.target.checked;
    setCmsCheckAll(isChecked);

    const updatedCmsPermission = { ...values.cmsManagement };
    cmsManagement.forEach((item) => {
      updatedCmsPermission[item.tag] = isChecked;
    });

    setFieldValue("cmsManagement", updatedCmsPermission);
  };

  // Cms permission single checkbox checked then function call
  const handleCmsPermissionChecked = (
    tag,
    isChecked,
    setFieldValue,
    values
  ) => {
    const updatedCmsPermission = { ...values.cmsManagement, [tag]: isChecked };

    // Check if all individual checkboxes are checked
    const allChecked = cmsManagement.every(
      (item) => updatedCmsPermission[item.tag]
    );

    setCmsCheckAll(allChecked);
    setFieldValue("cmsManagement", updatedCmsPermission);
  };

  // Agency Permission functions
  const handleCheckAgencyPermission = (e, setFieldValue, values) => {
    const isChecked = e.target.checked;
    setAgencyCheckAll(isChecked);

    const updatedAgencyPermission = { ...values.agencyManagement };
    agencyManagement.forEach((item) => {
      updatedAgencyPermission[item.tag] = isChecked;
    });

    setFieldValue("agencyManagement", updatedAgencyPermission);
  };
  // agency permission single checkbox checked then function call
  const handleAgencyPermissionChecked = (
    tag,
    isChecked,
    setFieldValue,
    values
  ) => {
    const updatedAgencyPermission = {
      ...values.agencyManagement,
      [tag]: isChecked,
    };

    // Check if all individual checkboxes are checked
    const allChecked = agencyManagement.every(
      (item) => updatedAgencyPermission[item.tag]
    );

    setAgencyCheckAll(allChecked);
    setFieldValue("agencyManagement", updatedAgencyPermission);
  };

  // Client Permission functions
  const handleCheckUserPermission = (e, setFieldValue, values) => {
    const isChecked = e.target.checked;
    setClientCheckAll(isChecked);

    const updatedUserPermission = { ...values.clientManagement };
    clientManagement.forEach((item) => {
      updatedUserPermission[item.tag] = isChecked;
    });

    setFieldValue("clientManagement", updatedUserPermission);
  };
  // Client permission single checkbox checked then function call
  const handleUserPermissionChecked = (
    tag,
    isChecked,
    setFieldValue,
    values
  ) => {
    const updatedUserPermission = {
      ...values.clientManagement,
      [tag]: isChecked,
    };

    // Check if all individual checkboxes are checked
    const allChecked = clientManagement.every(
      (item) => updatedUserPermission[item.tag]
    );

    setClientCheckAll(allChecked);
    setFieldValue("clientManagement", updatedUserPermission);
  };

  // Officer Permission functions
  const handleCheckOfficerPermission = (e, setFieldValue, values) => {
    const isChecked = e.target.checked;
    setOfficerCheckAll(isChecked);

    const updatedOfficerPermission = { ...values.officerManagement };
    officerManagement.forEach((item) => {
      updatedOfficerPermission[item.tag] = isChecked;
    });
    setFieldValue("officerManagement", updatedOfficerPermission);
  };
  // Officer permission single checkbox checked then function call
  const handleOfficerPermissionChecked = (
    tag,
    isChecked,
    setFieldValue,
    values
  ) => {
    const updatedOfficerPermission = {
      ...values.officerManagement,
      [tag]: isChecked,
    };

    // Check if all individual checkboxes are checked
    const allChecked = officerManagement.every(
      (item) => updatedOfficerPermission[item?.tag]
    );

    setOfficerCheckAll(allChecked);
    setFieldValue("officerManagement", updatedOfficerPermission);
  };
  // Rating and Review Permission functions
  const handleCheckRatingAndReviewPermission = (e, setFieldValue, values) => {
    const isChecked = e.target.checked;
    setReviewCheckAll(isChecked);

    const updatedRatingPermission = { ...values.RatingAndReviewManagement };
    RatingAndReviewManagement.forEach((item) => {
      updatedRatingPermission[item.tag] = isChecked;
    });

    setFieldValue("RatingAndReviewManagement", updatedRatingPermission);
  };
  // Rating and Review permission single checkbox checked then function call
  const handleRatingAndReviewPermissionChecked = (
    tag,
    isChecked,
    setFieldValue,
    values
  ) => {
    const updatedRatingPermission = { ...values.RatingAndReviewManagement, [tag]: isChecked };

    // Check if all individual checkboxes are checked
    const allChecked = RatingAndReviewManagement.every(
      (item) => updatedRatingPermission[item.tag]
    );

    setReviewCheckAll(allChecked);
    setFieldValue("RatingAndReviewManagement", updatedRatingPermission);
  };
  // Job Permission functions
  const handleCheckJobPermission = (e, setFieldValue, values) => {
    const isChecked = e.target.checked;
    setJobCheckAll(isChecked);

    const updatedJobPermission = { ...values.jobManagement };
    jobManagement.forEach((item) => {
      updatedJobPermission[item.tag] = isChecked;
    });

    setFieldValue("jobManagement", updatedJobPermission);
  };

  // Job permission single checkbox checked then function call
  const handleJobPermissionChecked = (
    tag,
    isChecked,
    setFieldValue,
    values
  ) => {
    const updatedJobPermission = { ...values.jobManagement, [tag]: isChecked };

    // Check if all individual checkboxes are checked
    const allChecked = jobManagement.every(
      (item) => updatedJobPermission[item.tag]
    );

    setJobCheckAll(allChecked);
    setFieldValue("jobManagement", updatedJobPermission);
  };

  return (
    <>
      {/* New Design Code Changes */}
      <Index.Box className="user-list-flex">
        <Index.Typography
          className="admin-page-title user-list-page-title"
          component="h2"
          variant="h2"
        >
          <Index.Link to="/admin/role" className="filter-back-link">
            <img
              src={PagesIndex.Svg.leftArrow}
              className="left-arrow-ic"
              alt="search grey img"
            ></img>
          </Index.Link>{" "}
          {editData ? "Edit" : "Add"} Role
        </Index.Typography>
      </Index.Box>
      {/* End New Design Code Changes */}

      <Index.Box className="role-edit-sec">
        <Formik
          initialValues={initialValues}
          validationSchema={PagesIndex.roleMasterValidationSchema}
          onSubmit={handleFormSubmit}
          innerRef={(f) => (formRef.current = f)}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Index.Box className="input-box">
                <Index.FormHelperText
                  variant="label"
                  component="label"
                  className="form-lable "
                >
                  Role Name
                </Index.FormHelperText>
                <Index.Box className="form-group">
                  <Index.TextField
                    hiddenLabel
                    id="filled-hidden-label-normal"
                    placeholder="Please enter role name"
                    variant="filled"
                    className="admin-input-bottom-border-field"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="roleName"
                    inputProps={{ maxLength: 30 }}
                    value={values?.roleName}
                    onKeyDown={(e) => {
                      // if (e.key === " " && e.target.value.trim() === "") {
                      //   e.preventDefault(); // Prevent space as the first character
                      // } else if (
                      //   e.target.value.trim().split(" ").length >= 2 &&
                      //   e.key === " "
                      // ) {
                      //   e.preventDefault(); // Prevent adding additional spaces between words
                      // } else if (
                      //   e.key === " " &&
                      //   e.target.value.endsWith(" ")
                      // ) {
                      //   e.preventDefault(); // Prevent additional spaces at the end
                      // }
                      if (!NUMERIC_REGEX_ONLY.test(e.key)) {
                        e.preventDefault(); // Prevent non-numeric characters
                      }
                    }}
                  />
                </Index.Box>
                <Index.FormHelperText error>
                  {errors.roleName && touched.roleName ? errors.roleName : null}
                </Index.FormHelperText>
              </Index.Box>
              <Index.Box className="main-permission-box common-card">
                <Index.Box className="permission-left-main">
                  <Index.Typography className="permission-label">
                    Zip Code Management
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="permission-right-main">
                  <FormControlLabel
                    control={
                      <Checkbox
                        className="role-check-box"
                        checked={zipCheckAll}
                        onChange={(e) =>
                          handleCheckZipPermission(e, setFieldValue, values)
                        }
                      />
                    }
                    label="Check All"
                  />
                  <Index.Box className="check-permission-flex">
                    <>
                      {zipManagement?.length &&
                        zipManagement?.map((item, index) => (
                          <Index.Box
                            key={index}
                            className="check-permission-box"
                          >
                            <FormControlLabel
                              name={item?.tag}
                              control={
                                <Checkbox
                                  className="role-check-box"
                                  onChange={(e) => {
                                    handleZipPermissionChecked(
                                      e.target.name,
                                      e.target.checked,
                                      setFieldValue,
                                      values
                                    );
                                  }}
                                  checked={values?.zipManagement[item?.tag]}
                                />
                              }
                              label={item?.name}
                            />
                          </Index.Box>
                        ))}
                    </>
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="main-permission-box common-card">
                <Index.Box className="permission-left-main">
                  <Index.Typography className="permission-label">
                    Job Type Management
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="permission-right-main">
                  <FormControlLabel
                    control={
                      <Checkbox
                        className="role-check-box"
                        checked={jobTypeCheckAll}
                        onChange={(e) =>
                          handleCheckJobTypePermission(e, setFieldValue, values)
                        }
                      />
                    }
                    label="Check All"
                  />
                  <>
                    {jobTypeManagement?.length &&
                      jobTypeManagement?.map((item, index) => (
                        <Index.Box key={index} className="check-permission-box">
                          <FormControlLabel
                            name={item?.tag}
                            control={
                              <Checkbox
                                className="role-check-box"
                                onChange={(e) => {
                                  handleJobTypePermissionChecked(
                                    e.target.name,
                                    e.target.checked,
                                    setFieldValue,
                                    values
                                  );
                                }}
                                checked={values?.jobTypeManagement[item?.tag]}
                              />
                            }
                            label={item?.name}
                          />
                        </Index.Box>
                      ))}
                  </>
                </Index.Box>
              </Index.Box>

              <Index.Box className="main-permission-box common-card">
                <Index.Box className="permission-left-main">
                  <Index.Typography className="permission-label">
                    Skill Management
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="permission-right-main">
                  <FormControlLabel
                    control={
                      <Checkbox
                        className="role-check-box"
                        checked={skillCheckAll}
                        onChange={(e) =>
                          handleCheckSkillPermission(e, setFieldValue, values)
                        }
                      />
                    }
                    label="Check All"
                  />
                  <>
                    {skillManagement?.length &&
                      skillManagement?.map((item, index) => (
                        <Index.Box key={index} className="check-permission-box">
                          <FormControlLabel
                            name={item?.tag}
                            control={
                              <Checkbox
                                className="role-check-box"
                                onChange={(e) => {
                                  handleSkillPermissionChecked(
                                    e.target.name,
                                    e.target.checked,
                                    setFieldValue,
                                    values
                                  );
                                }}
                                checked={values?.skillManagement[item?.tag]}
                              />
                            }
                            label={item?.name}
                          />
                        </Index.Box>
                      ))}
                  </>
                </Index.Box>
              </Index.Box>

              <Index.Box className="main-permission-box common-card">
                <Index.Box className="permission-left-main">
                  <Index.Typography className="permission-label">
                    Agency Management
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="permission-right-main">
                  <FormControlLabel
                    control={
                      <Checkbox
                        className="role-check-box"
                        checked={agencyCheckAll}
                        onChange={(e) =>
                          handleCheckAgencyPermission(e, setFieldValue, values)
                        }
                      />
                    }
                    label="Check All"
                  />
                  <>
                    {agencyManagement?.length &&
                      agencyManagement?.map((item, index) => (
                        <Index.Box key={index} className="check-permission-box">
                          <FormControlLabel
                            name={item?.tag}
                            control={
                              <Checkbox
                                className="role-check-box"
                                onChange={(e) => {
                                  handleAgencyPermissionChecked(
                                    e.target.name,
                                    e.target.checked,
                                    setFieldValue,
                                    values
                                  );
                                }}
                                checked={values?.agencyManagement[item?.tag]}
                              />
                            }
                            label={item?.name}
                          />
                        </Index.Box>
                      ))}
                  </>
                </Index.Box>
              </Index.Box>

              <Index.Box className="main-permission-box common-card">
                <Index.Box className="permission-left-main">
                  <Index.Typography className="permission-label">
                    Vendor Management
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="permission-right-main">
                  <FormControlLabel
                    control={
                      <Checkbox
                        className="role-check-box"
                        checked={clientCheckAll}
                        onChange={(e) =>
                          handleCheckUserPermission(e, setFieldValue, values)
                        }
                      />
                    }
                    label="Check All"
                  />
                  <>
                    {clientManagement?.length &&
                      clientManagement?.map((item, index) => (
                        <Index.Box key={index} className="check-permission-box">
                          <FormControlLabel
                            name={item?.tag}
                            control={
                              <Checkbox
                                className="role-check-box"
                                onChange={(e) => {
                                  handleUserPermissionChecked(
                                    e.target.name,
                                    e.target.checked,
                                    setFieldValue,
                                    values
                                  );
                                }}
                                checked={values?.clientManagement[item?.tag]}
                              />
                            }
                            label={item?.name}
                          />
                        </Index.Box>
                      ))}
                  </>
                </Index.Box>
              </Index.Box>

              <Index.Box className="main-permission-box common-card">
                <Index.Box className="permission-left-main">
                  <Index.Typography className="permission-label">
                    Officer Management
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="permission-right-main">
                  <FormControlLabel
                    control={
                      <Checkbox
                        className="role-check-box"
                        checked={officerCheckAll}
                        onChange={(e) =>
                          handleCheckOfficerPermission(e, setFieldValue, values)
                        }
                      />
                    }
                    label="Check All"
                  />
                  <>
                    {officerManagement?.length &&
                      officerManagement?.map((item, index) => (
                        <Index.Box key={index} className="check-permission-box">
                          <FormControlLabel
                            name={item?.tag}
                            control={
                              <Checkbox
                                className="role-check-box"
                                onChange={(e) => {
                                  handleOfficerPermissionChecked(
                                    e.target.name,
                                    e.target.checked,
                                    setFieldValue,
                                    values
                                  );
                                }}
                                checked={values?.officerManagement[item?.tag]}
                              />
                            }
                            label={item?.name}
                          />
                        </Index.Box>
                      ))}
                  </>
                </Index.Box>
              </Index.Box>

              <Index.Box className="main-permission-box common-card">
                <Index.Box className="permission-left-main">
                  <Index.Typography className="permission-label">
                    Cms Management
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="permission-right-main">
                  <FormControlLabel
                    control={
                      <Checkbox
                        className="role-check-box"
                        checked={cmsCheckAll}
                        onChange={(e) =>
                          handleCheckCmsPermission(e, setFieldValue, values)
                        }
                      />
                    }
                    label="Check All"
                  />
                  <>
                    {cmsManagement?.length &&
                      cmsManagement?.map((item, index) => (
                        <Index.Box key={index} className="check-permission-box">
                          <FormControlLabel
                            name={item?.tag}
                            control={
                              <Checkbox
                                className="role-check-box"
                                onChange={(e) => {
                                  handleCmsPermissionChecked(
                                    e.target.name,
                                    e.target.checked,
                                    setFieldValue,
                                    values
                                  );
                                }}
                                checked={values?.cmsManagement[item?.tag]}
                              />
                            }
                            label={item?.name}
                          />
                        </Index.Box>
                      ))}
                  </>
                </Index.Box>
              </Index.Box>

              <Index.Box className="main-permission-box common-card">
                <Index.Box className="permission-left-main">
                  <Index.Typography className="permission-label">
                    Job Management
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="permission-right-main">
                  <FormControlLabel
                    control={
                      <Checkbox
                        className="role-check-box"
                        checked={jobCheckAll}
                        onChange={(e) =>
                          handleCheckJobPermission(e, setFieldValue, values)
                        }
                      />
                    }
                    label="Check All"
                  />
                  <>
                    {jobManagement?.length &&
                      jobManagement?.map((item, index) => (
                        <Index.Box key={index} className="check-permission-box">
                          <FormControlLabel
                            name={item?.tag}
                            control={
                              <Checkbox
                                className="role-check-box"
                                onChange={(e) => {
                                  handleJobPermissionChecked(
                                    e.target.name,
                                    e.target.checked,
                                    setFieldValue,
                                    values
                                  );
                                }}
                                checked={values?.jobManagement[item?.tag]}
                              />
                            }
                            label={item?.name}
                          />
                        </Index.Box>
                      ))}
                  </>
                </Index.Box>
              </Index.Box>

              <Index.Box className="main-permission-box common-card">
                <Index.Box className="permission-left-main">
                  <Index.Typography className="permission-label">
                    Rating And Review Management
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="permission-right-main">
                  <FormControlLabel
                    control={
                      <Checkbox
                        className="role-check-box"
                        checked={reviewCheckAll}
                        onChange={(e) =>
                          handleCheckRatingAndReviewPermission(e, setFieldValue, values)
                        }
                      />
                    }
                    label="Check All"
                  />
                  <>
                    {RatingAndReviewManagement?.length &&
                      RatingAndReviewManagement?.map((item, index) => (
                        <Index.Box key={index} className="check-permission-box">
                          <FormControlLabel
                            name={item?.tag}
                            control={
                              <Checkbox
                                className="role-check-box"
                                onChange={(e) => {
                                  handleRatingAndReviewPermissionChecked(
                                    e.target.name,
                                    e.target.checked,
                                    setFieldValue,
                                    values
                                  );
                                }}
                                checked={values?.RatingAndReviewManagement[item?.tag]}
                              />
                            }
                            label={item?.name}
                          />
                        </Index.Box>
                      ))}
                  </>
                </Index.Box>
              </Index.Box>

              <Index.Box className="btn-main-primary user-btn-flex">
                <Index.Button
                  variant="contained"
                  type="submit"
                  disabled={loading}
                  className="btn-primary"
                >
                  {editData ? "Update" : "Submit"}
                </Index.Button>
              </Index.Box>
            </form>
          )}
        </Formik>
      </Index.Box>
    </>
  );
}

export default Edit;
