import React, { useEffect, useState } from "react";
import Index from "../../../..";
import PagesIndex from "../../../../PageIndex";
import Loader from "../../../../../common/loader/Loader";
import { convertHoursToHHMM } from "../../../../../common/timeCalculation/ExtendTime";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

const PendingJobOfficerAceeptModal = (props) => {
  const {
    pendingjobopen,
    pendingjobhandleClose,
    pendingjobOfficerAcceptData,
    getJobListData,
    isShowApproveRejectButton,
  } = props;
  console.log(isShowApproveRejectButton, "Button")
  const [jobList, setJobList] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  // console.log("pendingjobOfficerAcceptData", pendingjobOfficerAcceptData);
  console.log("jobList", jobList);

  useEffect(() => {
    if (pendingjobopen) {
      getOfficerAcceptJObDetail();
    }
  }, [pendingjobopen]);

  // get officer job single Detail's
  const getOfficerAcceptJObDetail = (status) => {
    setLoading(true);
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("jobId", pendingjobOfficerAcceptData?.jobId);
    urlEncoded.append("officerId", pendingjobOfficerAcceptData?.officerId);
    PagesIndex.DataService.post(
      PagesIndex.Api.Agency.GET_JOB_DETAIL_OFFICER_ACCEPT,
      urlEncoded
    )
      .then((res) => {
        if (res?.data?.status === 200) {
          setJobList(res?.data?.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        PagesIndex.toast.error(err?.response?.data?.message);
      });
  };

  // job approverd / Rejected
  const handleApproveRejectRequest = (status) => {
    setIsLoading(true);
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("jobId", pendingjobOfficerAcceptData?.jobId);
    urlEncoded.append("officerId", pendingjobOfficerAcceptData?.officerId);
    urlEncoded.append("officerStatus", status);
    PagesIndex.DataService.post(
      PagesIndex.Api.Agency.JOB_APPROVE_REJECT_OFFICER,
      urlEncoded
    )
      .then((res) => {
        if (res?.data?.status === 200) {
          pendingjobhandleClose();
          PagesIndex.toast.success(res?.data?.message);
          getJobListData();
          setTimeout(() => {
            setIsLoading(false);
          }, 2000);
        }
      })
      .catch((err) => {
        PagesIndex.toast.error(err.response.data.mes);
        setIsLoading(false);
      });
  };

  return (
    <>
      <Index.Modal
        open={pendingjobopen}
        onClose={pendingjobhandleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal"
      >
        <Index.Box
          sx={style}
          className="add-user-modal-inner-main modal-inner"
        >
          {loading ? (
            <Loader />
          ) : (
            <Index.Box>
              <Index.Box className="modal-header">
                <Index.Typography
                  id="modal-modal-title"
                  className="modal-title"
                  variant="h6"
                  component="h2"
                >
                  Officer Details
                </Index.Typography>
                <img
                  src={PagesIndex.Svg.closeblack}
                  className="modal-close-icon"
                  onClick={pendingjobhandleClose}
                  alt=""
                />
              </Index.Box>
              <Index.Box className="modal-body">
                <>
                  {jobList?.officerId.length &&
                    jobList?.officerId.map((item) => {
                      const officerlist = item?.officerId;
                      return (
                        <>

                          <Index.Box className="work-title-flex">
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="work-title-lable"
                            >
                              Accepted By :&nbsp;
                            </Index.Typography>
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="work-value-text"
                            >
                              {officerlist?.firstName} {officerlist?.lastName}
                            </Index.Typography>
                          </Index.Box>
                          <Index.Box className="work-title-flex">
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="work-title-lable "
                            >
                              Accepted On :&nbsp;
                            </Index.Typography>
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="work-value-text"
                            >

                              {PagesIndex.moment(item.jobAcceptedDate).utcOffset("+05:30").format("MM-DD-YYYY")}
                              {/* {console.log("171", item.jobAcceptedDate)} */}


                              {/* {PagesIndex.moment(
                                officerlist?.jobAcceptedDate
                              ).format("MM-DD-YYYY")}  */}
                            </Index.Typography>
                          </Index.Box>
                          <Index.Box className="work-title-flex">
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="work-title-lable"
                            >
                              Phone Number :&nbsp;
                            </Index.Typography>
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="work-value-text"
                            >
                              {
                                officerlist?.mobileNumber || "-"
                              }
                            </Index.Typography>
                          </Index.Box>
                          <Index.Box className="work-title-flex">
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="work-title-lable "
                            >
                              Email Address :&nbsp;
                            </Index.Typography>
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="work-value-text"
                            >
                              {officerlist?.email || "-"}
                            </Index.Typography>
                          </Index.Box>
                          {/* <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Category of work :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          {officerlist?.category || "-"}
                        </Index.Typography>
                      </Index.Box> */}

                          <Index.Box className="work-title-flex">
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="work-title-lable "
                            >
                              Rank :&nbsp;
                            </Index.Typography>
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="work-value-text"
                            >
                              {officerlist?.rank || "-"}
                            </Index.Typography>
                          </Index.Box>

                          {officerlist?.yearsOnDuty &&
                            officerlist?.monthsOnDuty ? (
                            <Index.Box className="work-title-flex">
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="work-title-lable "
                              >
                                TOD (Time On Duty) :&nbsp;
                              </Index.Typography>
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="work-value-text"
                              >
                                {officerlist?.yearsOnDuty || "-"} year{" "}
                                {officerlist?.monthsOnDuty || "-"} month
                              </Index.Typography>
                            </Index.Box>
                          ) : null}

                          <Index.Box className="work-title-flex">
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="work-title-lable "
                            >
                              Required Pay Rate <br />(Added By Officer) :&nbsp;
                            </Index.Typography>
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="work-value-text"
                            >
                              ${officerlist?.rate || "-"}
                            </Index.Typography>
                          </Index.Box>
                          <Index.Box className="work-title-flex">
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="work-title-lable "
                            >
                              Available For :&nbsp;
                            </Index.Typography>
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="work-value-text"
                            >
                              {officerlist?.jobType?.map((item, index) => item.jobType).join(', ')}
                            </Index.Typography>
                          </Index.Box>

                          {officerlist?.jobName == "Supervisor" ? (
                            <Index.Box className="work-title-flex">
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="work-title-lable"
                              >
                                Officer Type :&nbsp;
                              </Index.Typography>
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="work-value-text"
                              >
                                {officerlist?.jobName}
                              </Index.Typography>
                            </Index.Box>
                          ) : (
                            <Index.Box className="work-title-flex">
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="work-title-lable"
                              >
                                Officer's Type :&nbsp;
                              </Index.Typography>
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="work-value-text"
                              >
                                {officerlist?.jobName}
                              </Index.Typography>
                            </Index.Box>
                          )}
                          <Index.Box className="work-title-flex">
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="work-title-lable "
                            >
                              Required Skill :&nbsp;
                            </Index.Typography>
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="work-value-text"
                            >
                              {/* {officerlist?.skills?.length
                            ? officerlist?.skills?.toString()
                            : "-"} */}
                              {officerlist?.skills?.length
                                ? officerlist?.skills?.join(", ")
                                : "-"}
                            </Index.Typography>
                          </Index.Box>



                          <Index.Box className="work-title-flex">
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="work-title-lable "
                            >
                              Date/Time :&nbsp;
                            </Index.Typography>
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="work-value-text"
                            >
                              <span className="day-title">
                                {PagesIndex.dayjs(
                                  jobList[0]?.jobSchedule[0].jobStartDate
                                ).format("MM-DD-YYYY") +
                                  " to " +
                                  PagesIndex.dayjs(
                                    item?.jobEndDate !== undefined ? item.jobEndDate : jobList[0]?.jobSchedule[0].jobEndDate
                                  ).format("MM-DD-YYYY")}
                              </span>{" "}
                              {" "}

                            </Index.Typography>
                          </Index.Box>

                          {item.extendedHours !== "0" ? (
                            <Index.Box className="work-title-flex">
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="work-title-lable "
                              >
                                Extend Time :&nbsp;
                              </Index.Typography>

                              <Index.Typography
                                component="p"
                                variant="p"
                                className="work-value-text"
                              >
                                {convertHoursToHHMM(item?.extendedHours)}
                                {/* {item?.extendHours} */}
                              </Index.Typography>
                            </Index.Box>
                          ) : null
                          }

                          <Index.Box
                            // className="job-title-text"
                            className="work-title-flex"
                          >
                            <Index.Typography
                              component="p"
                              variant="p"
                              // className="job-title-label"
                              className="work-title-lable"
                            >
                              Available on :&nbsp;
                            </Index.Typography>
                            <Index.Box className="wrrapper-text-main">
                              <>
                                {officerlist?.days?.map((item, index) => {
                                  return (
                                    <>
                                      {item.isActive == true ? (
                                        <>
                                          <Index.Box className="day-title-flex">
                                            <Index.Box className="day-title">
                                              {item?.day.charAt(0).toUpperCase() +
                                                item?.day.slice(1)}
                                            </Index.Box>{" "}
                                            -{" "}
                                            <Index.Box className="day-title">
                                              {item?.startTime
                                                ? item?.startTime + " to "
                                                : null}
                                              {item?.endTime}
                                            </Index.Box>
                                          </Index.Box>
                                        </>
                                      ) : null}
                                    </>
                                  );
                                })}
                              </>
                            </Index.Box>
                          </Index.Box>

                          {officerlist?.certificates?.length ||
                            officerlist?.license?.length ? (
                            <>
                              <Index.Box className="work-title-flex">
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="work-title-lable "
                                >
                                  Document :&nbsp;
                                </Index.Typography>
                              </Index.Box>
                              <Index.Box className="document-wrapper">
                                {officerlist?.certificates?.map(
                                  (data, index) => {
                                    return (
                                      <Index.Box className="document-main">
                                        <Index.Typography
                                          component="p"
                                          variant="p"
                                          className="work-title-lable"
                                        >
                                          {data}
                                        </Index.Typography>
                                        <Index.PrimaryButton
                                          btnLabel="View Document"
                                          className="btn-primary"
                                          onClick={() => {
                                            window.open(
                                              `${PagesIndex.ADMIN_IMAGE_ENDPOINT}/${data}`
                                            );
                                          }}
                                        />
                                      </Index.Box>
                                    );
                                  }
                                )}

                                {officerlist?.license?.map((data, index) => {
                                  return (
                                    <Index.Box className="document-main">
                                      <Index.Typography
                                        component="p"
                                        variant="p"
                                        className="work-title-lable"
                                      >
                                        {data}
                                      </Index.Typography>
                                      <Index.PrimaryButton
                                        btnLabel="View Document"
                                        className="btn-primary"
                                        onClick={() => {
                                          window.open(
                                            `${PagesIndex.ADMIN_IMAGE_ENDPOINT}/${data}`
                                          );
                                        }}
                                      />
                                    </Index.Box>
                                  );
                                })}

                                {/* <Index.Box className="document-main">
                          <Index.Typography
                            component="p"
                            variant="p"
                            className="job-title-text-label"
                          >
                            Document 1
                          </Index.Typography>
                          <Index.PrimaryButton
                            btnLabel="View Document"
                            className="btn-primary"
                          />
                        </Index.Box> */}
                              </Index.Box>
                            </>
                          ) : null}
                        </>
                      );
                    })}
                </>
              </Index.Box>

              {/* PendingOfficer */}
              {console.log(jobList, "475")}
              <Index.Box className="modal-footer">
                <Index.Box className="modal-footer-btn-flex">
                  {isShowApproveRejectButton == true && jobList?.officerId[0]?.officerStatus !== "APPROVED" && jobList?.officerId[0]?.officerStatus !== "REJECTED" ? (
                    <>
                      <Index.PrimaryButton
                        btnLabel={
                          jobList?.officerId[0]?.officerStatus === "APPROVED"
                            ? "Approved"
                            : "Approve"
                        }
                        className="btn-primary bg-approve appr-rej-btn"
                        onClick={() => handleApproveRejectRequest("APPROVED")}
                        disabled={isLoading}
                      >
                        {isLoading ? <Loader /> : "Click Me"}
                      </Index.PrimaryButton>

                      <Index.PrimaryButton
                        btnLabel={
                          jobList?.officerId[0]?.officerStatus === "REJECTED"
                            ? "Rejected"
                            : "Reject"
                        }
                        className="btn-primary bg-suspend appr-rej-btn"
                        onClick={() => handleApproveRejectRequest("REJECTED")}
                      ></Index.PrimaryButton>
                    </>
                  ) : null}
                </Index.Box>
              </Index.Box>
            </Index.Box>
          )}
        </Index.Box>
      </Index.Modal>
    </>
  );
};

export default PendingJobOfficerAceeptModal;
