import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyBNDaTbEXt3oBHuLMZJW0avZjv1AYy0Ac0",
  authDomain: "protekt-security.firebaseapp.com",
  projectId: "protekt-security",
  storageBucket: "protekt-security.appspot.com",
  messagingSenderId: "652923474354",
  appId: "1:652923474354:web:e36058a55eb16898fcd4ab",
  measurementId: "G-M45Q7V91HR"
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/firebase-messaging-sw.js')
    .then((registration) => {
      // console.log('Service Worker registration successful with scope: ', registration.scope);
      messaging.useServiceWorker(registration);
    }).catch((err) => {
      // console.log('Service Worker registration failed: ', err);
    });
}

onMessage(messaging, (payload) => {
  console.log('Message received. ', payload);

  if (payload.notification) {
    payload.data = {
      title: payload.notification.title,
      body: payload.notification.body,
      image: payload.notification.icon || 'default-icon.png', // Default icon if not present
    };
    delete payload.notification; // Remove the original `notification` key
  }

  const notificationTitle = payload?.data?.title;
  const notificationOptions = {
    body: payload?.data?.body,
    icon: payload?.data?.image,
  };

  new Notification(notificationTitle, notificationOptions);

});

export const generateToken = async () => {
  const permission = await Notification.requestPermission();
  if (permission === "granted") {
    const token = await getToken(messaging, {
      vapidKey: "BMtv0yWbWkg1IAzf5Tv-1_ruDzCTN2LKJ0fKf5-IPZ6td26JDHOH1y2laANUv4W08s-m7Jp99kmTB9Wr0N-L6xI",
    });
    return token;
  } else {
    return "";
  }
};
