import React, { useEffect, useRef, useState } from "react";
import Index from "../../../..";
import PagesIndex from "../../../../PageIndex";
import DataService from "../../../../../config/DataService";
import Loader from "../../../../../common/loader/Loader";


const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
};

const JobTransferModel = (props) => {
    const {
        transferOfficerId,
        transferJobModalopen,
        handleTransferJobModalClose,
        transferJobId,
        getJobListData
    } = props;

    const [fetchOfficer, setOfficerData] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchOfficerList = async (officerId) => {
        setLoading(true);
        try {
            if (!officerId) {
                return false;
            }
            const response = await DataService.get(
                `${PagesIndex.Api.Agency.FETCH_JOB_TRANSFER_OFFICER}/${officerId}`);
            if (response?.data?.status === 200) {
                setLoading(false);
                setOfficerData(response?.data?.data);
            }
        } catch (error) {
            setLoading(false);
            console.log("42", error)
        }
    };

    PagesIndex.useEffect(() => {
        fetchOfficerList(transferOfficerId);
    }, [transferOfficerId, transferJobId]);

    const handleTransferOfficer = async (officerId) => {
        try {
            console.log("51",officerId, transferJobId )
            setLoading(true);
            if (!officerId) {
                PagesIndex.toast.error("Something went wrong.");
                return false;
            }
            const payload = {
                jobId:transferJobId,
                OfficerId:officerId,
                transferOfficerId:transferOfficerId
                // transferNote
            }
            const response = await DataService.post(
                `${PagesIndex.Api.Agency.JOB_TRANSFER_OFFICER}`, payload);
            if (response?.data?.status === 200) {
                setLoading(false);
                handleTransferJobModalClose();
                getJobListData();
                PagesIndex.toast.success(response?.data?.message);
            }

        } catch (error) {
            setLoading(false);
            PagesIndex.toast.error(error.response?.data?.message);
        }

    }

    return (
        <>
            <Index.Modal
                open={transferJobModalopen}
                onClose={handleTransferJobModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="modal"
            >
                <Index.Box
                    sx={style}
                    className="agency-job-modal-inner-main modal-inner"
                >
                    <Index.Box className="modal-header">
                        <Index.Typography
                            id="modal-modal-title"
                            className="modal-title"
                            variant="h6"
                            component="h2"
                        >
                            {/* Edit Job {agencyEditJobData?.aliasJobId} */}
                        </Index.Typography>
                        <img
                            src={PagesIndex.Svg.closeblack}
                            className="modal-close-icon"
                            onClick={handleTransferJobModalClose}
                            alt=""
                        />
                    </Index.Box>

                    <Index.Box className="common-card job-mamangment-card">
                        <Index.Box className="admin-sub-flex">


                            <Index.Box className="common-btn-flex">
                                <Index.Box className="progress-job-box">
                                    <Index.Typography
                                        component="p"
                                        variant="p"
                                        className="status_red"
                                    >
                                        {/* {item?.isCancelled
                                        ? "Cancelled"
                                        : ""} */}
                                    </Index.Typography>
                                </Index.Box>
                            </Index.Box>
                        </Index.Box>
                        {
                            loading ? (
                                <Loader />
                            ) : (
                                fetchOfficer.length > 0 ? (
                                    fetchOfficer.map((item, index) => (
                                        <Index.Box key={item?._id} className="card-main">
                                            <Index.Box className="card-left">
                                                <Index.Box className="officer-profile-flex">
                                                    <img
                                                        src={
                                                            item?.image
                                                                ? `${PagesIndex.ADMIN_IMAGE_ENDPOINT}/${item?.image}`
                                                                : PagesIndex.Png.usericon
                                                        }
                                                        alt=""
                                                        className="prof-img"
                                                    />
                                                    <Index.Box className="officer-detail">

                                                        <Index.Typography
                                                            component="p"
                                                            variant="p"
                                                            className="job-title-label"
                                                        >
                                                            {item?.firstName + " " + item?.lastName}
                                                            {item?.isBuild == true ? (
                                                                <>
                                                                    {item?.jobName == "Supervisor" ? (
                                                                        <Index.Box className="admin-list-pd-btn-main">
                                                                            <Index.PrimaryButton
                                                                                btnLabel="Supervisor "
                                                                                className="admin-list-pd-btn"
                                                                            />
                                                                        </Index.Box>
                                                                    ) : (
                                                                        <Index.Box className="admin-list-pd-btn-main">
                                                                            <Index.PrimaryButton
                                                                                btnLabel="Non-Supervisor"
                                                                                className="admin-list-pd-btn"
                                                                            />
                                                                        </Index.Box>
                                                                    )}
                                                                </>
                                                            ) : (null)}

                                                        </Index.Typography>

                                                        <Index.Typography
                                                            component="p"
                                                            variant="p"
                                                            className="officer-detail-email"
                                                        >
                                                            {item?.email}
                                                        </Index.Typography>

                                                        <Index.Typography
                                                            component="p"
                                                            variant="p"
                                                            className="officer-detail-email"
                                                        >
                                                            {item?.officerType}
                                                        </Index.Typography>
                                                    </Index.Box>
                                                   </Index.Box>
                                            </Index.Box>
                                            <Index.Box className="card-right">
                                                <Index.Box className="card-right btn-main-secondary btn-main-red btn-main-orange btn-main-yellow">
                                                    <Index.Box className="btn-main-primary">
                                                        <Index.PrimaryButton
                                                            btnLabel="Transfer"
                                                            className="btn-primary"
                                                            onClick={() => handleTransferOfficer(item?._id)}
                                                        />
                                                    </Index.Box>
                                                </Index.Box>
                                            </Index.Box>
                                        </Index.Box>

                                    ))

                                ) : (
                                    <PagesIndex.NoDataFound />
                                )
                            )
                        }



                    </Index.Box>
                </Index.Box>

            </Index.Modal>
        </>
    );
};

export default JobTransferModel;
