import React, { useEffect, useState } from "react";
import Index from "../../../index";
import PagesIndex from "../../../PageIndex";
import {
  getDashboardOfficerCount,
  getSingleOfficer,
} from "../../../../redux-toolkit/slice/common-slice/CommonServices";

const AdminOfficerDetail = () => {
  const navigate = PagesIndex.useNavigate();
  const dispatch = PagesIndex.useDispatch();
  const location = PagesIndex.useLocation();
  const [officerData, setOfficerData] = useState({});
  const tab = location?.state?.tabValue;
  const depart = location?.state?.agentsignleData;
  const [dashboardCount, setDashboardCount] = useState(false);
  const [loading, setLoading] = useState(false);
  const { officerId } = PagesIndex.useParams();
  const [tabValue, setTabValue] = React.useState(
    location?.state ? location?.state : "TotalOfficer"
  );

  function getPendingProfiles() {
    PagesIndex.DataService.get(
      PagesIndex.Api.Admin.GET_SINGLE_OFFICER + "/" + officerId
    )
      .then(({ data }) => {
        setOfficerData(data?.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  // // get single Officer
  // const getSingleOfficerData = (officerId) => {
  //   // setSignleAgentLoading(true);
  //   dispatch(getSingleOfficer(officerId)).then((res) => {
  //     if (res?.payload?.status == 200) {
  //       setOfficerData(res?.payload?.data);
  //       // setSignleAgentLoading(false);
  //     }
  //   });
  // };

  // get dashboard officer count
  const getDashboardCountData = () => {
    setLoading(true);
    dispatch(getDashboardOfficerCount(officerId)).then((res) => {
      if (res?.payload?.status == 200) {
        setDashboardCount(res?.payload?.data);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    getPendingProfiles();
    getDashboardCountData();
  }, [officerId]);

  console.log(location?.state, tabValue, "uves678");
  return (
    <>
      <Index.Box>
        <Index.Box
          className="user-list-flex"
          onClick={() => {
            if (location?.state?.path === "agentmanagement") {
              navigate(
                `/admin/agentmanagement/adminagentdetails/${location?.state?.agentId}`,
                { state: { tabValue: location?.state?.tabValue } }
              );
            } else {
              navigate(`/admin/officer-management`, {
                state: { tabValue: location?.state?.tabValue },
              });
            }
          }}
        >
          <img
            src={PagesIndex.Svg.leftArrow}
            className="left-arrow-ic"
            alt="search grey img"
          />
        </Index.Box>
        <Index.Box className="officer-details-main">
          <Index.Box className="card-main">
            <Index.Box className="card-left">
              <Index.Box className="officer-profile-flex">
                <img
                  src={
                    officerData?.image
                      ? `${PagesIndex.ADMIN_IMAGE_ENDPOINT}/${officerData?.image}`
                      : PagesIndex.Png.usericon
                  }
                  alt=""
                  className="prof-img"
                />
                <Index.Box className="officer-detail">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="officer-detail-title"
                  >
                    {officerData?.firstName} {officerData?.lastName}
                  </Index.Typography>

                  <Index.Typography
                    component="p"
                    variant="p"
                    className="officer-detail-email"
                  >
                    {officerData?.email || "-"} /{" "}
                    <span>
                      {officerData?.mobileNumber || "-"}
                    </span>
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="officer-detail-numebr"
                  >
                    {officerData?.agencyId?.agencyName || "-"}
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            {depart?.departmentName == "Agency" ||
              depart?.departmentName == "Department" ? null : (
              <Index.Box className="card-right">
                <Index.PrimaryButton
                  btnLabel="View Job History"
                  className="btn-primary"
                  onClick={() => {
                    navigate({
                      pathname: `/admin/admin-job-history/${officerId}`,
                    });
                  }}
                />
              </Index.Box>
            )}
          </Index.Box>
          <Index.Box className="admin-dashboad-row res-admin-dashboard-row">
            <Index.Box sx={{ width: 1 }} className="grid-main">
              <Index.Box
                display="grid"
                className="display-row"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 4",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dashboard-box box-shadow">
                    <Index.Box className="admin-dashboard-inner-box">
                      <Index.Box className="admin-dash-left">
                        <Index.Typography
                          className="admin-dash-text"
                          variant="p"
                          component="p"
                        >
                          Total complete working hours of the week
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="admin-dash-right">
                        <Index.Box className="admin-dash-digit-bg">
                          <Index.Typography
                            className="admin-dash-price"
                            variant="h6"
                            component="h6"
                          >
                            {dashboardCount?.totalWorkingHours}
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 4",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dashboard-box box-shadow">
                    <Index.Box className="admin-dashboard-inner-box">
                      <Index.Box className="admin-dash-left">
                        <Index.Typography
                          className="admin-dash-text"
                          variant="p"
                          component="p"
                        >
                          Total Activated jobs
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="admin-dash-right">
                        <Index.Box className="admin-dash-digit-bg">
                          <Index.Typography
                            className="admin-dash-price"
                            variant="h6"
                            component="h6"
                          >
                            {dashboardCount?.totalActiveJobs}
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 4",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dashboard-box box-shadow">
                    <Index.Box className="admin-dashboard-inner-box">
                      <Index.Box className="admin-dash-left">
                        <Index.Typography
                          className="admin-dash-text"
                          variant="p"
                          component="p"
                        >
                          Total Completed jobs
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="admin-dash-right">
                        <Index.Box className="admin-dash-digit-bg">
                          <Index.Typography
                            className="admin-dash-price"
                            variant="h6"
                            component="h6"
                          >
                            {dashboardCount?.totalCompletedJobs}
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="assigned-officer-main m-t-0">
            <Index.Box className="assigned-officer-header">
              <Index.Typography
                component="p"
                variant="p"
                className="main-title"
              >
                Work Information
              </Index.Typography>
            </Index.Box>
            <Index.Box className="officer-detail">
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Skill :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {officerData?.skills?.length
                    ? officerData?.skills?.join(", ")
                    : "-"}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Rank :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {officerData?.rank || "-"}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Required Pay Rate (Per hour) :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  ${officerData?.rate || "-"}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Total Worked Hours :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {officerData?.totalWorkHours || "-"} hour
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Available For :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {officerData?.jobType?.map((item, index) => item.jobType).join(', ')}
                </Index.Typography>

              </Index.Box>
              <Index.Box className="work-title-flex cus-item-top">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Available on :&nbsp;
                </Index.Typography>
                <Index.Box className="">
                  {officerData?.days?.map((item, index) => {
                    return (
                      <>
                        {item.isActive == true ? (
                          <Index.Typography
                            component="p"
                            variant="p"
                            className="work-value-text"
                          >
                            <span className="day-title">
                              {/* {item?.day} */}
                              {item?.day.charAt(0).toUpperCase() +
                                item?.day.slice(1)}
                            </span>{" "}
                            -{" "}
                            <span>
                              {item?.startTime
                                ? item?.startTime + " to "
                                : null}
                              {item?.endTime}
                            </span>
                          </Index.Typography>
                        ) : null}
                      </>
                    );
                  })}
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          {officerData?.certificates?.length ? (
            <Index.Box className="assigned-officer-main res-doc-main">
              <Index.Box className="assigned-officer-header">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="main-title"
                >
                  Document
                </Index.Typography>
              </Index.Box>

              <Index.Box className="document-wrapper">
                {officerData?.certificates?.map((data, index) => {
                  return (
                    <Index.Box className="document-main">
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="work-value-text"
                      >
                        {/* Document {index + 1} */}
                        {data}
                      </Index.Typography>
                      <Index.PrimaryButton
                        btnLabel="View Document"
                        className="btn-primary"
                        onClick={() => {
                          window.open(
                            `${PagesIndex.ADMIN_IMAGE_ENDPOINT}${data}`
                          );
                        }}
                      />
                    </Index.Box>
                  );
                })}
                {officerData?.license?.map((data, index) => {
                  return (
                    <Index.Box className="document-main">
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="work-value-text"
                      >
                        {/* Document {index + 1} */}
                        {data}
                      </Index.Typography>
                      <Index.PrimaryButton
                        btnLabel="View Document"
                        className="btn-primary"
                        onClick={() => {
                          window.open(
                            `${PagesIndex.ADMIN_IMAGE_ENDPOINT}${data}`
                          );
                        }}
                      />
                    </Index.Box>
                  );
                })}
              </Index.Box>
            </Index.Box>
          ) : (
            ""
          )}
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default AdminOfficerDetail;
