// import list from "../assets/images/svg/list.svg";
import close from "./images/svg/close.svg";
import dashboard from "./images/svg/dashboard.svg";
import jobmanagement from "./images/svg/job-management.svg";
import usercard from "./images/svg/user-card.svg";
import editpage from "./images/svg/edit-page.svg";
import berguremenu from "./images/svg/bergur-menu.svg";
import notification from "./images/svg/notification.svg";
import profilegrey from "./images/svg/profile-grey.svg";
import settings from "./images/svg/setting.svg";
import edit from "./images/svg/edit.svg";
import search from './images/svg/search.svg';
import filter from './images/svg/filter.svg';
import backarrow from './images/svg/back-arrow.svg';
import plus from './images/svg/plus.svg';
import closeblack from './images/svg/close-black.svg'
import download from './images/svg/download.svg';
import leftArrow from './images/svg/left-arrow.svg';
import rightArrow from './images/svg/right-arrow.svg';
import agentmanagement from './images/svg/agent-management.svg';
import help from './images/svg/help.svg';
import content from './images/svg/contentmanagement.svg';
import rating from './images/svg/ratting.svg';
import payment from './images/svg/payment.svg';
import category from './images/svg/category.svg';
// import job from './images/svg/job-management.svg';
import editNew from './images/svg/editNew.svg';
import star from './images/svg/star.svg';
import deletewhite from './images/svg/deletewhite.svg';
import eraser from './images/svg/eraser.svg';
import exports from './images/svg/export.svg';
import wallet from './images/svg/wallet.svg';

import closeicon from './images/svg/closeicon.svg';
import pay from './images/svg/pay.svg';

import deletecross from './images/svg/deletecross.svg';

import nodata from './images/svg/no-data-found.svg';
import nodataimg from './images/svg/no-data-found-img.svg';

import dotmenu from './images/svg/dot-menu.svg';
import zipCode from "./images/svg/zip-code.svg"
import jobType from "./images/svg/project-manager.svg";
import job from "./images/svg/team-management.svg";
import skill from "./images/svg/experience.svg";
import client from "./images/svg/crm.svg";
import cms from "./images/svg/content-management-system.svg";
import system from "./images/svg/system_management.svg";
import organization from "./images/svg/organization.svg";
import department from "./images/svg/department.svg";
import role from "./images/svg/role.svg";
import eyeIcon from './images/svg/view.svg';
import prohibitionIcon from './images/svg/prohibition.svg';

import checkbutton from "./images/svg/check.svg";
import work from "./images/svg/work.svg";
import transfer from "./images/svg/transfer.svg"
import crediCard from "./images/svg/credit-card.svg"
import activeCheck from "./images/svg/active-check.svg"
import checkMark from "./images/svg/check-mark.svg"
import exit from "./images/svg/exit.svg"
import paymentType from "./images/svg/paymentType.svg"
import paymentConfig from "./images/svg/paymentConfig.svg"
import profileConfig from "./images/svg/profileConfig.svg"
import AlertLogo from "./images/svg/alertLogo.svg"
import transferArrow from "./images/svg/transfer-arrow.svg"
import transferLeftArrow from "./images/svg/transfer-left-arrow.svg"

const Svg = {
  zipCode,
  checkbutton,
  work,
  transfer,
  role,
  organization,
  department,
  skill,
  client,
  jobType,
  cms,
  wallet,
  exports,
  eraser,
  job,
  category,
  payment,
  crediCard,
  rating,
  content,
  help,
  close,
  activeCheck,
  dashboard,
  jobmanagement,
  usercard,
  editpage,
  berguremenu,
  notification,
  profilegrey,
  settings,
  edit,
  search,
  filter,
  plus,
  closeblack,
  download,
  backarrow,
  leftArrow,
  rightArrow,
  closeicon,
  agentmanagement,
  editNew,
  star,
  deletewhite,
  pay,
  deletecross,
  nodata,
  nodataimg,
  dotmenu,
  system,
  eyeIcon,
  prohibitionIcon,
  checkMark,
  exit,
  paymentType,
  paymentConfig,
  profileConfig,
  AlertLogo,
  transferArrow,
  transferLeftArrow
};

export default Svg;