import React, { memo, useEffect, useRef, useState } from "react";
import Index from "../../../..";
import PagesIndex from "../../../../PageIndex";
import DataService from "../../../../../config/DataService";


const ChatHistoryModel = ({
    open,
    onClose,
    _jobId,
    _setJobId,
    selectedData,
    tabName
}) => {
    const [chatList, setChatList] = useState([]);
    const [groupedMessages, setGroupedMessages] = useState({});
    const [loading, setLoading] = useState(true);
    const agencyLoginData = PagesIndex.useSelector(
        (state) => state.AgencySlice.agencyLoginData
    );
    const divRef = useRef(null);

    //   useEffect(() => {
    //     if (open) {
    //       setTimeout(() => {
    //         if (divRef.current) {
    //           divRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    //         }
    //       }, 1000);
    //     } 
    //     else {
    //       setChatList([]);
    //     }
    //   }, [open]);

    useEffect(() => {
        const fetchData = async () => {
            if (_jobId) {
                setLoading(true);
                try {
                    const response = await DataService.get(
                        `${PagesIndex.Api.Agency.CHAT_HISTORY}/${_jobId}`);
                    if (response?.status) {
                        console.log("43", response?.data?.data)
                        setChatList(response?.data?.data);
                    }
                } catch (error) {
                    PagesIndex.toast.error(error.message);
                } finally {
                    setLoading(false);
                }
            }
        };
        fetchData();
    }, [_jobId]);

    const groupMessagesByDate = (messages) => {
        return messages.reduce((acc, message) => {
            const date = message?.time ? new Date(message.time).toISOString().split("T")[0] : null;
            if (date && !acc[date]) {
                acc[date] = [];
            }
            if (date) {
                acc[date].push(message);
            }
            return acc;
        }, {});
    };

    useEffect(() => {
        if (chatList && chatList?.messages?.length) {
            const grouped = groupMessagesByDate(chatList?.messages);
            setGroupedMessages(grouped);
        }
    }, [chatList]);

    const formatTime = (time) => {
        return new Date(time).toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
        });
    };

    const today = PagesIndex.moment().format('YYYY-MM-DD');
    const yesterday = PagesIndex.moment().subtract(1, 'day').format('YYYY-MM-DD');

    const displayDate = (date) => {
        if (date === today) return 'Today';
        else if (date === yesterday) return 'Yesterday';
        else return PagesIndex.moment(date).format('MMM DD YYYY');
    };

    console.log("groupedMessages", groupedMessages,)
    return (
        <Index.SwipeableDrawer
            className="filter-main chat-box-section"
            anchor="right"
            open={open}
            onClose={() => {
                onClose();
                _setJobId("");
            }}
            onOpen={() => { }}
        >
            <Index.Box className="filter-header chat-header">
                <Index.Typography className="filter-title chat-title">
                    <Index.Typography className="chat-user-name">
                       { console.log("chatList",chatList)}
                        {chatList?.jobName ?? "Chat List"}
                    </Index.Typography>
                </Index.Typography>
                <img
                    src={PagesIndex.Svg.closeicon}
                    onClick={() => {
                        onClose();
                        _setJobId("");
                    }}
                    alt=""
                />
            </Index.Box>

            {tabName === 0 || true && (
                <Index.Box className="chat-userlist-main-box">
                    {groupedMessages && Object.keys(groupedMessages).map((date) => (
                        <React.Fragment key={date}>
                            <Index.Typography className="chat-date-header">
                                {displayDate(date)}
                            </Index.Typography>
                            {groupedMessages && groupedMessages[date].map((list, index) => (
                                <React.Fragment key={index}>
                                    {agencyLoginData?._id === list.senderId ? (
                                        <Index.Box>
                                            <Index.Typography className="chat-time-text-right chat-time-text">{formatTime(list.time)}</Index.Typography>
                                            <Index.Box className="chat-right-text">
                                                <Index.Typography className="chat-userlist-text">
                                                    {list.message}
                                                </Index.Typography>
                                            </Index.Box>
                                        </Index.Box>
                                    ) : (
                                        <Index.Box>
                                            {console.log("146", list)}
                                            <Index.Typography className="chat-time-text-left chat-time-text"> {list.from} {formatTime(list.time)}</Index.Typography>
                                            <Index.Box className="chat-left-text">
                                                <Index.Typography className="chat-userlist-text">
                                                    {list?.message}
                                                </Index.Typography>
                                            </Index.Box>
                                        </Index.Box>
                                    )}
                                </React.Fragment>
                            ))}
                            <div ref={divRef} />
                        </React.Fragment>
                    ))}
                </Index.Box>
            )}

        </Index.SwipeableDrawer>
    );
};

export default memo(ChatHistoryModel);
