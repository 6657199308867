import React from "react";
import PagesIndex from "../../../PageIndex";
import Index from "../../../index";

const AgencyDetailsPendingOfficer = () => {
  const { agencyLoginData } = PagesIndex.useSelector(
    (state) => state.AgencySlice
  );
  const AgencyName = agencyLoginData?.agencyName;
  const roleName = agencyLoginData?.roleId?.Permission_name;
  const [ratingvalue, setRatingValue] = React.useState(2);
  const location = PagesIndex.useLocation();
  const navigate = PagesIndex.useNavigate();
  const completejobsDetail = location?.state?.officerDetails;
  
  return (
    <>
      <Index.Box className="user-list-flex">
        <Index.Box className="admin-page-title user-list-page-title">

          <Index.Box
            onClick={() =>
              navigate(`/${roleName}/agency-job-management`, { state: location?.state?.tab })
            }
          >
            <img
              src={PagesIndex.Svg.leftArrow}
              className="left-arrow-ic"
              alt="search grey img"
            />
            {completejobsDetail?.title}
          </Index.Box>

        </Index.Box>
      </Index.Box>
      <Index.Box className="mob-inner-scroll-area">
        <Index.Box className="admin-sub-flex">
          <Index.Box className="admin-sub-title-main">
            <Index.Typography
              component="p"
              variant="p"
              className="admin-sub-title"
            >
              Assigned Officer
            </Index.Typography>
          </Index.Box>
        </Index.Box>
        {completejobsDetail?.officerId.map((item, index) => {
          const officershortDetail = item?.officerId;
          return (
            <>
              <Index.Box className="card-main">
                <Index.Box className="card-left">
                  <Index.Box className="officer-profile-flex">
                    <img
                      src={PagesIndex.Png.usericon}
                      className="prof-img"
                      alt=""
                    />
                    <Index.Box className="officer-detail">
                      <Index.Box className="work-title-flex">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="work-title-lable"
                        >
                          {officershortDetail?.firstName ? (
                            <>
                              {officershortDetail?.firstName +
                                " " +
                                officershortDetail?.lastName}
                            </>
                          ) : null}
                          &nbsp;
                        </Index.Typography>
                       {officershortDetail?.officerType == "Active Police Officer" ? (null) : (
                          <Index.Typography
                            component="p"
                            variant="p"
                            className="work-value-text"
                          >
                            ({officershortDetail?.officerType})
                          </Index.Typography>
                        )}
                      </Index.Box>
                      <Index.Box className="work-title-flex">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="work-title-lable"
                        >
                          Officer Assigned on :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="work-value-text"
                        >
                          {PagesIndex.dayjs(item?.jobAcceptedDate).format(
                            "MM-DD-YYYY"
                          )}
                        </Index.Typography>
                      </Index.Box>

                    </Index.Box>
                  </Index.Box>

                </Index.Box>
              </Index.Box>
            </>
          );
        })}


        <Index.Box className="admin-sub-title-main">
          <Index.Typography
            component="p"
            variant="p"
            className="admin-sub-title"
          >
            General Information
          </Index.Typography>
        </Index.Box>
        <Index.Box className="card-main">
          <Index.Box className="card-left">
            <Index.Box className="officer-detail">
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Job Title :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {completejobsDetail?.title}
                </Index.Typography>
              </Index.Box>


              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Posted By :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {completejobsDetail?.userId?.name}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Posted On :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {PagesIndex.dayjs(completejobsDetail?.createdAt).format(
                    "MM-DD-YYYY"
                  )}
                </Index.Typography>
              </Index.Box>

              {
                completejobsDetail?.totalAmount !== "" ? (
                  <Index.Box className="work-title-flex">
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-title-lable"
                    >
                      Total Payment :&nbsp;
                    </Index.Typography>
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-value-text"
                    >
                      {completejobsDetail?.totalAmount}
                    </Index.Typography>
                  </Index.Box>
                ) : null
              }

              {completejobsDetail?.paymentIntentId !== "" ? (

                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Transaction Id  :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {completejobsDetail?.paymentIntentId}
                  </Index.Typography>
                </Index.Box>
              ) : null
              }


              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Phone Number :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {completejobsDetail?.mobileNumber}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Email Address :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {completejobsDetail?.userId?.email}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Point Of Contact Name :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {completejobsDetail?.pointOfContactName}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Point Of Contact Number :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {
                    completejobsDetail?.pointOfContactNumber || "-"
                  }
                </Index.Typography>
              </Index.Box>



              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Location :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {completejobsDetail?.location}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Service :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {completejobsDetail?.service}
                </Index.Typography>
              </Index.Box>

              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Department :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {completejobsDetail?.department}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Job Type :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {completejobsDetail?.jobType?.jobType}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Zip Code :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {completejobsDetail?.zipCode}
                </Index.Typography>
              </Index.Box>
              {completejobsDetail?.supervisor ? (
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Supervisor :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {completejobsDetail?.supervisor}
                  </Index.Typography>
                </Index.Box>) : (null)}
              {completejobsDetail?.nonSupervisor ? (
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Non Supervisor :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {completejobsDetail?.nonSupervisor}
                  </Index.Typography>
                </Index.Box>
              ) : (null)}

              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Date/Time :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {PagesIndex.showDateTime(completejobsDetail)}
                </Index.Typography>
              </Index.Box>

              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Total Hours :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {PagesIndex.timeDifferenceCalculation(completejobsDetail)} hour
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Pay Rate :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {completejobsDetail?.rate ? "$" : null}
                  {completejobsDetail?.rate}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  No of Officer :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {completejobsDetail?.totalOfficer}
                </Index.Typography>
              </Index.Box>
              {/* <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Information :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {completejobsDetail?.information}
                </Index.Typography>
              </Index.Box> */}
              <Index.Box
                className="work-title-flex"
              >
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Description :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {completejobsDetail?.description}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>

        </Index.Box>

        <Index.Box className="admin-sub-title-main">
          <Index.Typography
            component="p"
            variant="p"
            className="admin-sub-title"
          >
            Organizer (Client)
          </Index.Typography>
        </Index.Box>
        <Index.Box className="card-main">
          <Index.Box className="card-left">
            <Index.Box className="officer-detail">
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Name :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {completejobsDetail?.userId?.name}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Mobile Number :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {completejobsDetail?.userId?.mobileNumber}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Email Address :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {completejobsDetail?.userId?.email}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>

        <Index.Box className="admin-sub-title-main">
          <Index.Typography
            component="p"
            variant="p"
            className="admin-sub-title"
          >
            Job Log
          </Index.Typography>
        </Index.Box>

        <Index.Box className="card-main">
          <Index.Box className="card-left">
            <Index.Box className="officer-detail">
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Job Posted On :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {PagesIndex.dayjs(completejobsDetail?.createdAt).format(
                    "MM-DD-YYYY"
                  )}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Job Transferred To :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  -
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Job Transferred On :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  -
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default AgencyDetailsPendingOfficer;
