import React, { useEffect, useRef } from "react";
import Index from "../../../..";
import PagesIndex from "../../../../PageIndex";
import DataService from "../../../../../config/DataService";
import { newAgencyEditJobSchema } from "../../../../../validation/FormikSchema";
import Loader from "../../../../../common/loader/Loader";
import { useDispatch } from "react-redux";
import { getJobTypeAgency } from "../../../../../redux-toolkit/slice/agency-slice/AgencyServices";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

const AgencyEditJobModal = (props) => {
  const dispatch = useDispatch();
  const {
    agencyEditJobData,
    agencyEditJobModalopen,
    handleAgencyEditJobModalClose,
    getJobListData,
    tabValue,
  } = props;

  const [isLoading, setIsLoading] = React.useState(false);
  const [suggestions, setSuggestions] = React.useState([]);
  const formRef = useRef(null);
  const addressInputRef = useRef(null);
  const [address, setAddress] = React.useState("");
  const [isSuggestionSelected, setIsSuggestionSelected] = React.useState({
    state: false,
    city: false,
    zipCode: false,
  });

  const [allJobTypeList, setAllJobTypeList] = React.useState([]);
  const [disableupdate, setDisableUpdate] = React.useState(false);

  console.log("agencyEditJobData", agencyEditJobData);
  // console.log("tabValue54", tabValue);

  const initialValues = {
    vendorId: agencyEditJobData?.agencyIds?.length
      ? agencyEditJobData?.agencyIds[0]?.vendorCode
      : "",
    officerNo: agencyEditJobData ? agencyEditJobData?.totalOfficer : 0,
    title: agencyEditJobData ? agencyEditJobData?.title : "",
    businessNo: agencyEditJobData
      ? agencyEditJobData?.mobileNumber
      : "",
    pointOfContactName: agencyEditJobData
      ? agencyEditJobData?.pointOfContactName
      : "",
    pointOfContactNumber: agencyEditJobData
      ? agencyEditJobData?.pointOfContactNumber
      : "",
    services: agencyEditJobData ? agencyEditJobData?.service : "",
    jobDescription: agencyEditJobData ? agencyEditJobData?.description : "",
    jobType: agencyEditJobData
      ? agencyEditJobData?.jobType && agencyEditJobData?.jobType?._id
      : "",
    address: agencyEditJobData ? agencyEditJobData?.location : "",
    zipCode: agencyEditJobData ? agencyEditJobData?.zipCode : "",
    supervisor: agencyEditJobData ? agencyEditJobData?.supervisor : 0,
    nonSupervisor: agencyEditJobData ? agencyEditJobData?.nonSupervisor : 0,

    jobSchedule: agencyEditJobData?.jobSchedule?.length
      ? agencyEditJobData.jobSchedule.map((schedule) => ({
        ...schedule,
        jobStartDate: dayjs(schedule.jobStartDate).format("YYYY-MM-DD"),
        jobEndDate: dayjs(schedule.jobEndDate).format("YYYY-MM-DD"),
      }))
      : [
        {
          jobStartDate: "",
          jobEndDate: "",
          jobStartTime: "",
          jobEndTime: "",
        },
      ],
    // jobSchedule: [{ jobStartDate: "", jobEndDate: "", jobStartTime: "", jobEndTime: "" }],

    // jobSchedule: agencyEditJobData ? agencyEditJobData.jobSchedule
    // : [{ jobStartDate: "", jobEndDate: "", jobStartTime: "", jobEndTime: "" }],
    // jobSchedule: [{ jobStartDate: "", jobEndDate: "", jobStartTime: "", jobEndTime: "" }],
  };

  useEffect(() => {
    if (agencyEditJobData?.address?.length) {
      setAddress(agencyEditJobData?.address);
    }
  }, [agencyEditJobData]);

  // when the get all job type list then used...
  const handleGetAllJobTypes = async () => {
    dispatch(getJobTypeAgency())
      .then((res) => {
        if (res?.payload?.status == 200) {
          setAllJobTypeList(res?.payload?.data);
        } else {
          setAllJobTypeList([]);
        }
      })
      .catch((error) => {
        console.log(error);
        setAllJobTypeList([]);
      });
  };

  // when the page first time load then used...
  useEffect(() => {
    handleGetAllJobTypes();
  }, []);

  const handleSubmitEditJob = async (values) => {
    setIsLoading(true);
    let checkIsEmptyValue = values.jobSchedule.some(
      (data) =>
        !data.jobStartDate ||
        !data.jobEndDate ||
        !data.jobStartTime ||
        !data.jobEndTime
    );

    if (checkIsEmptyValue) {
      setIsLoading(false);
      return PagesIndex.toast.error(
        "Please select date/time",
        {
          toastId: "customID",
        }
      );
    }

    console.log("values", values)

    if (disableupdate) {
      setIsLoading(false);
      return PagesIndex.toast.error(
        "Please select a valid end date/time",
        {
          toastId: "customID",
        }
      );
    }


    const { officerNo, supervisor, nonSupervisor } = values;

    if (+supervisor + +nonSupervisor !== +officerNo) {
      setIsLoading(false);
      return PagesIndex.toast.error(
        "Supervisor and Non-Supervisor should be equal to total officer",
        {
          toastId: "customID",
        }
      );
    }

    const urlEncoded = new URLSearchParams();
    urlEncoded.append(
      "_id",
      tabValue === "PendingUser"
        ? agencyEditJobData?._id
        : agencyEditJobData.jobId
    );
    urlEncoded.append("title", values.title);
    urlEncoded.append("totalOfficer", values.officerNo);
    urlEncoded.append("jobSchedule", JSON.stringify(values.jobSchedule));
    urlEncoded.append("supervisor", supervisor ?? 0);
    urlEncoded.append("nonSupervisor", nonSupervisor ?? 0);
    urlEncoded.append("pointOfContactName", values.pointOfContactName);
    urlEncoded.append("pointOfContactNumber", values.pointOfContactNumber);
    urlEncoded.append("description", values.jobDescription);
    urlEncoded.append("service", values.services);
    urlEncoded.append("zipCode", values.zipCode);
    urlEncoded.append("jobType", values.jobType);
    urlEncoded.append("mobileNumber", values.businessNo);
    tabValue === "PendingUser" &&
      urlEncoded.append(
        "agencyIds",
        JSON.stringify(agencyEditJobData.agencyIds)
      );

    // console.log("urlEncoded122", tabValue);

    // return;
    try {
      const endpoint =
        tabValue === "PendingUser"
          ? PagesIndex.Api.Common.EDIT_JOB
          : PagesIndex.Api.Common.EDIT_JOB;
      const response = await DataService.post(endpoint, urlEncoded);
      if (response?.data?.status == 200) {
        PagesIndex.toast.success(response?.data?.message, {
          toastId: "customID",
        });

        handleAgencyEditJobModalClose();
        getJobListData();
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      }
    } catch (error) {
      PagesIndex.toast.error(error?.response?.data?.message, {
        toastId: "customID",
      });
      setIsLoading(false);
    }
  };

  const handleAddressChange = async (e, setFieldValue) => {
    setAddress(e.target.value);
    // formRef.current.setFieldValue("address", e.target.value);
    setFieldValue("address", e.target.value);
    if (e.target.value?.length <= 0) {
      setFieldValue("address", "");
      setFieldValue("zipCode", "");
      setIsSuggestionSelected({
        zipCode: false,
      });
    }
    if (e.target.value.length > 3) {
      try {
        const response = await fetch(
          `https://nominatim.openstreetmap.org/search?format=json&addressdetails=1&q=${e.target.value}`
        );
        const data = await response.json();
        setSuggestions(data);
      } catch (error) {
        console.error("Error fetching address:", error);
        setSuggestions([]);
      }
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestion, setFieldValue) => {
    setAddress(suggestion.display_name);
    let zipCode = suggestion.address.postcode || "";
    setFieldValue("address", suggestion.display_name);
    setFieldValue("zipCode", zipCode);
    setIsSuggestionSelected({
      zipCode: zipCode?.length ? true : false,
    });
    setSuggestions([]);
  };

  const handleMobileNumber = (e, setFieldValue, fieldName) => {
    const input = e.target.value;
    const cleaned = input.replace(/\D/g, "");
    // Check if the first digit is 0
    if (cleaned.length > 0 && cleaned[0] == '0') {
      return;
    }
    setFieldValue(fieldName, cleaned);

  };


  return (
    <>
      <Index.Modal
        open={agencyEditJobModalopen}
        onClose={handleAgencyEditJobModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal"
      >
        <Index.Box
          sx={style}
          className="agency-job-modal-inner-main modal-inner"
        >
          <Index.Box className="modal-header">
            <Index.Typography
              id="modal-modal-title"
              className="modal-title"
              variant="h6"
              component="h2"
            >
              Edit Job {agencyEditJobData?.aliasJobId}
            </Index.Typography>
            <img
              src={PagesIndex.Svg.closeblack}
              className="modal-close-icon"
              onClick={handleAgencyEditJobModalClose}
              alt=""
            />
          </Index.Box>
          <PagesIndex.Formik
            enableReinitialize
            validationSchema={newAgencyEditJobSchema}
            initialValues={initialValues}
            onSubmit={handleSubmitEditJob}
          // innerRef={formik}
          >
            {({
              values,
              errors,
              handleChange,
              handleBlur,
              touched,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
            }) => (
              console.log("values227", values),
              console.log("errors", errors),
              (
                <form onSubmit={handleSubmit}>
                  <Index.Box className="modal-body">
                    <Index.Box sx={{ width: 1 }} className="grid-main">
                      <Index.Box
                        display="grid"
                        className="agency-job-modal-row"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                      >
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 4",
                            lg: "span 6",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="input-box modal-input-box">
                            <Index.FormHelperText className="form-lable">
                              Vendor Id
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <Index.TextField
                                fullWidth
                                id="fullWidth"
                                className="form-control"
                                placeholder="Please enter Vendor Id"
                                name="vendorId"
                                value={values?.vendorId}
                                aria-readonly
                                disabled
                              />
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>

                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 4",
                            lg: "span 6",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="input-box modal-input-box">
                            <Index.FormHelperText className="form-lable">
                              How many officers are required ?
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <Index.Select
                                className="dropdown-select drop-select"
                                displayEmpty
                                inputProps={{ "aria-label": "Without label" }}
                                name="officerNo"
                                value={values.officerNo}
                                onChange={(e) => {
                                  // console.log(e.target.value);
                                  setFieldValue("officerNo", e.target.value);
                                }}
                                onBlur={handleBlur}
                                error={errors.officerNo && touched.officerNo}
                                renderValue={
                                  values.officerNo
                                    ? undefined
                                    : () => "Select No of Officers"
                                }
                              >
                                {[
                                  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14,
                                  15, 16, 17, 18, 19, 20,
                                ].map((data, index) => (
                                  <Index.MenuItem
                                    key={index}
                                    value={data}
                                    className="drop-menuitem"
                                  >
                                    {data}
                                  </Index.MenuItem>
                                ))}
                              </Index.Select>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>

                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 4",
                            lg: "span 6",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="input-box modal-input-box">
                            <Index.FormHelperText className="form-lable">
                              Supervisor
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <Index.TextField
                                fullWidth
                                id="fullWidth"
                                type="number"
                                className="form-control"
                                placeholder="Please enter supervisor"
                                name="supervisor"
                                value={values?.supervisor}
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                onBlur={handleBlur}
                                error={
                                  errors.supervisor && touched.supervisor
                                    ? true
                                    : false
                                }
                                helperText={
                                  errors.supervisor && touched.supervisor
                                    ? errors.supervisor
                                    : null
                                }
                              />
                            </Index.Box>
                          </Index.Box>


                        </Index.Box>


                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 4",
                            lg: "span 6",
                          }}
                          className="grid-column"
                        >


                          <Index.Box className="input-box modal-input-box">
                            <Index.FormHelperText className="form-lable">
                              Non Supervisor
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <Index.TextField
                                fullWidth
                                id="fullWidth"
                                type="number"
                                className="form-control"
                                placeholder="Please enter non supervisor"
                                name="nonSupervisor"
                                value={values?.nonSupervisor}
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                onBlur={handleBlur}
                                error={
                                  errors.nonSupervisor && touched.nonSupervisor
                                    ? true
                                    : false
                                }
                                helperText={
                                  errors.nonSupervisor && touched.nonSupervisor
                                    ? errors.nonSupervisor
                                    : null
                                }
                              />
                            </Index.Box>
                          </Index.Box>

                        </Index.Box>


                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 4",
                            lg: "span 6",
                          }}
                          className="grid-column"
                        >

                          <Index.Box className="input-box modal-input-box">
                            <Index.FormHelperText className="form-lable">
                              Job Title
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <Index.TextField
                                fullWidth
                                id="fullWidth"
                                className="form-control"
                                placeholder="Please enter job title"
                                name="title"
                                value={values?.title}
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                onBlur={handleBlur}
                                error={errors.title && touched.title ? true : false}
                                helperText={
                                  errors.title && touched.title ? errors.title : null
                                }
                              />
                            </Index.Box>
                          </Index.Box>

                        </Index.Box>


                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 4",
                            lg: "span 6",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="input-box modal-input-box">
                            <Index.FormHelperText className="form-lable">
                              Business Number
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <Index.TextField
                                fullWidth
                                id="fullWidth"
                                className="form-control"
                                placeholder="Please enter business number"
                                name="businessNo"
                                value={values?.businessNo}
                                onChange={(e) => {
                                  handleMobileNumber(e, setFieldValue, "businessNo")
                                }}
                                inputProps={{ maxLength: 10 }}
                                onBlur={handleBlur}
                                error={
                                  errors.businessNo && touched.businessNo
                                    ? true
                                    : false
                                }
                                helperText={
                                  errors.businessNo && touched.businessNo
                                    ? errors.businessNo
                                    : null
                                }
                              />
                            </Index.Box>
                          </Index.Box>

                        </Index.Box>


                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 4",
                            lg: "span 6",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="input-box modal-input-box">
                            <Index.FormHelperText className="form-lable">
                              Point Of Contact Name
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <Index.TextField
                                fullWidth
                                id="fullWidth"
                                className="form-control"
                                placeholder="Please enter point of contact name"
                                name="pointOfContactName"
                                value={values?.pointOfContactName}
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                onBlur={handleBlur}
                                error={
                                  errors.pointOfContactName &&
                                    touched.pointOfContactName
                                    ? true
                                    : false
                                }
                                helperText={
                                  errors.pointOfContactName &&
                                    touched.pointOfContactName
                                    ? errors.pointOfContactName
                                    : null
                                }
                              />
                            </Index.Box>
                          </Index.Box>

                        </Index.Box>


                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 4",
                            lg: "span 6",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="input-box modal-input-box">
                            <Index.FormHelperText className="form-lable">
                              Point Of Contact Number
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <Index.TextField
                                fullWidth
                                id="fullWidth"
                                className="form-control"
                                placeholder="Please enter point of contact number"
                                name="pointOfContactNumber"
                                value={values?.pointOfContactNumber}
                                onChange={(e) => {
                                  handleMobileNumber(e, setFieldValue, "pointOfContactNumber")
                                }}
                                inputProps={{ maxLength: 10 }}
                                onBlur={handleBlur}
                                error={
                                  errors.pointOfContactNumber && touched.pointOfContactNumber
                                    ? true
                                    : false
                                }
                                helperText={
                                  errors.pointOfContactNumber && touched.pointOfContactNumber
                                    ? errors.pointOfContactNumber
                                    : null
                                }
                              />
                            </Index.Box>
                          </Index.Box>

                        </Index.Box>



                        {/* <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 4",
                            lg: "span 6",
                          }}
                          className="grid-column"
                        >

                          <Index.Box className="input-box modal-input-box">
                            <Index.FormHelperText className="form-lable">
                              Point Of Contact Number
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <Index.TextField
                                fullWidth
                                id="fullWidth"
                                type="number"
                                className="form-control"
                                placeholder="Please enter point of contact number"
                                name="pointOfContactNumber"
                                value={values?.pointOfContactNumber}
                                onChange={(e) => {
                                  handleMobileNumber(e, setFieldValue, "pointOfContactNumber")

                                }}
                                inputProps={{ maxLength: 10 }}
                                onBlur={handleBlur}
                                error={
                                  errors.pointOfContactNumber &&
                                    touched.pointOfContactNumber
                                    ? true
                                    : false
                                }
                                helperText={
                                  errors.pointOfContactNumber &&
                                    touched.pointOfContactNumber
                                    ? errors.pointOfContactNumber
                                    : null
                                }
                              />
                            </Index.Box>
                          </Index.Box>

                        </Index.Box> */}

                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 4",
                            lg: "span 12",
                          }}
                          className="grid-column"
                        >

                          {/* <Index.Box className="input-box modal-input-box">
                            <Index.Box className="input-box">
                              <Index.Box className="form-label-title">
                                <Index.FormHelperText>From Date</Index.FormHelperText>
                                <Index.FormHelperText>To Date</Index.FormHelperText>
                              </Index.Box>

                              <Index.Box className="form-group pwd-icon-btn time-form-group">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                  <Index.Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      gap: 2,
                                    }}
                                  >
                                    {values.jobSchedule.map((schedule, index) => (
                                      // console.log("schedule", schedule),
                                      <Index.Box
                                        key={index}
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                          gap: 2,
                                        }}
                                      >
                                        <Index.DemoContainer
                                          components={["DateTimePicker"]}
                                        >
                                          <Index.DateTimePicker
                                            // label={`From Date ${index + 1}`}
                                            value={
                                              schedule.jobStartDate &&
                                                schedule.jobStartTime
                                                ? dayjs(
                                                  `${schedule.jobStartDate} ${schedule.jobStartTime}`,
                                                  "YYYY-MM-DD HH:mm"
                                                )
                                                : null
                                            }
                                            ampm={false}
                                            minDate={dayjs()}
                                            minTime={
                                              schedule.jobStartDate &&
                                                dayjs(schedule.jobStartDate).isSame(
                                                  dayjs(),
                                                  "day"
                                                )
                                                ? dayjs().add(1, "hour") // Restrict to 1 hour ahead for today
                                                : undefined // Allow all times for future dates
                                            }
                                            
                                            onChange={(newValue) => {
                                              const startDate = newValue
                                                ? newValue.format("YYYY-MM-DD")
                                                : "";
                                              const startTime = newValue
                                                ? newValue.format("HH:mm")
                                                : "";

                                              setFieldValue(
                                                `jobSchedule[${index}].jobStartDate`,
                                                startDate
                                              );
                                              setFieldValue(
                                                `jobSchedule[${index}].jobStartTime`,
                                                startTime
                                              );
                                            }}
                                            viewRenderers={{
                                              hours: renderTimeViewClock,
                                              minutes: renderTimeViewClock,
                                              // seconds: renderTimeViewClock,
                                            }}
                                          />
                                        </Index.DemoContainer>

                                        <Index.DemoContainer
                                          components={["DateTimePicker"]}
                                        >
                                          <Index.DateTimePicker
                                            // label={`To Date ${index + 1}`}
                                            value={
                                              schedule.jobEndDate &&
                                                schedule.jobEndTime
                                                ? dayjs(
                                                  `${schedule.jobEndDate} ${schedule.jobEndTime}`,
                                                  "YYYY-MM-DD HH:mm"
                                                )
                                                : null
                                            }
                                            // value={schedule.jobEndDate ? dayjs(schedule.jobEndDate) : null}
                                            ampm={false}
                                            minDate={dayjs()}
                                            minTime={
                                              schedule.jobEndDate &&
                                                dayjs(schedule.jobEndDate).isSame(
                                                  dayjs(),
                                                  "day"
                                                )
                                                ? dayjs().add(1, "hour") // Restrict to 1 hour ahead for today
                                                : undefined // Allow all times for future dates
                                            }
                                    
                                            onChange={(newValue) => {
                                              const endDate = newValue
                                                ? newValue.format("YYYY-MM-DD")
                                                : "";
                                              const endTime = newValue
                                                ? newValue.format("HH:mm")
                                                : "";

                                              setFieldValue(
                                                `jobSchedule[${index}].jobEndDate`,
                                                endDate
                                              );
                                              setFieldValue(
                                                `jobSchedule[${index}].jobEndTime`,
                                                endTime
                                              );
                                            }}
                                            viewRenderers={{
                                              hours: renderTimeViewClock,
                                              minutes: renderTimeViewClock,
                                              // seconds: renderTimeViewClock,
                                            }}
                                          />
                                        </Index.DemoContainer>

                                        {schedule.jobStartDate &&
                                          schedule.jobStartTime &&
                                          schedule.jobEndDate &&
                                          schedule.jobEndTime && (
                                            <Index.Box>
                                              {dayjs(
                                                `${schedule.jobEndDate} ${schedule.jobEndTime}`,
                                                "YYYY-MM-DD HH:mm"
                                              )
                                                .diff(
                                                  dayjs(
                                                    `${schedule.jobStartDate} ${schedule.jobStartTime}`,
                                                    "YYYY-MM-DD HH:mm"
                                                  ),
                                                  "hours",
                                                  true
                                                )
                                                .toFixed(2)}{" "}
                                              hr
                                            </Index.Box>
                                          )}
                                        {console.log("696", index)}
                                        {tabValue == "PendingUser" && (
                                          <Index.Button
                                            variant="outlined"
                                            color="error"
                                            disabled={index === 0}
                                            onClick={() => {
                                              const updatedSchedule =
                                                values.jobSchedule.filter(
                                                  (_, i) => i !== index
                                                );
                                              setFieldValue(
                                                "jobSchedule",
                                                updatedSchedule
                                              );
                                            }}
                                          >
                                            -
                                          </Index.Button>
                                        )}
                                      </Index.Box>
                                    ))}

                                    {tabValue == "PendingUser" && (
                                      <Index.Button
                                        variant="contained"
                                        onClick={() =>
                                          setFieldValue("jobSchedule", [
                                            ...values.jobSchedule,
                                            {
                                              jobStartDate: "",
                                              jobEndDate: "",
                                              jobStartTime: "",
                                              jobEndTime: "",
                                            },
                                          ])
                                        }
                                      >
                                        +
                                      </Index.Button>
                                    )}
                                  </Index.Box>
                                </LocalizationProvider>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box> */}

                          <Index.Box className="input-box modal-input-box">
                            <Index.Box className="input-box">
                              <Index.Box className="form-label-title">
                                <Index.FormHelperText>From Date</Index.FormHelperText>
                                <Index.FormHelperText>To Date</Index.FormHelperText>
                              </Index.Box>

                              <Index.Box className="form-group pwd-icon-btn time-form-group">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                  <Index.Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      gap: 2,
                                    }}
                                  >
                                    {values.jobSchedule.map((schedule, index) => (
                                      <Index.Box
                                        key={index}
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                          gap: 2,
                                        }}
                                      >
                                        <Index.DemoContainer components={["DateTimePicker"]}>
                                          <Index.DateTimePicker
                                            value={
                                              schedule.jobStartDate && schedule.jobStartTime
                                                ? dayjs(
                                                  `${schedule.jobStartDate} ${schedule.jobStartTime}`,
                                                  "YYYY-MM-DD HH:mm"
                                                )
                                                : null
                                            }
                                            ampm={false}
                                            minDate={dayjs()}
                                            minTime={
                                              schedule.jobStartDate &&
                                                dayjs(schedule.jobStartDate).isSame(dayjs(), "day")
                                                ? dayjs().add(1, "hour") // Restrict to 1 hour ahead for today
                                                : undefined // Allow all times for future dates
                                            }
                                            onChange={(newValue) => {
                                              const startDate = newValue
                                                ? newValue.format("YYYY-MM-DD")
                                                : "";
                                              const startTime = newValue
                                                ? newValue.format("HH:mm")
                                                : "";

                                              setFieldValue(
                                                `jobSchedule[${index}].jobStartDate`,
                                                startDate
                                              );
                                              setFieldValue(
                                                `jobSchedule[${index}].jobStartTime`,
                                                startTime
                                              );
                                            }}

                                            slotProps={{
                                              textField: {
                                                readOnly: true,
                                                size: "small",
                                              },
                                              actionBar: { actions: [] },
                                            }}
                                           
                                            viewRenderers={{
                                              hours: renderTimeViewClock,
                                              minutes: renderTimeViewClock,
                                              // seconds: renderTimeViewClock,
                                            }}
                                          />
                                        </Index.DemoContainer>

                                        <Index.DemoContainer components={["DateTimePicker"]}>
                                          <Index.DateTimePicker
                                            value={
                                              schedule.jobEndDate && schedule.jobEndTime
                                                ? dayjs(
                                                  `${schedule.jobEndDate} ${schedule.jobEndTime}`,
                                                  "YYYY-MM-DD HH:mm"
                                                )
                                                : null
                                            }
                                            ampm={false}
                                            minDate={
                                              schedule.jobStartDate
                                                ? dayjs(schedule.jobStartDate)
                                                : dayjs()
                                            }
                                            minTime={
                                              schedule.jobStartDate && schedule.jobStartTime
                                                ? dayjs(
                                                  `${schedule.jobStartDate} ${schedule.jobStartTime}`
                                                ).add(1, "hour") // Ensure at least 1 hour difference
                                                : undefined
                                            }
                                            onChange={(newValue) => {
                                              const endDate = newValue
                                                ? newValue.format("YYYY-MM-DD")
                                                : "";
                                              const endTime = newValue
                                                ? newValue.format("HH:mm")
                                                : "";

                                              setFieldValue(
                                                `jobSchedule[${index}].jobEndDate`,
                                                endDate
                                              );
                                              setFieldValue(
                                                `jobSchedule[${index}].jobEndTime`,
                                                endTime
                                              );
                                            }}
                                            slotProps={{
                                              textField: {
                                                readOnly: true,
                                                size: "small",
                                              },
                                              actionBar: { actions: [] },
                                            }}
                                           
                                            viewRenderers={{
                                              hours: renderTimeViewClock,
                                              minutes: renderTimeViewClock,
                                              // seconds: renderTimeViewClock,
                                            }}
                                          />
                                        </Index.DemoContainer>

                                        {schedule.jobStartDate &&
                                          schedule.jobStartTime &&
                                          schedule.jobEndDate &&
                                          schedule.jobEndTime && (
                                            // <Index.Box>
                                            //   {(() => {
                                            //     const startDateTime = dayjs(
                                            //       `${schedule.jobStartDate} ${schedule.jobStartTime}`,
                                            //       "YYYY-MM-DD HH:mm"
                                            //     );
                                            //     const endDateTime = dayjs(
                                            //       `${schedule.jobEndDate} ${schedule.jobEndTime}`,
                                            //       "YYYY-MM-DD HH:mm"
                                            //     );
                                            //     const diff = endDateTime.diff(startDateTime, "hours", true);

                                            //     if (diff < 0) {
                                            //       // Show toaster for negative difference
                                            //       setDisableUpdate(true);
                                            //     } else {
                                            //       setDisableUpdate(false);
                                            //     }

                                            //     return `${diff.toFixed(2)} hr`;
                                            //   })()}
                                            // </Index.Box>

                                            <Index.Box>
                                              {(() => {
                                                const startDateTime = dayjs(
                                                  `${schedule.jobStartDate} ${schedule.jobStartTime}`,
                                                  "YYYY-MM-DD HH:mm"
                                                );
                                                const endDateTime = dayjs(
                                                  `${schedule.jobEndDate} ${schedule.jobEndTime}`,
                                                  "YYYY-MM-DD HH:mm"
                                                );
                                                const diffInMinutes = endDateTime.diff(startDateTime, "minutes");
                                                const hours = Math.floor(diffInMinutes / 60);
                                                const minutes = diffInMinutes % 60;
                                                const formattedDiff = `${hours}.${minutes < 10 ? `0${minutes}` : minutes}`; // Format as "HH.MM"
                                                if (diffInMinutes < 0) {
                                                  setDisableUpdate(true);

                                                } else {
                                                  setDisableUpdate(false);
                                                }
                                                return `${formattedDiff} hr`;
                                              })()}
                                            </Index.Box>
                                          )}
                                        {tabValue == "PendingUser" && (
                                          <Index.Button
                                            variant="outlined"
                                            color="error"
                                            disabled={index === 0}
                                            onClick={() => {
                                              const updatedSchedule = values.jobSchedule.filter(
                                                (_, i) => i !== index
                                              );
                                              setFieldValue("jobSchedule", updatedSchedule);
                                            }}
                                          >
                                            -
                                          </Index.Button>
                                        )}
                                      </Index.Box>
                                    ))}

                                    {tabValue == "PendingUser" && (
                                      <Index.Button
                                        variant="contained"
                                        onClick={() =>
                                          setFieldValue("jobSchedule", [
                                            ...values.jobSchedule,
                                            {
                                              jobStartDate: "",
                                              jobEndDate: "",
                                              jobStartTime: "",
                                              jobEndTime: "",
                                            },
                                          ])
                                        }
                                      >
                                        +
                                      </Index.Button>
                                    )}
                                  </Index.Box>
                                </LocalizationProvider>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>

                        </Index.Box>


                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 4",
                            lg: "span 12",
                          }}
                          className="grid-column"
                        >

                          <Index.Box className="input-box modal-input-box">
                            <Index.FormHelperText className="form-lable">
                              Job Address
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <Index.TextareaAutosize
                                fullWidth
                                id="fullWidth"
                                name="address"
                                className="form-control-textarea hgt-address"
                                placeholder="Please enter address"
                                value={values.address}
                                onChange={(e) =>
                                  handleAddressChange(e, setFieldValue)
                                }
                                error={
                                  touched.address && errors.address ? true : false
                                }
                                helperText={
                                  touched.address && errors.address ? true : false
                                }
                              />
                              {suggestions.length > 0 && (
                                <ul className="address-list">
                                  {suggestions.map((suggestion, index) => (
                                    <li
                                      key={index}
                                      onClick={() =>
                                        handleSuggestionClick(
                                          suggestion,
                                          setFieldValue
                                        )
                                      }
                                    >
                                      {suggestion.display_name}
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </Index.Box>
                            {touched.address && errors.address && (
                              <Index.FormHelperText error>
                                {errors.address}
                              </Index.FormHelperText>
                            )}
                          </Index.Box>

                        </Index.Box>

                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 4",
                            lg: "span 12",
                          }}
                          className="grid-column"
                        >

                          <Index.Box className="input-box modal-input-box">
                            <Index.FormHelperText className="form-lable">
                              Job Description
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <Index.TextareaAutosize
                                fullWidth
                                id="fullWidth"
                                name="jobDescription"
                                className="form-control-textarea hgt-address"
                                placeholder="Please enter job description"
                                value={values.jobDescription}
                                onChange={handleChange}
                                error={
                                  touched.jobDescription && errors.jobDescription
                                    ? true
                                    : false
                                }
                                helperText={
                                  touched.jobDescription && errors.jobDescription
                                    ? true
                                    : false
                                }
                              />
                            </Index.Box>
                          </Index.Box>

                        </Index.Box>


                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 4",
                            lg: "span 6",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="input-box modal-input-box">
                            <Index.FormHelperText className="form-lable">
                              Zipcode
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <Index.TextField
                                id="fullWidth"
                                // type="number"
                                className="form-control w-100-full"
                                name="zipCode"
                                labelId="stateName-placeholder"
                                placeholder="Please enter zip code"
                                value={values.zipCode}
                                disabled={
                                  isSuggestionSelected?.zipCode ? true : false
                                }
                                onChange={(e) => {
                                  const numberPattern = /^[0-9-]+$/;
                                  if (e.target?.value?.length <= 0) {
                                    setFieldValue("zipCode", "");
                                  }
                                  if (
                                    numberPattern.test(e.target.value) &&
                                    e.target.value?.length <= 9
                                  ) {
                                    setFieldValue("zipCode", e.target.value);
                                  }
                                }}
                                error={
                                  touched.zipCode && errors.zipCode ? true : false
                                }
                                helperText={
                                  touched.zipCode && errors.zipCode ? true : false
                                }
                              />
                            </Index.Box>
                            {touched.zipCode && errors.zipCode && (
                              <Index.FormHelperText error>
                                {errors.zipCode}
                              </Index.FormHelperText>
                            )}
                          </Index.Box>

                        </Index.Box>


                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 4",
                            lg: "span 6",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="input-box modal-input-box">
                            <Index.FormHelperText className="form-lable">
                              Services
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <Index.TextField
                                fullWidth
                                id="fullWidth"
                                className="form-control"
                                placeholder="Please enter services"
                                name="services"
                                value={values?.services}
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                onBlur={handleBlur}
                                error={
                                  errors.services && touched.services ? true : false
                                }
                                helperText={
                                  errors.services && touched.services
                                    ? errors.services
                                    : null
                                }
                              />
                            </Index.Box>
                          </Index.Box>


                        </Index.Box>





                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 4",
                            lg: "span 6",
                          }}
                          className="grid-column"
                        >


                          <Index.Box className="input-box modal-input-box">
                            <Index.FormHelperText className="form-lable">
                              Job Type
                            </Index.FormHelperText>
                            {console.log("values", values)}
                            <Index.Box className="form-group">
                              <Index.Select
                                className="dropdown-select drop-select"
                                displayEmpty
                                inputProps={{ "aria-label": "Without label" }}
                                name="type"
                                value={values.jobType}
                                onChange={(e) => {
                                  console.log(e.target.value);
                                  setFieldValue("jobType", e.target.value);
                                }}
                                onBlur={handleBlur}
                                error={errors.jobType && touched.jobType}
                                renderValue={
                                  values.jobType ? undefined : () => "Select Job Type"
                                }
                              >
                                {allJobTypeList.map((data, index) => (
                                  <Index.MenuItem
                                    key={index}
                                    value={data?._id}
                                    className="drop-menuitem"
                                  >
                                    {data.jobType}
                                  </Index.MenuItem>
                                ))}
                              </Index.Select>
                            </Index.Box>
                          </Index.Box>

                        </Index.Box>
                      </Index.Box>
                    </Index.Box>

                  </Index.Box>
                  <Index.Box className="modal-footer">
                    <Index.Box className="modal-footer-btn-flex">
                      <Index.PrimaryButton
                        btnLabel="Update"
                        className="btn-primary bg-approve"
                        type="submit"
                        disabled={isLoading}
                      >
                        {isLoading ? <Loader /> : "Click Me"}
                      </Index.PrimaryButton>
                      <Index.PrimaryButton
                        btnLabel="Cancel"
                        className="btn-primary bg-suspend"
                        onClick={() => handleAgencyEditJobModalClose()}
                      />
                    </Index.Box>
                  </Index.Box>
                </form>
              )
            )}
          </PagesIndex.Formik>
        </Index.Box>
      </Index.Modal>
    </>
  );
};

export default AgencyEditJobModal;
