import React, { useEffect, useState } from "react";
import Index from "../../../..";
import PagesIndex from "../../../../PageIndex";
import Loader from "../../../../../common/loader/Loader";
import { convertHoursToHHMM } from "../../../../../common/timeCalculation/ExtendTime";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};
const AdminPendingJobOfficerAceeptModal = (props) => {
  const {
    pendingjobopen,
    pendingjobhandleClose,
    pendingjobOfficerAcceptData,
    getJobListData,
    isShowApproveRejectButton,
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [jobList, setJobList] = useState();
  const [loading, setLoading] = useState(false);
  const [hideButton, setHideButton] = useState(false);
  const currentDate = PagesIndex.moment();

  // get officer job single Detail's
  const getOfficerAcceptJObDetail = (status) => {
    setLoading(true);
    if (pendingjobOfficerAcceptData) {
      const urlEncoded = new URLSearchParams();
      urlEncoded.append("jobId", pendingjobOfficerAcceptData?.jobId);
      urlEncoded.append("officerId", pendingjobOfficerAcceptData?.officerId);

      PagesIndex?.DataService?.post(
        PagesIndex.Api.Admin.GET_JOB_DETAIL_OFFICER_ACCEPT,
        urlEncoded
      )
        .then((res) => {
          if (res?.data?.status === 200) {
            setJobList(res?.data?.data);
          }
          setLoading(false);
        })
        .catch((err) => {
          PagesIndex.toast.error(err?.response?.data?.mes);
          setLoading(false); // Make sure to setLoading(false) on error too.
        });
    } else {
      setLoading(false); // Set loading to false if pendingjobOfficerAcceptData is null.
    }
  };

  // job approverd / Rejected
  const handleApproveRejectRequest = (status) => {
    setIsLoading(true);
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("jobId", pendingjobOfficerAcceptData?.jobId);
    urlEncoded.append("officerId", pendingjobOfficerAcceptData?.officerId);
    urlEncoded.append(
      "officerJobId",
      pendingjobOfficerAcceptData?.officerJobId
    );
    urlEncoded.append("officerStatus", status);
    PagesIndex.DataService.post(
      PagesIndex.Api.Admin.JOB_APPROVE_REJECT_OFFICER,
      urlEncoded
    )
      .then((res) => {
        if (res?.data?.status === 200) {
          pendingjobhandleClose();
          PagesIndex.toast.success(res?.data?.message);
          getJobListData();
          setTimeout(() => {
            setIsLoading(false);
          }, 2000);
        }
      })
      .catch((err) => {
        PagesIndex.toast.error(err.response.data.mes);
        setIsLoading(false);
      });
  };
  console.log(pendingjobOfficerAcceptData, "officerIdhjhjhj");

  useEffect(() => {
    getOfficerAcceptJObDetail();
  }, [pendingjobOfficerAcceptData]);

  return (
    <>
      <Index.Modal
        open={pendingjobopen}
        onClose={pendingjobhandleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal"
      >
        <Index.Box sx={style} className="add-user-modal-inner-main modal-inner">
          {loading ? (
            <Loader />
          ) : (
            <Index.Box>
              <Index.Box className="modal-header">
                <Index.Typography
                  id="modal-modal-title"
                  className="modal-title"
                  variant="h6"
                  component="h2"
                >
                  Officer Details
                </Index.Typography>
                <img
                  src={PagesIndex.Svg.closeblack}
                  className="modal-close-icon"
                  onClick={pendingjobhandleClose}
                  alt=""
                />
              </Index.Box>
              <Index.Box className="modal-body">
                <>
                  {jobList?.officerId.length &&
                    jobList?.officerId.map((item) => {
                      const officerlist = item?.officerId;
                      return (
                        <>
                          <Index.Box className="work-title-flex">
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="work-title-lable"
                            >
                              Name :&nbsp;
                            </Index.Typography>
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="work-value-text"
                            >
                              {officerlist?.firstName} {officerlist?.lastName}
                            </Index.Typography>
                          </Index.Box>
                          <Index.Box className="work-title-flex">
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="work-title-lable"
                            >
                              Email Address :&nbsp;
                            </Index.Typography>
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="work-value-text"
                            >
                              {officerlist?.email || "-"}
                            </Index.Typography>
                          </Index.Box>
                          <Index.Box className="work-title-flex">
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="work-title-lable"
                            >
                              Phone Number :&nbsp;
                            </Index.Typography>
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="work-value-text"
                            >
                             {officerlist?.mobileNumber ||
                                "-"}
                            </Index.Typography>
                          </Index.Box>
                          <Index.Box className="work-title-flex">
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="work-title-lable"
                            >
                              Rank :&nbsp;
                            </Index.Typography>
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="work-value-text"
                            >
                              {officerlist?.rank || "-"}
                            </Index.Typography>
                          </Index.Box>
                          {officerlist?.yearsOnDuty &&
                            officerlist?.monthsOnDuty ? (
                            <Index.Box className="work-title-flex">
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="work-title-lable"
                              >
                                TOD (Time On Duty) :&nbsp;
                              </Index.Typography>
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="work-value-text"
                              >
                                {officerlist?.yearsOnDuty || "-"} year{" "}
                                {officerlist?.monthsOnDuty || "-"} month
                              </Index.Typography>
                            </Index.Box>
                          ) : null}

                          <Index.Box className="work-title-flex">
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="work-title-lable"
                            >
                              Required Pay Rate (Per hour) :&nbsp;
                            </Index.Typography>
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="work-value-text"
                            >
                              {officerlist?.rate ? "$" : null}
                              {officerlist?.rate || "-"}
                            </Index.Typography>
                          </Index.Box>

                          <Index.Box className="work-title-flex">
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="work-title-lable"
                            >
                              Available For :&nbsp;
                            </Index.Typography>
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="work-value-text"
                            >
                            {officerlist?.jobType?.map((item, index) => item.jobType).join(', ')}

                            </Index.Typography>
                          </Index.Box>
                          {officerlist?.jobName == "Supervisor" ? (
                            <Index.Box className="work-title-flex">
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="work-title-lable"
                              >
                                Officer Type :&nbsp;
                              </Index.Typography>
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="work-value-text"
                              >
                                {officerlist?.jobName}
                              </Index.Typography>
                            </Index.Box>
                          ) : (
                            <Index.Box className="work-title-flex">
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="work-title-lable"
                              >
                                Non Supervisor :&nbsp;
                              </Index.Typography>
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="work-value-text"
                              >
                                {officerlist?.jobName}
                              </Index.Typography>
                            </Index.Box>
                          )}
                          <Index.Box className="work-title-flex">
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="work-title-lable"
                            >
                              Required Skill :&nbsp;
                            </Index.Typography>
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="work-value-text"
                            >
                              {officerlist?.skills?.length
                                ? officerlist.skills.join(", ")
                                : "-"}
                            </Index.Typography>
                          </Index.Box>


                          <Index.Box className="work-title-flex">
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="work-title-lable "
                            >
                              Date/Time :&nbsp;
                            </Index.Typography>
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="work-value-text"
                            >
                              <span className="day-title">
                                {PagesIndex.dayjs(
                                  jobList[0]?.jobSchedule[0].jobStartDate
                                ).format("MM-DD-YYYY") +
                                  " to " +
                                  PagesIndex.dayjs(
                                    item?.jobEndDate !== undefined ? item.jobEndDate : jobList[0]?.jobSchedule[0].jobEndDate
                                  ).format("MM-DD-YYYY")}
                              </span>{" "}
                              {" "}

                            </Index.Typography>
                          </Index.Box>

                          {item.extendedHours !== "0" ? (
                            <Index.Box className="work-title-flex">
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="work-title-lable "
                              >
                                Extend Time :&nbsp;
                              </Index.Typography>

                              <Index.Typography
                                component="p"
                                variant="p"
                                className="work-value-text"
                              >
                                {convertHoursToHHMM(item?.extendedHours)}
                                {/* {item?.extendHours} */}
                              </Index.Typography>
                            </Index.Box>
                          ) : null
                          }


                          <Index.Box className="work-title-flex">
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="work-title-lable"
                            >
                              Available on :&nbsp;
                            </Index.Typography>

                            <Index.Box className="">
                              {officerlist?.days?.map((item, index) => {
                                return (
                                  <>
                                    {item.isActive == true ? (
                                      <Index.Typography
                                        component="p"
                                        variant="p"
                                        // className="job-title-text-label"
                                        className="work-value-text"
                                      >
                                        <span className="day-title">
                                          {item?.day.charAt(0).toUpperCase() +
                                            item?.day.slice(1)}
                                        </span>{" "}
                                        -{" "}
                                        <span>
                                          {item?.startTime
                                            ? item?.startTime + " to "
                                            : null}
                                          {item?.endTime}
                                        </span>
                                      </Index.Typography>
                                    ) : null}
                                  </>
                                );
                              })}
                            </Index.Box>
                          </Index.Box>

                          {officerlist?.certificates?.length ||
                            officerlist?.license?.length ? (
                            <Index.Box className="assigned-officer-main">
                              <Index.Box className="work-title-flex">
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="work-title-lable"
                                >
                                  Document :&nbsp;
                                </Index.Typography>
                              </Index.Box>

                              <Index.Box className="document-wrapper">
                                {officerlist?.certificates?.map(
                                  (data, index) => {
                                    return (
                                      <Index.Box className="document-main">
                                        <Index.Typography
                                          component="p"
                                          variant="p"
                                          className="job-title-text-label"
                                        >
                                          {data}
                                        </Index.Typography>
                                        <Index.PrimaryButton
                                          btnLabel="View Document"
                                          className="btn-primary"
                                          onClick={() => {
                                            window.open(
                                              `${PagesIndex.ADMIN_IMAGE_ENDPOINT}/${data}`
                                            );
                                          }}
                                        />
                                      </Index.Box>
                                    );
                                  }
                                )}

                                {officerlist?.license?.map((data, index) => {
                                  console.log(officerlist, "officerlist");
                                  return (
                                    <Index.Box className="document-main">
                                      <Index.Typography
                                        component="p"
                                        variant="p"
                                        className="job-title-text-label"
                                      >
                                        {data}
                                      </Index.Typography>
                                      <Index.PrimaryButton
                                        btnLabel="View Document"
                                        className="btn-primary"
                                        onClick={() => {
                                          window.open(
                                            `${PagesIndex.ADMIN_IMAGE_ENDPOINT}/${data}`
                                          );
                                        }}
                                      />
                                    </Index.Box>
                                  );
                                })}
                              </Index.Box>
                            </Index.Box>
                          ) : null}
                        </>
                      );
                    })}
                </>
              </Index.Box>
              {isShowApproveRejectButton ? (
                <Index.Box className="modal-footer">
                  <Index.Box className="modal-footer-btn-flex">
                    <Index.PrimaryButton
                      btnLabel={
                        jobList?.officerId[0]?.officerStatus === "APPROVED"
                          ? "Approved"
                          : "Approve"
                      }
                      className="btn-primary bg-approve"
                      onClick={() => handleApproveRejectRequest("APPROVED")}
                      disabled={isLoading}
                    >
                      {isLoading ? <Loader /> : "Click Me"}
                    </Index.PrimaryButton>

                    <Index.PrimaryButton
                      btnLabel={
                        jobList?.officerId[0]?.officerStatus === "REJECTED"
                          ? "Rejected"
                          : "Reject"
                      }
                      className="btn-primary bg-suspend"
                      onClick={() => handleApproveRejectRequest("REJECTED")}
                      disabled={
                        jobList?.officerId[0]?.officerStatus === "REJECTED"
                      }
                    ></Index.PrimaryButton>
                  </Index.Box>
                </Index.Box>
              ) : null}
            </Index.Box>
          )}
        </Index.Box>
      </Index.Modal>
    </>
  );
};

export default AdminPendingJobOfficerAceeptModal;
