import React, { useEffect, useState } from "react";
import Index from "../../../..";
import PagesIndex from "../../../../PageIndex";
import dayjs from "dayjs";
import Loader from "../../../../../common/loader/Loader";
import { agencyRateSchema } from "../../../../../validation/FormikSchema";
import DataService from "../../../../../config/DataService";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};
const PendingRateJobModal = (props) => {
  const {
    pandingEditRequestopen,
    handleCloseEditPandingRequest,
    pendingEditjobId,
    getJobListData,
    agencyJobData
  } = props;

  const [initialValues, setInitialValues] = useState({ rate: "" });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (agencyJobData.length) {
      setInitialValues({ rate: agencyJobData.length ? agencyJobData[0]?.rate : "" });
    }
  }, [agencyJobData]);
  console.log(initialValues, "initialValues", "getJobListData", agencyJobData)

  // job approverd / Rejected
  //   const handleEditRequest = (status) => {
  //     setIsLoading(true);
  //     const urlEncoded = new URLSearchParams();
  //     urlEncoded.append("id", pendingjobId?._id);
  //     urlEncoded.append("status", status);
  //     PagesIndex.DataService.post(
  //       PagesIndex.Api.Agency.JOB_APPROVE_REJECT,
  //       urlEncoded
  //     )
  //       .then((res) => {
  //         if (res?.data?.status === 200) {
  //           setapproveRejectStatus(true);
  //           PagesIndex?.toast?.success(res?.data?.message);
  //           handleClosePandingRequest();
  //           getJobListData();
  //           setIsLoading(false);
  //         }
  //       })
  //       .catch((err) => {
  //         PagesIndex?.toast?.error(err?.response?.data?.mes);
  //         setIsLoading(false);
  //       });
  //   };



  const handleEditRequest = async (values) => {
    setIsLoading(true);
    if (values.rate <= 0) {
      setIsLoading(false);
      return PagesIndex.toast.error("Pay rate should be greater than zero");
    }
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("id", pendingEditjobId?.jobId);
    urlEncoded.append("rate", values.rate);

    try {
      const response = await DataService.post(
        PagesIndex.Api.Agency.AGENCY_ADD_JOB_RATE,
        urlEncoded
      );
      if (response?.data?.status == 200) {
        PagesIndex.toast.success(response?.data?.message);
        handleCloseEditPandingRequest();
        getJobListData();
        setIsLoading(false);
      }


    } catch (error) {
      PagesIndex.toast.error(error?.response?.data?.message);
      setIsLoading(false);
    }
  };



  return (
    <>
      <Index.Modal
        open={pandingEditRequestopen}
        onClose={handleCloseEditPandingRequest}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal"
      >
        <PagesIndex.Formik
          initialValues={initialValues}
          onSubmit={handleEditRequest}
          validationSchema={agencyRateSchema}
        >
          {({
            values,
            errors,
            handleChange,
            handleBlur,
            touched,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
          }) => (
            <form onSubmit={handleSubmit}>
              {/* {console.log(errors, "error")} */}
              {/* {console.log(values.zipCode, "zip")} */}
              <Index.Box
                sx={style}
                className="add-user-modal-inner-main modal-inner"
              >
                <Index.Box className="modal-header">
                  <Index.Typography
                    id="modal-modal-title"
                    className="modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Pending Job Requests (From Users)
                  </Index.Typography>
                  <img
                    src={PagesIndex.Svg.closeblack}
                    className="modal-close-icon"
                    onClick={handleCloseEditPandingRequest}
                    alt=""
                  />
                </Index.Box>
                <Index.Box className="modal-body">
                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Pay Rate ($ Per hour)
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        type="number"
                        fullWidth
                        id="fullWidth"
                        className="form-control"
                        placeholder="Enter Rate"
                        name="rate"
                        onBlur={handleBlur}
                        value={values.rate}
                        onChange={handleChange}
                        inputProps={{ maxLength: 6 }}
                        error={errors.rate && touched.rate ? true : false}
                        helperText={
                          errors.rate && touched.rate ? errors.rate : null
                        }
                        // onKeyDown={(e) => {
                        //   if (
                        //     (e.key === " " &&
                        //       e.target.value.trim() === "")
                        //   ) {
                        //     e.preventDefault();
                        //   }
                        // }}

                        onKeyDown={(e) => {
                          const currentValue = e.target.value;
                          const key = e.key;
                          const isValidInput = /^[0-9.]*$/.test(key);

                          // Check if input is not a number, decimal point, or already has 6 digits before the decimal point
                          if (
                            (!isValidInput ||
                              (key === '.' && currentValue.includes('.')) ||
                              (currentValue.split('.')[0].length >= 6 && key !== '.')) &&
                            // Allow navigation keys, delete, backspace, and arrow keys
                            !['ArrowLeft', 'ArrowRight', 'Delete', 'Backspace', 'Tab'].includes(key)
                          ) {
                            e.preventDefault();
                          }
                        }}

                      />
                    </Index.Box>
                  </Index.Box>

                </Index.Box>
                <Index.Box className="modal-footer">
                  <Index.Box className="modal-footer-btn-flex">
                    <Index.PrimaryButton
                      btnLabel={pendingEditjobId && pendingEditjobId?.rate > 0 ? "Update" : "Add"}
                      className="btn-primary bg-approve"
                      type="submit"
                      disabled={isLoading}
                    >
                      {isLoading ? <Loader /> : "Click Me"}
                    </Index.PrimaryButton>
                    <Index.PrimaryButton
                      btnLabel="Cancel"
                      className="btn-primary bg-suspend"
                      onClick={handleCloseEditPandingRequest}
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </form>
          )}
        </PagesIndex.Formik>
      </Index.Modal>
    </>
  );
};

export default PendingRateJobModal;
