import {
  Box,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  Table,
  Button,
  Typography,
  TextField,
  Modal,
  ListItem,
  Pagination,
  Grid,
  Select,
  Container,
  MenuItem,
  FormControl,
  FormHelperText,
  List,
  InputAdornment,
  IconButton,
  OutlinedInput,
  InputLabel,
  Tabs,
  Tab,
  SwipeableDrawer,
  FormControlLabel,
  Checkbox,
  Rating,
  TableBody,
  Paper,
  TextareaAutosize,
  RadioGroup,
  Radio,
  Switch,
  FormLabel,
  styled,
  alpha,
  Menu,
  Collapse,
  Stack,
  Autocomplete,
  ListItemText,
  Chip,
  InputBase,
  Tooltip,
  Accordion,
  AccordionActions,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Check } from "@mui/icons-material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import PrimaryButton from "../common/button/PrimaryButton";
import SecondaryButton from "../common/button/SecondaryButton";
import FormGroup from "@mui/material/FormGroup";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Link } from "react-router-dom";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import CircularProgress from "@mui/material/CircularProgress";
import AddIcon from '@mui/icons-material/Add';
import Divider from '@mui/material/Divider';
import TablePagination from "@mui/material/TablePagination";
import DeleteModal from "../common/DeleteModel";
import DeleteAgencyModal from "../common/DeleteAgencyModel";
import MenuIcon from "@mui/icons-material/MoreVert";
import ArrowBack from '@mui/icons-material/ArrowBack';
import SendIcon from '@mui/icons-material/Send';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';


const Index = {
  Accordion,
  AccordionActions,
  AccordionSummary,
  AccordionDetails,
  TablePagination,
  Check,
  ListItemText,
  Autocomplete,
  Box,
  MenuIcon,
  ArrowBack,
  TableContainer,
  TableRow,
  TableCell,
  FormHelperText,
  Button,
  Table,
  Chip,
  DemoContainer,
  TableHead,
  Typography,
  TextField,
  ListItem,
  Modal,
  Pagination,
  Stack,
  Grid,
  Container,
  List,
  Select,
  InputBase,
  MenuItem,
  FormControl,
  InputAdornment,
  IconButton,
  OutlinedInput,
  InputLabel,
  Link,
  Tabs,
  Tab,
  Checkbox,
  TableBody,
  Paper,
  TextareaAutosize,
  RadioGroup,
  Radio,
  Switch,
  styled,
  alpha,
  Menu,
  Collapse,
  CircularProgress,
  Divider,
  DeleteModal,
  DeleteAgencyModal,
  SingleInputDateRangeField,
  DateRangePicker,
  DateTimePicker,
  renderTimeViewClock,


  // Icons
  Visibility,
  VisibilityOff,
  PrimaryButton,
  SwipeableDrawer,
  FormControlLabel,
  FormLabel,
  FormGroup,
  ExpandLess,
  ExpandMore,
  Rating,
  AdapterDayjs,
  LocalizationProvider,
  MobileDatePicker,
  AddIcon,
  SendIcon,
  ArrowBackIosNewIcon,
  SecondaryButton,
  Tooltip,
  ExpandMoreIcon,
};

export default Index;
