import React, { useState, useEffect } from "react";
import Index from "../../..";
import PagesIndex from "../../../../components/PagesIndex";
import DataService from "../../../../config/DataService";
import { Api } from "../../../../config/Api";
import { AgencyCancelJobBeforeHourSchema, AgencyEditJobBeforeHourSchema,AgencyJobBeforeCancelHourSchema,AgencyJobBeforeHourSchema } from "../../../../validation/FormikSchema";
import { useLocation } from "react-router-dom";
import Loader from "../../../../common/loader/Loader";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import moment from "moment";
import dayjs from "dayjs";
// AdminSlice: AdminSliceReducer,

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};
const officerType = [
  { title: "Active Police Officer" },
  { title: "Retired Police Officer" },
  { title: "Retired Military Officer" },
  { title: " Security Officer" },
];
const AddJobCancelBeforeHour = (props) => {
  const { state } = useLocation();
  let editData = state?.item;
  const dispatch = PagesIndex.useDispatch();
  const { open, handleClose, getJobHoursLimitList, editUserData } = props;
  const [isLoading, setIsLoading] = useState(false);

  // validation regex
  const NUMERIC_REGEX_ONLY = /^[a-zA-Z ]+$/;
  const ONLY_NUMBER_NOT_SPACE_SPACIALCHAR = /\D/g;
  const initialValues = {
    jobCancelBeforeHours: editUserData ? editUserData?.jobCancelBeforeHours : "",
  };
  console.log(editUserData, "editUserData");

  // const handleTimeChange = (e, setFieldValue, fieldName) => {
  //   let value = e.target.value;
  //     // Limit to HH:MM format
  //     if (value.length > 2) {
  //       value = value.slice(0, 2);
  //     }
  //     if(fieldName == "dayWorkLimit")
  //       {
  //         setFieldValue('dayWorkLimit', value);
  //       }
  //       if(fieldName == "weekWorkLimit"){
  //         setFieldValue('weekWorkLimit', value);
  //       }

  // };
  const handleTimeChange = (e, setFieldValue, fieldName) => {
    let value = e.target.value;

    // Remove non-digit characters
    value = value.replace(/[^\d]/g, '');

    // Limit to 2 characters
    if (value.length > 2) {
        value = value.slice(0, 2);
    }

    // Set the value using the provided field name
    setFieldValue(fieldName, value);
};

  const handleAddJobBeforeHours = async (values) => {
    setIsLoading(true);
    const urlEncoded = new URLSearchParams();
    if (editUserData === undefined || editUserData) {
      urlEncoded.append("_id", editUserData?._id);
    }
    urlEncoded.append("jobCancelBeforeHours", values?.jobCancelBeforeHours);
    try {
      const response = await DataService.post(
        Api.Agency.JOB_CANCEL_BEFORE_HOURS_POLICY,
        urlEncoded
      );
      if (response?.data?.status === 200) {
        PagesIndex.toast.success(response?.data?.message, {
          toastId: "customId",
        });
        handleClose();
        getJobHoursLimitList();
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      }
    } catch (error) {
      PagesIndex.toast.error(error?.response?.data?.message, {
        toastId: "customId",
      });
      setIsLoading(false);
    }
  };

  return (
    <>
      <Index.Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal"
      >
        <PagesIndex.Formik
          validationSchema={
            editUserData.length === 0 ? AgencyCancelJobBeforeHourSchema : AgencyJobBeforeCancelHourSchema
          }
          initialValues={initialValues}
          onSubmit={handleAddJobBeforeHours}
        >
          {({
            values,
            errors,
            handleChange,
            handleBlur,
            touched,
            handleSubmit,
            setFieldTouched,
            setFieldValue,
            handleFocus,
          }) => (
            <form onSubmit={handleSubmit}>
              {console.log("error", errors)}
              {/* {console.log("values>>", values)} */}
              <Index.Box
                sx={style}
                className="add-user-modal-inner-main modal-inner"
              >
                <Index.Box className="modal-header">
                  <Index.Typography
                    id="modal-modal-title"
                    className="modal-title"
                    variant="h6"
                    component="h2"
                  >
                    {editUserData ? "Job Cancel Policy" : "Add Job Cancel Policy"}
                  </Index.Typography>
                  <img
                    src={PagesIndex.Svg.closeblack}
                    className="modal-close-icon"
                    onClick={handleClose}
                    alt=""
                  />
                </Index.Box>

                <Index.Box className="modal-body">
                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">
                    Job Cancel Policy
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        className="form-control"
                        placeholder="HH"
                        name="jobCancelBeforeHours"
                        value={values.jobCancelBeforeHours}
                        onChange={(e) => {
                          handleTimeChange(e, setFieldValue, "jobCancelBeforeHours");
                        }}
                        // onBlur={handleBlur}
                        focused={false}
                        error={errors.jobCancelBeforeHours && touched.jobCancelBeforeHours ? true : false}
                        helperText={
                          errors.jobCancelBeforeHours && touched.jobCancelBeforeHours ? errors.jobCancelBeforeHours : null
                        }
                      />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>

                <Index.Box className="modal-footer">
                  <Index.Box className="modal-footer-btn-flex">
                    <Index.PrimaryButton
                      btnLabel={editUserData ? "Update" : "Add"}
                      className="btn-primary bg-approve"
                      type="submit"
                      disabled={isLoading}
                    >
                      {isLoading ? <Loader /> : "submit"}
                    </Index.PrimaryButton>

                    <Index.PrimaryButton
                      btnLabel="cancel"
                      className="btn-primary bg-suspend"
                      onClick={() => handleClose()}
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </form>
          )}
        </PagesIndex.Formik>
      </Index.Modal>
    </>
  );
};

export default AddJobCancelBeforeHour;
