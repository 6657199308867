import React, { useEffect, useState } from "react";
import PagesIndex from "../../../PageIndex";
import Index from "../../../index";
import { Formik } from "formik";
// import "./Role.css";
import { addRoleMaster } from "../../../../redux-toolkit/slice/admin-slice/AdminServices";
import {
  webRoleMaster,
  zipManagement,
  jobTypeManagement,
  agencyManagement,
  userManagement,
  officerManagement,
  jobManagement,
} from "../../../../config/CommonRoleMaster";
import { Checkbox, FormControlLabel } from "@mui/material";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { addAgencyRoleMaster } from "../../../../redux-toolkit/slice/agency-slice/AgencyServices";

function AddAgency() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const NUMERIC_REGEX_ONLY = /^[a-zA-Z ]+$/;
     //Getting data from redux
     const { agencyLoginData } = PagesIndex.useSelector(
      (state) => state.AgencySlice
    );
    const AgencyPermission = agencyLoginData?.roleId?.AgencyPermission;
    const roleName = agencyLoginData?.roleId?.Permission_name;

  let initialValues = {
    roleName: "",
  };

  const handleFormSubmit = async (values) => {
    setLoading(true);
    const urlencoded = new URLSearchParams();
    urlencoded.append("Permission_name", values.roleName.trim())

    dispatch(addAgencyRoleMaster({ urlencoded, navigate, setLoading }));
  };

  const BackBtn = () => {
    navigate("/agency/role");
  };

  return (
    <>
      {/* New Design Code Changes */}
      <Index.Box className="user-list-flex">
        <Index.Typography className='admin-page-title user-list-page-title' component='h2' variant='h2'>
          {/* <Index.Link to="/agency/role" className="filter-back-link"> */}
            <img
              src={PagesIndex.Svg.leftArrow}
              className="left-arrow-ic"
              alt="search grey img"
              onClick={()=>{
                navigate(`/${roleName}/role`)
              }}
            ></img>
          {/* </Index.Link>{" "} */}
          Add Role 
        </Index.Typography>
      </Index.Box>
      {/* End New Design Code Changes */}

      <Index.Box className="">
        <Index.Box className="barge-common-box">

          <Formik
            // enableReinitialize
            initialValues={initialValues}
            validationSchema={PagesIndex.roleMasterValidationSchema}
            onSubmit={handleFormSubmit}
          >
            {({
              values,
              errors,
              touched,
              setFieldValue,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldTouched,

            }) => (
              <form onSubmit={handleSubmit}>
                <Index.Box className="card-main">
                  <Index.Grid container spacing={2} alignItems={"center"}>
                    <Index.Grid item xs={12} sm={12} md={12}>
                      <Index.Box className="input-box modal-input-box">
                        <Index.FormHelperText className="form-lable">
                          Role Name 
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.TextField
                            hiddenLabel
                            id="filled-hidden-label-normal"
                            placeholder="Role Name"
                            variant="filled"
                            className="form-control w-100 remove-brder"
                            onChange={(e) => {
                              handleChange(e);
                              setFieldTouched("roleName", true);
                            }}
                            onBlur={handleBlur}
                            name="roleName"
                            value={values?.roleName}
                            error={Boolean(
                              errors.roleName ? touched.roleName : undefined
                            )}
                            helperText={
                              touched.roleName ? errors.roleName : undefined
                            }
                            inputProps={{ maxlength: 30 }}
                            onKeyDown={(e) => {
                              // if (e.key === " " && e.target.value.trim() === "") {
                              //   e.preventDefault(); // Prevent space as the first character
                              // } else if (e.target.value.trim().split(" ").length >= 2 && e.key === " ") {
                              //   e.preventDefault(); // Prevent adding additional spaces between words
                              // } else if (e.key === " " && e.target.value.endsWith(" ")) {
                              //   e.preventDefault(); // Prevent additional spaces at the end
                              // }
                              if (!NUMERIC_REGEX_ONLY.test(e.key)) {
                                e.preventDefault(); // Prevent non-numeric characters
                              }
                            }}
                            // onPaste={(e) => e.preventDefault()} 
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>
                    <Index.Grid item xs={12} sm={12} md={12}>
                      <Index.Box className="user-btn-flex btn-main-primary">
                        <Index.Button
                          variant="contained"
                          className="btn-primary"
                          type="submit"
                          disabled={loading}
                        >
                          Submit
                        </Index.Button>
                      </Index.Box>
                    </Index.Grid>
                  </Index.Grid>
                </Index.Box>
              </form>
            )}
          </Formik>
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default AddAgency;
