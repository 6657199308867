import React, { useEffect, useState } from "react";
import Index from "../../..";
import PagesIndex from "../../../PageIndex";
import dayjs from "dayjs";
import Loader from "../../../../common/loader/Loader";
import DataService from "../../../../config/DataService";
import { agencySelectSuperNsuperSchema } from "../../../../validation/FormikSchema";
import FormField from "./FormField";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};
const SupervisorNonSupervisor = (props) => {
  const {
    supervisorRequestOpen,
    handleCloseSupervisorRequest,
    supervisorjobId,
    getPendingProfiles,
    officerData,
    actionType
  } = props;
  let supervisorType = [
    { title: "Supervisor" },
    { title: "Non Supervisor" }
  ]
  console.table(officerData, "officerData29")
  const [initialValues, setInitialValues] = useState({
    supervisorType: "",
    name: "",
    lname: "",
    email: "",
    mobileNumber: ""
  });
  const [isLoading, setIsLoading] = useState(false);

  // console.log("initialValues34",initialValues)

  useEffect(() => {
    if (officerData) {
      setInitialValues({
        supervisorType: officerData.jobName || "",
        name: officerData.firstName || "",
        lname: officerData.lastName || "",
        email: officerData.email || "",
        mobileNumber: officerData.mobileNumber 
      });
    }
  }, [officerData]);

  const handleEditRequest = async (values) => {
    setIsLoading(true);
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("officerId", supervisorjobId);
    urlEncoded.append("jobName", values.supervisorType);
    urlEncoded.append("name", values.name);
    urlEncoded.append("lname", values.lname);
    urlEncoded.append("email", values.email);
    urlEncoded.append("mobileNumber", values.mobileNumber);

    try {
      const response = await DataService.post(
        PagesIndex.Api.Agency.AGENCY_ADD_SUPERVISOR_NONSUPERVISOR,
        urlEncoded
      );
      if (response?.data?.status == 200) {
        PagesIndex.toast.success(response?.data?.message);
        handleCloseSupervisorRequest();
        setTimeout(() => {
          getPendingProfiles();
        }, 1000);
        setIsLoading(false);
      }


    } catch (error) {
      PagesIndex.toast.error(error?.response?.data?.message);
      setIsLoading(false);
    }
  };


  return (
    <>
      <Index.Modal
        open={supervisorRequestOpen}
        onClose={handleCloseSupervisorRequest}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal"
      >
        <PagesIndex.Formik
          initialValues={initialValues}
          onSubmit={handleEditRequest}
          validationSchema={agencySelectSuperNsuperSchema}
        >
          {({
            values,
            errors,
            handleChange,
            handleBlur,
            touched,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
          }) => (
            <form onSubmit={handleSubmit}>
              {/* {console.log(errors, "error")} */}
              {/* {console.log(values.zipCode, "zip")} */}
              <Index.Box
                sx={style}
                className="add-user-modal-inner-main modal-inner"
              >
                <Index.Box className="modal-header">
                  <Index.Typography
                    id="modal-modal-title"
                    className="modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Edit Details
                  </Index.Typography>
                  <img
                    src={PagesIndex.Svg.closeblack}
                    className="modal-close-icon"
                    onClick={handleCloseSupervisorRequest}
                    alt=""
                  />
                </Index.Box>
                <Index.Box className="modal-body">
                  {actionType == "supervisor" ?
                    <FormField
                      name="supervisorType"
                      type="dropdown"
                      label="Supervisor/NonSupervisor"
                      value={values.supervisorType}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.supervisorType}
                      touched={touched.supervisorType}
                      options={supervisorType}
                    />
                    : actionType == "name" ?
                      <FormField
                        name="name"
                        type="text"
                        label="First Name"
                        placeholder="Please enter first name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.name}
                        touched={touched.name}
                      />
                      : actionType == "lname" ?
                        <FormField
                          name="lname"
                          type="text"
                          label="Last Name"
                          placeholder="Please enter last name"
                          value={values.lname}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.lname}
                          touched={touched.lname}
                        />
                        : actionType == "email" ?
                          <FormField
                            name="email"
                            type="text"
                            label="Email Address"
                            placeholder="Please enter email address"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors.email}
                            touched={touched.email}
                          />
                          : actionType == "mobileNumber" ?
                            <FormField
                              name="mobileNumber"
                              type="Number"
                              label="Phone Number"
                              placeholder="Please enter phone number"
                              value={values.mobileNumber}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={errors.mobileNumber}
                              touched={touched.mobileNumber}
                            />
                            : null

                  }

                  {/* <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Select Supervisor/NonSupervisor
                    </Index.FormHelperText>
                    <Index.Box className="dropdown-box">
                      <Index.FormControl className="form-control drop-form-control">
                        <Index.Select
                          className="dropdown-select drop-select"
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          name="supervisorType"
                          value={values?.supervisorType}
                          onChange={handleChange}
                          renderValue={
                            values?.supervisorType
                              ? undefined
                              : () => "Select Supervisor/NonSupervisor"
                          }
                          error={
                            errors.supervisorType && touched.supervisorType
                              ? true
                              : false
                          }
                        >
                          {supervisorType && supervisorType.length > 0
                            ? supervisorType.map((data, index) => {
                              return (
                                <Index.MenuItem
                                  key={index}
                                  value={data?.title}
                                  className="drop-menuitem"
                                // onClick={()=>getDepartmentByAgencyList(data?.title)}
                                >
                                  {data?.title}
                                </Index.MenuItem>
                              );
                            })
                            : null}
                        </Index.Select>
                      </Index.FormControl>

                    </Index.Box>
                    <Index.FormHelperText error>
                      {errors.supervisorType && touched.supervisorType
                        ? errors.supervisorType
                        : null}
                    </Index.FormHelperText>
                  </Index.Box> */}

                </Index.Box>
                <Index.Box className="modal-footer">
                  <Index.Box className="modal-footer-btn-flex">
                    <Index.PrimaryButton
                      btnLabel="Update"
                      className="btn-primary bg-approve"
                      type="submit"
                      disabled={isLoading}
                    >
                      {isLoading ? <Loader /> : "Click Me"}
                    </Index.PrimaryButton>
                    <Index.PrimaryButton
                      btnLabel="Cancel"
                      className="btn-primary bg-suspend"
                      onClick={handleCloseSupervisorRequest}
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </form>
          )}
        </PagesIndex.Formik>
      </Index.Modal>
    </>
  );
};

export default SupervisorNonSupervisor;
