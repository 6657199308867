import React from "react";
import Index from "../../../..";
import PagesIndex from "../../../../PageIndex";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

const CancelledJobModal = (props) => {
  const { cancelledJobsopen, handleCloseCancelledJobs, cancelledjobsData } = props;
  
  return (
    <>
      <Index.Modal
        open={cancelledJobsopen}
        onClose={handleCloseCancelledJobs}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal"
      >
        <Index.Box sx={style} className="add-user-modal-inner-main modal-inner">
          <Index.Box className="modal-header">
            <Index.Typography
              id="modal-modal-title"
              className="modal-title"
              variant="h6"
              component="h2"
            >
              Cancelled jobs
            </Index.Typography>
            <img
              src={PagesIndex.Svg.closeblack}
              className="modal-close-icon"
              onClick={handleCloseCancelledJobs}
              alt=""
            />
          </Index.Box>
          <Index.Box className="modal-body">
            <Index.Box className="card-left job-text-width-content">
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Job Title : &nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {cancelledjobsData?.title}
                </Index.Typography>
              </Index.Box>

              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Job Id : &nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {cancelledjobsData?.aliasJobId}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Posted By :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {cancelledjobsData?.userId?.name}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Posted On :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {PagesIndex.dayjs(cancelledjobsData?.jobStartDate).format(
                    "MM-DD-YYYY"
                  )}
                </Index.Typography>
              </Index.Box>


              {/* {
                cancelledjobsData?.totalAmount !== "" ? (
                  <Index.Box className="work-title-flex">
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-title-lable"
                    >
                      Total Payment :&nbsp;
                    </Index.Typography>
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-value-text"
                    >
                      {cancelledjobsData?.totalAmount}
                    </Index.Typography>
                  </Index.Box>
                ) : null
              } */}

              {cancelledjobsData?.paymentIntentId !== "" ? (

                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Transaction Id  :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {cancelledjobsData?.paymentIntentId}
                  </Index.Typography>
                </Index.Box>
              ) : null
              }


              {/* <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Total Payment :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  -
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Transaction Id  :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  -
                </Index.Typography>
              </Index.Box> */}
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Phone Number :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {cancelledjobsData?.mobileNumber}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Email Address :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {cancelledjobsData?.userId?.email}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Location :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {cancelledjobsData?.location}
                </Index.Typography>
              </Index.Box>
              {/* <Index.Box className="job-title-text">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-label"
                >
                  Required Skill :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-text-label"
                >
                  {cancelledjobsData?.skills?.length > 0
                    ? cancelledjobsData?.skills.map((row, index) => (
                        <React.Fragment key={index}>
                          <span>{row}</span>
                          {index < cancelledjobsData?.skills?.length - 1 && (
                            <span>, </span>
                          )}
                        </React.Fragment>
                      ))
                    : null}
                </Index.Typography>
              </Index.Box> */}
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Service :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {cancelledjobsData?.service}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Department :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {cancelledjobsData?.department}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Job Type :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {cancelledjobsData?.jobType?.jobType}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Zip Code :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {cancelledjobsData?.zipCode}
                </Index.Typography>
              </Index.Box>
              {cancelledjobsData?.supervisor ? (
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Supervisor :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {cancelledjobsData?.supervisor}
                  </Index.Typography>
                </Index.Box>
              ) : null}

              {cancelledjobsData?.nonSupervisor ? (
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Non Supervisor :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {cancelledjobsData?.nonSupervisor}
                  </Index.Typography>
                </Index.Box>
              ) : null}
              {/* <Index.Box className="job-title-text">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-label"
                >
                  Date :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-text-label"
                >
                  {PagesIndex.dayjs(cancelledjobsData?.jobStartDate).format(
                    "MM-DD-YYYY"
                  ) +
                    " to " +
                    PagesIndex.dayjs(cancelledjobsData?.jobEndDate).format(
                      "MM-DD-YYYY"
                    )}
                </Index.Typography>
              </Index.Box> */}
              {/* <Index.Box className="job-title-text">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-label"
                >
                  Timing :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-text-label"
                >
                  {cancelledjobsData?.jobStartTime +
                    " to " +
                    cancelledjobsData?.jobEndTime}
                </Index.Typography>
              </Index.Box> */}
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Date/Time :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {/* {cancelledjobsData?.jobSchedule?.map((item, index) => {
                        return (
                          <>
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="job-title-text-label"
                            >
                              <span className="day-title">
                                {
                                  PagesIndex.dayjs(item?.jobStartDate).format(
                                    "MM-DD-YYYY"
                                  ) +
                                    " to " +
                                    PagesIndex.dayjs(item?.jobEndDate).format(
                                      "MM-DD-YYYY"
                                    )
                                }
                              </span>{" "}
                              -{" "}
                              <span>
                                {item?.jobStartTime
                                  ? item?.jobStartTime + " to "
                                  : null}
                                {item?.jobEndTime}
                              </span>
                            </Index.Typography>
                          </>
                        );
                      })} */}
                  {PagesIndex.showDateTime(cancelledjobsData)}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Pay Rate (Per hour) :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {cancelledjobsData?.rate ? "$" : null}
                  {cancelledjobsData?.rate}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Total Hours :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {PagesIndex.timeDifferenceCalculation(cancelledjobsData)} hour
                  {/* {PagesIndex.timeDifferenceCalculation(
                      cancelledjobsData?.jobSchedule[0]?.jobStartTime,
                      cancelledjobsData?.jobSchedule[0]?.jobEndTime
                    )} */}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  No of Officer :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {cancelledjobsData?.totalOfficer}
                </Index.Typography>
              </Index.Box>
              {/* <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Information :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {cancelledjobsData?.information}
                  </Index.Typography>
                </Index.Box> */}
              <Index.Box
                // className="job-title-text"
                className="work-title-flex"
              >
                <Index.Typography
                  component="p"
                  variant="p"
                  // className="job-title-label"
                  className="work-title-lable"
                >
                  Description :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  // className="job-title-text-label"
                  className="work-value-text"
                >
                  {cancelledjobsData?.description}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Approved By : &nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {cancelledjobsData?.approveBy?.agencyName}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Approved on : &nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {PagesIndex.moment(
                    cancelledjobsData?.officerId[0]?.jobAcceptedDate
                  ).format("MM-DD-YYYY")}

                </Index.Typography>
              </Index.Box>
              {/* <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Accepted By : &nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {cancelledjobsData?.officerId[0]?.officerId?.firstName ? (
                    <>
                      {cancelledjobsData?.officerId[0]?.officerId?.firstName +
                        " " +
                        cancelledjobsData?.officerId[0]?.officerId?.lastName}
                    </>
                  ) : null}
                </Index.Typography>
              </Index.Box> */}

              {/* <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Accepted On : &nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {PagesIndex.moment(
                    cancelledjobsData?.officerId[0]?.jobAcceptedDate
                  ).format("MM-DD-YYYY")}
                </Index.Typography>
              </Index.Box> */}
            </Index.Box>
          </Index.Box>
          {/* <Index.Box className="modal-footer">
            <Index.Box className="document-btn-grp">
              <Index.PrimaryButton
                btnLabel="Approve"
                className="btn-primary bg-approve appr-rej-btn"
              />

              <Index.PrimaryButton
                btnLabel="Reject"
                className="btn-primary bg-suspend appr-rej-btn"
              />
            </Index.Box>
          </Index.Box> */}
        </Index.Box>
      </Index.Modal>
    </>
  );
};

export default CancelledJobModal;
