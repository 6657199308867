import React, { useState } from "react";
import PagesIndex from "../../../PageIndex";
import Index from "../../../index";
import AddPercentageModel from "./AddPercentageModel";
import EditPercentageModel from "./EditPercentageModel";

export default function PaymentPerCentageList() {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const dispatch = PagesIndex.useDispatch();
  const [listData, setListData] = useState([]);
  const [logList, setLogList] = useState([]);

  const [editPercentageModelOpen, setPercentageModelopen] = useState(false);
  const [editPercentageData, setEditPercentageData] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const handleEditPerCentageOpen = () => setPercentageModelopen(true);
  const handlePercentageModelClose = () => {
    setPercentageModelopen(false);
    setEditPercentageData();
  };


  // Pagination states and methods
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };
  // End Pagination
  // filter
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Index.Box
      className="filter-main"
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    ></Index.Box>
  );

  const getAllList = async () => {
    try {
      const res = await PagesIndex.DataService.get(
        PagesIndex.Api.Admin.GET_PAYMENT_PERCENTAGE
      );
      setListData(res?.data?.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getLogListData = async () => {
    try {
      const res = await PagesIndex.DataService.get(
        PagesIndex.Api.Admin.GET_PAYMENT_PERCENTAGE_LOGS
      );
      setLogList(res?.data?.data);
    } catch (error) {
      console.error(error);
    }
  };



  PagesIndex.useEffect(() => {
    getAllList();
    getLogListData();
  }, []);

  return (
    <>
      <Index.Box className="user-list-flex">
        <Index.Typography
          className="admin-page-title user-list-page-title"
          component="h2"
          variant="h2"
        >
          Payment Percentage Management
        </Index.Typography>
        <Index.Box className="userlist-btn-flex"></Index.Box>
        {!listData.length  ? (
          <Index.Box className="adduser-btn-main btn-main-primary">
            <Index.Button
              className="adduser-btn btn-primary"
              onClick={handleOpen}
            >
              <img
                src={PagesIndex.Svg.plus}
                className="down-icon"
                alt="download icon"
              />{" "}
              Add Payment Type Percentage
            </Index.Button>
          </Index.Box>
        ) : null}
      </Index.Box>
      <Index.Box className="common-card">
        <Index.Box className="page-table-main">
          <Index.TableContainer
            component={Index.Paper}
            className="table-container"
          >
            <Index.Table aria-label="simple table" className="table">
              <Index.TableHead className="table-head">
                <Index.TableRow className="table-row">
                  <Index.TableCell
                    align="left"
                    component="th"
                    variant="th"
                    className="table-th"
                    width="3%"
                  >
                    Sr No
                  </Index.TableCell>
                  <Index.TableCell
                    align="left"
                    component="th"
                    variant="th"
                    className="table-th"
                    width="15%"
                  >
                    Type
                  </Index.TableCell>
                  <Index.TableCell
                    align="center"
                    component="th"
                    variant="th"
                    className="table-th"
                    width="10%"
                  >
                    Admin %
                  </Index.TableCell>
                  <Index.TableCell
                    align="center"
                    component="th"
                    variant="th"
                    className="table-th"
                    width="10%"
                  >
                    PD %
                  </Index.TableCell>
                  <Index.TableCell
                    align="center"
                    component="th"
                    variant="th"
                    className="table-th"
                    width="10%"
                  >
                    Security Agency %
                  </Index.TableCell>
                  <Index.TableCell
                    align="center"
                    component="th"
                    variant="th"
                    className="table-th"
                    width="10%"
                  >
                    Officer %
                  </Index.TableCell>
                  <Index.TableCell
                    align="left"
                    component="th"
                    variant="th"
                    className="table-th"
                    width="15%"
                  >
                    Date & Time
                  </Index.TableCell>
                </Index.TableRow>
              </Index.TableHead>
              {loading ? (
                <Index.TableBody>
                  <Index.TableRow>
                    <Index.TableCell
                      colSpan={12}
                      align="center"
                      className="loading-purchase"
                    >
                      <Index.CircularProgress color="secondary" size={20} />
                    </Index.TableCell>
                  </Index.TableRow>
                </Index.TableBody>
              ) : (
                <Index.TableBody className="table-body">
                  {listData?.length ? (
                    listData
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((list, index) => (
                        <Index.TableRow
                          key={list?._id}
                          className="nftListing-table-row"
                        >
                          <Index.TableCell
                            component="td"
                            variant="td"
                            className="table-td"
                          >
                            {index + 1}
                          </Index.TableCell>
                          <Index.TableCell
                            component="td"
                            variant="td"
                            className="table-td"
                            align="left"
                          >
                            {list?.type}
                          </Index.TableCell>
                          <Index.TableCell
                            component="td"
                            variant="td"
                            className="table-td"
                            align="center"
                          >
                            <Index.Box className="table-data-flex">
                              <Index.Box className="admin-num">
                                {list?.admin}
                              </Index.Box>

                              <Index.Tooltip
                                title="Edit"
                                arrow
                                placement="bottom"
                                className="admin-tooltip"
                              >
                                <Index.Box className="table-btn-flex">
                                  <Index.Button
                                    className="btn-secondary table-btn"
                                    onClick={() => {
                                      handleEditPerCentageOpen();
                                      setEditPercentageData({
                                        id: list._id,
                                        type: "admin",
                                        value: list.admin,
                                      });
                                    }}
                                  >
                                    <img
                                      src={PagesIndex.Svg.editNew}
                                      alt="delete"
                                      className="admin-icons"
                                    />
                                  </Index.Button>
                                </Index.Box>
                              </Index.Tooltip>
                            </Index.Box>
                          </Index.TableCell>
                          <Index.TableCell
                            component="td"
                            variant="td"
                            className="table-td"
                            align="center"
                          >
                            <Index.Box className="table-data-flex">
                              <Index.Box className="admin-num"
                              // className={
                              //   list?.police_departmnet ? "admin-num" : ""
                              // }
                              >
                                {list?.type == "Through Police Department" ? Math.abs(100 - list?.admin) : '-'}
                                {/* {list?.police_departmnet} */}
                              </Index.Box>
                              {/* <Index.Tooltip
                                title="Edit"
                                arrow
                                placement="bottom"
                                className="admin-tooltip"
                              >
                                <Index.Box className="table-btn-flex">
                                  <Index.Button
                                    className="btn-secondary table-btn"
                                    onClick={() => {
                                      handleEditPerCentageOpen();
                                      setEditPercentageData({
                                        id: list._id,
                                        type: "police_departmnet",
                                        value: list.police_departmnet,
                                      });
                                      // setEditPercentageData();
                                    }}
                                  >
                                    <img
                                      src={PagesIndex.Svg.editNew}
                                      alt="delete"
                                      className="admin-icons"
                                    />
                                  </Index.Button>
                                </Index.Box>
                              </Index.Tooltip> */}
                            </Index.Box>
                          </Index.TableCell>

                          <Index.TableCell
                            component="td"
                            variant="td"
                            className="table-td"
                            align="center"
                          >
                            <Index.Box className="table-data-flex">
                              <Index.Box className="admin-num">
                                {list?.type.trim() == "Security Agency" ? Math.abs(100 - list?.admin) : '-'}
                                {/* {list?.security_Agency} */}
                              </Index.Box>
                              {/* 
                              <Index.Tooltip
                                title="Edit"
                                arrow
                                placement="bottom"
                                className="admin-tooltip"
                              >
                                <Index.Box className="table-btn-flex">
                                  <Index.Button
                                    className="btn-secondary table-btn"
                                    onClick={() => {
                                      handleEditPerCentageOpen();
                                      setEditPercentageData({
                                        id: list._id,
                                        type: "security_Agency",
                                        value: list.security_Agency,
                                      });
                                    }}
                                  >
                                    <img
                                      src={PagesIndex.Svg.editNew}
                                      alt="delete"
                                      className="admin-icons"
                                    />
                                  </Index.Button>
                                </Index.Box>
                              </Index.Tooltip> */}
                            </Index.Box>
                          </Index.TableCell>
                          <Index.TableCell
                            component="td"
                            variant="td"
                            className="table-td"
                            align="center"
                          >
                            <Index.Box className="table-data-flex">
                              <Index.Box className="admin-num">
                                {list?.type == "Direct Pay" ? Math.abs(100 - list?.admin) : '-'}
                              </Index.Box>
                              {/* 
                              <Index.Tooltip
                                title="Edit"
                                arrow
                                placement="bottom"
                                className="admin-tooltip"
                              >
                                <Index.Box className="table-btn-flex">
                                  <Index.Button
                                    className="btn-secondary table-btn"
                                    onClick={() => {
                                      handleEditPerCentageOpen();
                                      setEditPercentageData({
                                        id: list._id,
                                        type: "officer",
                                        value: list.officer,
                                      });
                                    }}
                                  >
                                    <img
                                      src={PagesIndex.Svg.editNew}
                                      alt="delete"
                                      className="admin-icons"
                                    />
                                  </Index.Button>
                                </Index.Box>
                              </Index.Tooltip> */}
                            </Index.Box>
                          </Index.TableCell>
                          <Index.TableCell
                            component="td"
                            variant="td"
                            className="table-td"
                            align="left"
                          >
                            {PagesIndex.moment(list?.updatedAt).format(
                              "MM-DD-YYYY  HH:mm:ss"
                            )}
                          </Index.TableCell>
                        </Index.TableRow>
                      ))
                  ) : (
                    <Index.TableRow>
                      <Index.TableCell
                        component="td"
                        variant="td"
                        scope="row"
                        className="no-data-in-list"
                        colSpan={15}
                        align="center"
                      >
                        No data available
                      </Index.TableCell>
                    </Index.TableRow>
                  )}
                </Index.TableBody>
              )}
            </Index.Table>
          </Index.TableContainer>

          <AddPercentageModel
            open={open}
            setOpen={setOpen}
            handleClose={handleClose}
          // getOfficerListData={getOfficerListData}
          />

          <EditPercentageModel
            handlePercentageModelClose={handlePercentageModelClose}
            getAllList={getAllList}
            getLogListData={getLogListData}
            editPercentageModelOpen={editPercentageModelOpen}
            editPercentageData={editPercentageData}
          />

          {/* <Index.Box className="pagination-design flex-end">
                        <Index.Stack spacing={2}>
                            <Index.Box className="pagination-count">
                                <Index.TablePagination
                                    className="paginationColor"
                                    component="div"
                                    page={page}
                                    count={listData?.length}
                                    onPageChange={handleChangePage}
                                    rowsPerPage={rowsPerPage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    labelDisplayedRows={({ from, to, count }) =>
                                        `Records ${from} to ${to === -1 ? count : to} of ${count}`
                                    }
                                />
                            </Index.Box>
                        </Index.Stack>
                    </Index.Box> */}
        </Index.Box>
      </Index.Box>

      {/* ---------------------Payment percentage logs---------------------- */}

      {/* <Index.Box className="user-list-flex">
        <Index.Typography
          className="admin-page-title user-list-page-title"
          component="h2"
          variant="h2"
        >
          Payment Percentage Logs
        </Index.Typography>
      </Index.Box> */}
      <Index.Box className="common-card">
        <Index.Box className="page-table-main">
          <Index.TableContainer
            component={Index.Paper}
            className="table-container"
          >
            <Index.Table aria-label="simple table" className="table">
              <Index.TableHead className="table-head">
                <Index.TableRow className="table-row">
                  <Index.TableCell
                    align="left"
                    component="th"
                    variant="th"
                    className="table-th"
                    width="3%"
                  >
                    Sr No
                  </Index.TableCell>
                  <Index.TableCell
                    align="left"
                    component="th"
                    variant="th"
                    className="table-th"
                    width="15%"
                  >
                    Type
                  </Index.TableCell>
                  <Index.TableCell
                    align="center"
                    component="th"
                    variant="th"
                    className="table-th"
                    width="10%"
                  >
                    Percentage (%)
                  </Index.TableCell>
                  <Index.TableCell
                    align="center"
                    component="th"
                    variant="th"
                    className="table-th"
                    width="10%"
                  >
                    Updated By
                  </Index.TableCell>
                  {/* <Index.TableCell
                    align="center"
                    component="th"
                    variant="th"
                    className="table-th"
                    width="10%"
                  >
                    Update By
                  </Index.TableCell>
                  <Index.TableCell
                    align="center"
                    component="th"
                    variant="th"
                    className="table-th"
                    width="10%"
                  >
                   Updated At 
                  </Index.TableCell> */}
                  <Index.TableCell
                    align="left"
                    component="th"
                    variant="th"
                    className="table-th"
                    width="15%"
                  >
                   Updated At 
                  </Index.TableCell>
                </Index.TableRow>
              </Index.TableHead>
              {loading ? (
                <Index.TableBody>
                  <Index.TableRow>
                    <Index.TableCell
                      colSpan={12}
                      align="center"
                      className="loading-purchase"
                    >
                      <Index.CircularProgress color="secondary" size={20} />
                    </Index.TableCell>
                  </Index.TableRow>
                </Index.TableBody>
              ) : (
                <Index.TableBody className="table-body">
                  {logList?.length ? (
                    logList
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((list, index) => (
                        <Index.TableRow
                          key={list?._id}
                          className="nftListing-table-row"
                        >
                          <Index.TableCell
                            component="td"
                            variant="td"
                            className="table-td"
                          >
                            {index + 1}
                          </Index.TableCell>
                          <Index.TableCell
                            component="td"
                            variant="td"
                            className="table-td"
                            align="left"
                          >
                            {list?.type}
                          </Index.TableCell>
                          <Index.TableCell
                            component="td"
                            variant="td"
                            className="table-td"
                            align="center"
                          >
                            <Index.Box className="table-data-flex">
                              <Index.Box className="admin-num">
                                {list?.admin}
                              </Index.Box>
                            </Index.Box>
                          </Index.TableCell>
                          <Index.TableCell
                            component="td"
                            variant="td"
                            className="table-td"
                            align="center"
                          >
                            <Index.Box className="table-data-flex">
                              <Index.Box className="admin-num">
                                {list?.updatedBy?.name}
                              </Index.Box>
                            </Index.Box>
                          </Index.TableCell>

                          <Index.TableCell
                            component="td"
                            variant="td"
                            className="table-td"
                            align="left"
                          >
                            {PagesIndex.moment(list?.updatedAt).format(
                              "MM-DD-YYYY  HH:mm:ss"
                            )}
                          </Index.TableCell>
                        </Index.TableRow>
                      ))
                  ) : (
                    <Index.TableRow>
                      <Index.TableCell
                        component="td"
                        variant="td"
                        scope="row"
                        className="no-data-in-list"
                        colSpan={15}
                        align="center"
                      >
                        No data available
                      </Index.TableCell>
                    </Index.TableRow>
                  )}
                </Index.TableBody>
              )}
            </Index.Table>
          </Index.TableContainer>
        </Index.Box>
      </Index.Box>
    </>
  );
}
