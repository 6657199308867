import { styled, alpha } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import Index from "../../index";
import { agencyLogout } from "../../../redux-toolkit/slice/agency-slice/AgencySlice";
import PagesIndex from "../../../container/PageIndex";
import { Api } from "../../../config/Api";
import DataService from "../../../config/DataService";
import { Badge } from "@mui/material";
import PaymentType from "./PaymentType";


export default function Header(props) {
  // for open in header profile menu
  const dispatch = PagesIndex.useDispatch();
  const navigate = PagesIndex.useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { agencyLoginData } = PagesIndex.useSelector(
    (state) => state.AgencySlice
  );

  const unreadNotificationCount = localStorage.getItem(`${agencyLoginData?._id}/count`);



  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

 

  const [paymentTypeModelopen, setPaymnetTypeModelOpen] = useState(false);
  const handlePaymnetTypeOpen = () => setPaymnetTypeModelOpen(true);
  const handlePaymnetTypeModelClose = () => setPaymnetTypeModelOpen(false);
  const [paymentType, setPaymentType] = useState(null);

  const handleAgencylogout = () => {
    dispatch(agencyLogout());
    PagesIndex.toast.success("Logged out successfully");
    
    localStorage.removeItem(agencyLoginData?._id);
    localStorage.removeItem(`${agencyLoginData?._id}/count`);
    localStorage.removeItem(`${agencyLoginData?._id}/agencypayment`);
    navigate("/");
  };

  // console.log("header",agencyLoginData)

  const AgencyPermission = agencyLoginData?.roleId?.AgencyPermission;
  const roleName = agencyLoginData?.roleId?.Permission_name;


  const StyledMenu = styled((props) => (

    <Index.Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 3,
      marginTop: theme.spacing(1),
      minWidth: 258,
      paddingTop: 30,
      paddingBottom: 0,
      paddingLeft: 23,
      marginTop: 38,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow: "0px 3px 6px #00000029",
      "& .MuiMenu-list": {
        padding: "0px 0",
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        "&:active": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  }));


  const handleNotification = () => {
    navigate(`/${roleName}/notification`)
  }

  const getNotification = async () => {
    try {
      const res = await PagesIndex.DataService.get(
        PagesIndex.Api.Agency.GET_NOTIFICATION_HISTORY
      );
      if (res.status === 200) {
        let unreadCount = res?.data?.data.filter((data) => !data?.isRead)?.length
        localStorage.setItem(`${agencyLoginData?._id}/count`, unreadCount);
        if (res?.data?.paymentType !== undefined) {
          setPaymentType(res?.data?.paymentType);
          localStorage.setItem(`${agencyLoginData?._id}/agencypayment`, res?.data?.paymentType);
        }
      } else {
        // PagesIndex.toast.error(res?.message);
      }
    } catch (error) {
      // PagesIndex.toast.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    if (roleName === "Agency" || roleName === "Department") {
      setTimeout(() => {
        getNotification();
      }, 2000);
    }
  }, []);


  return (
    <>
      <Index.Box
        className={`admin-header-main ${props.open ? "active" : "admin-header-deactive"
          }`}
      >
        <Index.Box className="admin-header-left">
          <Index.Box className="admin-header-logo-main">
            <img
              src={PagesIndex.Png.logo}
              className="admin-sidebar-logo"
              alt="logo"
            />
            <Index.Button
              className="admin-bergur-button"
              onClick={() => {
                props.setOpen(!props.open);
                document.body.classList[props.open ? "add" : "remove"](
                  "body-overflow"
                );
              }}
            >
              <img
                src={PagesIndex.Svg.berguremenu}
                className="bergurmenu-icon"
                alt="dashboard logo"
              ></img>
            </Index.Button>
          </Index.Box>


          <Index.Box className="admin-header-right">

            {
              roleName === "Agency" || roleName === "Department" ? (
                <Index.Button className="btn icon-box" onClick={handleNotification}>
                  <Badge
                    badgeContent={unreadNotificationCount ? `${unreadNotificationCount}` : "0"}
                    color={unreadNotificationCount !== 0 ? "secondary" : "primary"}
                  >
                    <img
                      src={PagesIndex.Svg.notification}
                      className="admin-header-icon"
                      alt="dashboard logo"
                    />
                  </Badge>
                </Index.Button>

              ) : null
            }

            <Index.Box className="admin-header-drop-main">
              <Index.Box
                className="drop-header-btn"
                id="basic-button"
                disableRipple
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                <Index.Box className="flex-drop-main">
                  <img
                    // src={PagesIndex.Png.usericon}
                    src={
                      agencyLoginData?.image
                        ? `${PagesIndex.ADMIN_IMAGE_ENDPOINT}/${agencyLoginData?.image}`
                        : PagesIndex.Png.usericon
                    }
                    className="admin-header-profile-icon"
                    alt="dashboard bell icon"
                  ></img>
                  <Index.Box className="title-admin-drop">
                    {roleName === "Agency" || roleName === "Department" ? (
                      <Index.Typography
                        variant="h5"
                        component="h5"
                        className="admin-header-drop"
                      >
                        {agencyLoginData?.agencyName}
                      </Index.Typography>
                    ) : (
                      <Index.Typography
                        variant="h5"
                        component="h5"
                        className="admin-header-drop"
                      >
                        {agencyLoginData?.name}
                      </Index.Typography>
                    )}
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            {/* <Index.Menu 
              className="drop-header-menu"
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            > */}
            {/* <Index.Link to="/agency/agency-agent-profile"> */}
            {/* <Index.MenuItem
                // onClick={handleClose}
                onClick={() => {
                  handleClose();
                  navigate(`/${roleName}/profile`);
                }}
                className="drop-header-menuitem"
              >
                {" "}
                <img
                  src={PagesIndex.Svg.profileConfig}
                  className="drop-header"
                />{" "}
                Profile
              </Index.MenuItem> */}

            {/* <Index.MenuItem
                onClick={() => {
                  // handlePaymentConfig()
                  // handleClose();
                  // handleAgencylogout();
                }}
                className="drop-header-menuitem"
              >
                {" "}
                <img src={PagesIndex.Svg.paymentConfig} className="drop-header" />
                Payment Config
              </Index.MenuItem> */}
            {/* {
                roleName == "Department" && paymentType == null ? (
                  <Index.MenuItem
                    onClick={() => {
                      setPaymnetTypeModelOpen(true);
                    }}
                    className="drop-header-menuitem"
                  >
                    {" "}
                    <img src={PagesIndex.Svg.paymentType} className="drop-header" />
                    Payment Type
                  </Index.MenuItem>
                ) : null
              } */}
            {/* Payment Type Module */}
            {/* <PaymentType
                open={paymentTypeModelopen}
                setOpen={setPaymnetTypeModelOpen}
                handleClose={handlePaymnetTypeModelClose}
                getNotification={getNotification}
              /> */}

            {/* </Index.Link> */}
            {/* <Index.MenuItem
                onClick={() => {
                  handleClose();
                  handleAgencylogout();
                }}
                className="drop-header-menuitem"
              >
                {" "}
                <img src={PagesIndex.Svg.exit} className="drop-header" /> Sign
                Out
              </Index.MenuItem> */}
            {/* </Index.Menu> */}
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}


