import React from 'react';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, CircularProgress, colors } from '@mui/material';
import Loader from "../../../../common/loader/Loader";
import PagesIndex from "../../../PageIndex";
import Index from '../../..';
import { Link } from 'react-router-dom';

const DynamicTabPanel = ({ value, index, loading, data, className, tabNumber, handleRefundInitiate }) => {



  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className={className}
    >
      {value === index && (
        <Box>
          {loading ? (
            <Loader />
          ) : (
            <Box className={`tabpanel-main tabpanel-${tabNumber}`}>
              {data.length ? (
                console.log("payment", tabNumber, tabNumber !== 1, data),
                <Index.Box className="page-table-main payment-config-table-main">
                  <TableContainer component={Paper} className="table-container">
                    <Table aria-label="simple table" className="table">
                      <TableHead className="table-head">
                        <TableRow className="table-row">
                          <TableCell align="left" width="8%" component="th" variant="th" className="table-th">Payment Transaction Id</TableCell>
                          {tabNumber == 5 ? (<TableCell align="left" width="8%" component="th" variant="th" className="table-th">Refund Transaction Id</TableCell>) : null}
                          {tabNumber == 5 ? (<TableCell align="left" width="8%" component="th" variant="th" className="table-th">Action Type</TableCell>) : null}
                          <TableCell align="left" width="5%" component="th" variant="th" className="table-th">Job Id</TableCell>
                          <TableCell align="left" width="6%" component="th" variant="th" className="table-th">Job Title</TableCell>
                          {tabNumber !== 5 ? (<TableCell align="left" width="6%" component="th" variant="th" className="table-th">Transaction From</TableCell>) : null}
                          {tabNumber !== 5 ? (<TableCell align="left" width="6%" component="th" variant="th" className="table-th">Transaction To</TableCell>) : null}
                          {tabNumber !== 5 ? (<TableCell align="left" width="4%" component="th" variant="th" className="table-th">Amount ($) </TableCell>) : null}
                          {tabNumber !== 1 && tabNumber !== 5 ? (<TableCell align="left" width="4%" component="th" variant="th" className="table-th">Detected Percentage (%) </TableCell>) : null}
                          {tabNumber !== 1 ? (<TableCell align="left" width="10%" component="th" variant="th" className="table-th"> {tabNumber == 5 ? 'Amount ($)' : 'Payable Amount ($)'}  </TableCell>) : null}
                          <TableCell align="left" width="10%" component="th" variant="th" className="table-th">Email</TableCell>
                          <TableCell align="left" width="8%" component="th" variant="th" className="table-th">Status</TableCell>
                          <TableCell align="left" width="10%" component="th" variant="th" className="table-th">Date & Time</TableCell>
                          {tabNumber == 5 ? (<TableCell align="left" width="8%" component="th" variant="th" className="table-th"> Action </TableCell>) : null}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data.map((item, idx) => (
                          value == "enduserpayment" ? <EndUserPayment key={idx} item={item} /> : value == "paymenttodepartment" ?
                            <DepartmentHistory key={idx} item={item} /> : value == "paymenttoagency" ? <AgencyHistory key={idx} item={item} /> : value == "paymenttoofficers" ? <OfficerHistory key={idx} item={item} /> : value == "refund" ? <RefundHistory key={idx} item={item} handleRefundInitiate={handleRefundInitiate} /> : null
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Index.Box>
              ) : (
                <PagesIndex.NoDataFound />
              )}
            </Box>
          )}
        </Box>
      )}
    </div>
  );
};

const EndUserPayment = ({ item }) => (
  <>
    <TableRow>
      <TableCell className='table-td'>{item?.paymentIntentId}</TableCell>
      <TableCell className='table-td'>
        {/* <Link to={`/admin/adminjobmanagement/667e494d3a8a386cd911df10`}> */}
        {/* {item?.jobInfo[0]?._id} */}
        {item?.jobInfo[0]?.aliasJobId}
        {/* </Link> */}
      </TableCell>
      {/* <TableCell className='table-td'>{item?.jobInfo[0]?._id}</TableCell> */}
      <TableCell className='table-td'>{item?.jobInfo[0]?.title}</TableCell>
      <TableCell className='table-td'>{Array.isArray(item.clientInfo) ? item?.clientInfo[0]?.name : ""}</TableCell>
      <TableCell className='table-td'>{item?.adminInfo}</TableCell>
      <TableCell className='table-td'>{item?.amount}</TableCell>
      <TableCell className='table-td'>{Array.isArray(item?.clientInfo) ? item.clientInfo[0]?.email : ""}</TableCell>
      <TableCell className='table-td'>{item?.status}</TableCell>
      <TableCell className='table-td'>{PagesIndex.moment(item.createdAt).utc("+05:30").format("MM-DD-YYYY hh:mm A") || "-"}</TableCell>
    </TableRow>
  </>
);


const DepartmentHistory = ({ item }) => (
  <TableRow>
    <TableCell className='table-td'>{item?.transferId}</TableCell>
    <TableCell className='table-td'>{Array.isArray(item?.jobInfo) ? item.jobInfo[0]?.aliasJobId : ""}</TableCell>
    <TableCell className='table-td'>{Array.isArray(item?.jobInfo) ? item?.jobInfo[0]?.title : ""}</TableCell>
    <TableCell className='table-td'>{item?.adminInfo}</TableCell>
    <TableCell className='table-td'>{Array.isArray(item?.agencyInfo) ? item.agencyInfo[0]?.agencyName : ""}</TableCell>
    <TableCell className='table-td'>{item?.amount || '0.00'}</TableCell>
    <TableCell className='table-td'>{item?.detected_percentage || '-'}</TableCell>
    <TableCell className='table-td'>{item?.payable_amount || ' 0.00'}</TableCell>
    <TableCell className='table-td'>{Array.isArray(item?.agencyInfo) ? item?.agencyInfo[0]?.email : ""}</TableCell>
    <TableCell className='table-td'>{item?.status}</TableCell>
    <TableCell className='table-td'>{PagesIndex.moment(item.createdAt).utc("+05:30").format("MM-DD-YYYY hh:mm A") || "-"}</TableCell>
  </TableRow>
);

const AgencyHistory = ({ item }) => (
  <TableRow>
    <TableCell className='table-td'>{item?.transferId}</TableCell>
    <TableCell className="table-td">{Array.isArray(item?.jobInfo) ? item.jobInfo[0]?.aliasJobId : ""}</TableCell>
    <TableCell className="table-td">{Array.isArray(item?.jobInfo) ? item?.jobInfo[0]?.title : ""}</TableCell>
    <TableCell className='table-td'>{item?.adminInfo}</TableCell>
    <TableCell className="table-td">{Array.isArray(item?.agencyInfo) ? item.agencyInfo[0]?.agencyName : ""}</TableCell>
    <TableCell className='table-td'>{item?.amount || '0.00'}</TableCell>
    <TableCell className='table-td'>{item?.detected_percentage || '-'}</TableCell>
    <TableCell className='table-td'>{item?.payable_amount || '0.00'}</TableCell>
    <TableCell className="table-td">{Array.isArray(item?.agencyInfo) ? item?.agencyInfo[0]?.email : ""}</TableCell>
    <TableCell className='table-td'>{item?.status}</TableCell>
    <TableCell className='table-td'>{PagesIndex.moment(item.createdAt).utc("+05:30").format("MM-DD-YYYY hh:mm A") || "-"}</TableCell>
  </TableRow>
);

const OfficerHistory = ({ item }) => (
  <TableRow>
    <TableCell className='table-td'>{item?.transferId}</TableCell>
    <TableCell className="table-td">{Array.isArray(item?.jobInfo) ? item.jobInfo[0]?.aliasJobId : ""}</TableCell>
    <TableCell className="table-td">{Array.isArray(item?.jobInfo) ? item?.jobInfo[0]?.title : ""}</TableCell>
    <TableCell className='table-td'>{item?.adminInfo}</TableCell>
    <TableCell className="table-td">{Array.isArray(item?.officerInfo) ? item.officerInfo[0]?.firstName + " " + item.officerInfo[0]?.lastName : ""}</TableCell>
    <TableCell className='table-td'>{item?.amount || '0.00'}</TableCell>
    <TableCell className='table-td'>{item?.detected_percentage || '-'}</TableCell>
    <TableCell className='table-td'>{item?.payable_amount || '0.00'}</TableCell>
    <TableCell className="table-td">{Array.isArray(item?.officerInfo) ? item?.officerInfo[0]?.email : ""}</TableCell>
    <TableCell className='table-td'>{item?.status}</TableCell>
    <TableCell className='table-td'>{PagesIndex.moment(item.createdAt).utc("+05:30").format("MM-DD-YYYY hh:mm A") || "-"}</TableCell>
  </TableRow>
);

const RefundHistory = ({ item, handleRefundInitiate }) => (
  <TableRow>
    <TableCell className='table-td'>{Array.isArray(item?.paymentIntentId) ? item?.paymentIntentId.join(" ") : item?.paymentIntentId ?? "-"}</TableCell>
    <TableCell className="table-td">
      {Array.isArray(item?.refundId) && item.refundId.length > 0
        ? item.refundId.map((transaction) => transaction.refundId).join(" ")
        : "-"}
    </TableCell>
    <TableCell className='table-td'>{item?.isEditedBy !== undefined ? "Job Edited" : item?.isCancelledBy !== undefined ? "Job Cancelled" : "-"}</TableCell>
    <TableCell className="table-td">{Array.isArray(item?.jobInfo) ? item.jobInfo[0]?.aliasJobId : "-"}</TableCell>
    <TableCell className="table-td">{Array.isArray(item?.jobInfo) ? item?.jobInfo[0]?.title : "-"}</TableCell>
    <TableCell className='table-td'>{item?.amount || '0.00'}</TableCell>
    <TableCell className="table-td">{Array.isArray(item.clientInfo) ? item?.clientInfo[0]?.email : "-"}</TableCell>
    <TableCell className='table-td'>

      <Index.Typography
        component="p"
        variant="p"
        className={
          item?.status == "PENDING"  ? "status_pending"  : "status_tag"
        }
      >
        {item?.status == "PENDING" ? "Pending" : item?.status ?? "-"}

      </Index.Typography>

    </TableCell>
    <TableCell className='table-td'>{PagesIndex.moment(item.createdAt).utc("+05:30").format("MM-DD-YYYY hh:mm A") || "-"}</TableCell>
    <TableCell className='table-td'>
      {
        item?.status == "PENDING" ?
          (
            <Index.PrimaryButton
              btnLabel={item?.status == "PENDING" && "Refund"}
              onClick={() => handleRefundInitiate(item?._id)}
              disabled={item?.status !== "PENDING"}
              className="adduser-btn btn-primary"
            />
          ) :
          <Index.Typography
            component="p"
            variant="p"
            className="status_tag"
          >
            Refunded
          </Index.Typography>
      }
    </TableCell>

  </TableRow>
);

export default DynamicTabPanel;
