import React from "react";
import { Routes, Route, Outlet } from "react-router-dom";

// Admin routes import

// Agency routes import

import LayOut from "../container/admin/pages/layout/LayOut";
import Home from "../container/admin/pages/dashboard/Dashboard";
import Viewreport from "../container/admin/pages/dashboard/view-report/Viewreport";
import OfficerManagement from "../container/admin/pages/officermanagement/OfficerManagement";
import EditOfficerDetail from "../container/admin/pages/officermanagement/EditOfficerDetail";
// import JobManagement from "../container/admin/pages/jobmanagement/AdminJobManagement";
// import ExtraWorkPermitRequests from "../container/admin/pages/extraworkpermitrequests/WorkPolicy";
// import RatingAndReviewManagement from "../container/agency/ratingandreviewmanagement/RatingAndReviewManagement";
import UserManage from "../container/admin/pages/usermanage/UserManage";
import AgencyUserManage from "../container/agency/pages/usermanagement/UserManage";

// import UserManagement from "../container/admin/pages/usermanagement/UserManagement";
import RatingManagement from "../container/admin/pages/ratingmanagement/RatingManagement";
import AgencyLayOut from "../container/agency/pages/layout/LayOut";
import AgencyHome from "../container/agency/pages/dashboard/Dashboard";
import AgencyNotification from "../container/agency/pages/notification/notification"
// import Detailsofcompletedjobs from "../container/agency/pages/detailsofcompletedjobs/Detailsofcompletedjobs";
import AgencyDetailsofcompletedjobs from "../container/agency/pages/detailsofcompletedjobs/AgencyDetailsofcompletedjobs";
import DetailsOfPendingprofiles from "../container/agency/pages/detailsofpendingprofiles/DetailsOfPendingprofiles";
import DetailsOfActiveJobs from "../container/admin/pages/detailsofactivejobs/DetailsOfActiveJobs";
import UserHistory from "../container/admin/pages/usermanage/UserHistory";
import AgencyUserHistory from "../container/agency/pages/usermanagement/UserHistory";
import AgentManagement from "../container/admin/pages/agentmanagement/AgentManagement";
import AgencyOfficerManagement from "../container/agency/pages/officermanagement/OfficerManagement";
import AdminDetailsofcompletedjobs from "../container/admin/pages/jobmanagement/AdminDetailsofcompletedjobs";
import AgencyEditOfficerDetail from "../container/agency/pages/officermanagement/AgencyEditOfficerDetails";
import AdminJobManagement from "../container/admin/pages/jobmanagement/AdminJobManagement";
import AgencyViewreport from "../container/agency/pages/dashboard/viewreport/AgencyViewreport";
import AgencyJobManagement from "../container/agency/pages/agencyjobmanagement/AgencyJobManagement";
import AgencyJobManagementDetailActiveJobs from "../container/agency/pages/agencyjobmanagement/AgencyJobManagementDetailActiveJobs";
import AgencyJobHistory from "../container/agency/pages/jobhistory/AgencyJobHistory";
import AgencyPaymentManagement from "../container/agency/pages/paymentmanagement/AgencyPaymentManagement";
import AgencyPaymentConfigureSuccess from "../container/agency/pages/paymentmanagement/AgencyPaymentConfigureSuccess";
import AgencyAgentProfile from "../container/agency/pages/agentprofile/AgencyAgentProfile";
import AdminAgentDetails from "../container/admin/pages/agentmanagement/AdminAgentDetails";
import AdminAgentDetailsActive from "../container/admin/pages/agentmanagement/AdminAgentDetailsActive";
import AdminAgentDetailsCompleted from "../container/admin/pages/agentmanagement/AdminAgentDetailsCompleted";
import AdminExtraWorkPermit from "../container/admin/pages/adminextraworkpermit/AdminExtraWorkPermit";
import AdminJobManagementPendingJobRequest from "../container/admin/pages/adminjobmanagementpendingjobrequest/AdminJobManagementPendingJobRequest ";
import AdminJobManagementActiveJobs from "../container/admin/pages/adminjobmanagementpendingjobrequest/AdminJobManagementActiveJobs";
import AdminJobManagementCompletedJobs from "../container/admin/pages/adminjobmanagementpendingjobrequest/AdminJobManagementCompletedJobs";
import AdminRatingAndReviewManagement from "../container/admin/pages/adminratingandreviewmanagement/AdminRatingAndReviewManagement";
import AdminPaymentConfigManagement from "../container/admin/pages/adminpaymentmanagement/AdminPaymentConfigManagement"; //Added
import AdminPaymentManagement from "../container/admin/pages/adminpaymentmanagement/AdminPaymentManagement";
import AdminProfile from "../container/admin/pages/adminprofile/AdminAgentProfile";
import AdminHelpandSupportRequests from "../container/admin/pages/adminhelpandsupportrequests/AdminHelpandSupportRequests";
import AdminContentManagement from "../container/admin/pages/admincontentmanagement/AdminContentManagement";
import AdminUserHistoryDetailsOfActivejob from "../container/admin/pages/usermanage/AdminUserHistoryDetailsOfActivejob";
import AdminUserHistoryDetailsofcompletedjobs from "../container/admin/pages/usermanage/AdminUserHistoryDetailsofcompletedjobs";
import AdminCategoryManagementPlatformCategories from "../container/admin/pages/categoryManagementPlatformCategories/CategoryManagementPlatformCategories";
import OfficerDetails from "../container/agency/pages/officermanagement/officerdetails/OfficerDetails";
import AdminOfficerDetail from "../container/admin/pages/officermanagement/AdminOfficerDetail";
import AdminJobHistory from "../container/admin/pages/officermanagement/AdminJobHistory";
// import AdminJobManagementDetailsOfActivejob from "../container/admin/pages/jobmanagement/AdminJobManagementDetailsOfActivejob";
import AgencyLoginNew from "../container/agency/auth/AgencyLoginNew";
import AgencyForgotPasswordNew from "../container/agency/auth/AgencyForgotPasswordNew";
import AgencyOtpVerifiy from "../container/agency/auth/AgencyOtpVerifiy";
import AgencyForgotPasswordOtpVerifiy from "../container/agency/auth/AgencyForgotPasswordOtpVerifiy";
import AgencyResetPasswordNew from "../container/agency/auth/AgencyResetPasswordNew";
import AdminLogin from "../container/admin/auth/AdminLoginNew";
import ForgotPassword from "../container/admin/auth/ForgotPasswordNew";
import OtpVerification from "../container/admin/auth/OtpVerificationNew";
import ResetPassword from "../container/admin/auth/ResetPasswordNew";
import AgencySignUp from "../container/agency/auth/AgencySignUp";
import AgencyPrivateLayout from "./private/AgencyPrivateLayout";
import AgencyPublicLayout from "./public/AgencyPublicLayout";
import AdminPublicLayout from "./public/AdminPublicLayout";
import AdminPrivateLayout from "./private/AdminPrivateLayout";
import OfficerTermsAndCondition from "../container/admin/pages/admincontentmanagement/officer-cms/terms-condition/OfficerTermsAndCondition";
import OfficerRefundPolicy from "../container/admin/pages/admincontentmanagement/officer-cms/refundpolicy/OfficerRefundPolicy";
import OfficerPrivacyPolicy from "../container/admin/pages/admincontentmanagement/officer-cms/privacypolicy/OfficerPrivacyPolicy";
import UserPrivacyPolicy from "../container/admin/pages/admincontentmanagement/user-cms/privacypolicy/UserPrivacyPolicy";
import UserRefundPolicy from "../container/admin/pages/admincontentmanagement/user-cms/refundpolicy/UserRefundPolicy";
import UserTermsAndCondition from "../container/admin/pages/admincontentmanagement/user-cms/terms-condition/UserTermsAndCondition";
import ZipCodeManagement from "../container/admin/pages/zipcode/ZipCodeManagement";
import JobTypeManagement from "../container/admin/pages/jobtype/JobTypeManagement";
import SkillsManagement from "../container/admin/pages/skillsmanagement/SkillsManagement";
import RatingAndReviewManagement from "../container/agency/pages/ratingandreviewmanagement/RatingAndReviewManagement";
import RoleMaster from "../container/admin/pages/roleMaster/List";
import AddRoleMaster from "../container/admin/pages/roleMaster/Add";
import Edit from "../container/admin/pages/roleMaster/Edit";
import ClientManage from "../container/admin/pages/client/ClientManage";
import ClientHistory from "../container/admin/pages/client/ClientHistory";
import AdminClientHistoryDetailsOfActivejob from "../container/admin/pages/client/AdminClientHistoryDetailsOfActivejob";
import AdminClientHistoryDetailsofcompletedjobs from "../container/admin/pages/client/AdminClientHistoryDetailsofcompletedjobs";
import Restricted from "../common/restrict/Restricted";
import EditAgency from "../container/agency/pages/roleMaster/EditAgency";
import RoleAddAgency from "../container/agency/pages/roleMaster/AddAgency";
import ListAgency from "../container/agency/pages/roleMaster/ListAgency";
import UserLoggedInAnotherDevice from "../common/userLoggedInAnotherDevice/UserLoggedInAnotherDevice";
import AgencyDetailsPendingOfficer from "../container/agency/pages/detailsofpendingofficer/AgencyDetailsPendingOfficer";
import AdminDetailsOfPendingOfficer from "../container/admin/pages/jobmanagement/AdminDetailsOfPendingOfficer";
import AdminJobHistoryView from "../container/admin/pages/officermanagement/AdminJobHistoryView";
import AgencyUserHistoryDetailsOfActivejob from "../container/agency/pages/usermanagement/AgencyUserHistoryDetailsOfActivejob";
import AgencyUserHistoryDetailsofcompletedjobs from "../container/agency/pages/usermanagement/AgencyUserHistoryDetailsofcompletedjobs";
// import VendorHistory from "../container/admin/pages/vendorManagement/VendorHistory";
import VendorHistoryDetailsOfCompletedJobs from "../container/agency/pages/vendorManagement/VendorHistoryDetailsOfCompletedJobs";
import VendorHistoryDetailsOfActivejob from "../container/agency/pages/vendorManagement/VendorHistoryDetailsOfActivejob";
import VendorManagement from "../container/agency/pages/vendorManagement/VendorManagement";
import VendorHistory from "../container/agency/pages/vendorManagement/VendorHistory";
import WorkPolicy from "../container/agency/pages/extraworkpermitrequests/WorkPolicy";
import TransferJob from "../container/agency/pages/transferjob/TransferJob";
import AgencyJobHistoryView from "../container/agency/pages/jobhistory/AgencyJobHistoryView";
import NotificationList from "../container/admin/pages/notification/NotificationList";
import PaymentPerCentageList from "../container/admin/pages/admin-payment-percentage/PaymentPerCentageList";
import GoogleAddress from "../container/admin/auth/GoogleAddress";
import AgencyPaymentLoader from "../container/agency/pages/paymentmanagement/AgencyPaymentLoader";
import AgencyChangePasswordPage from "../container/agency/pages/agentprofile/AgencyChangePasswordPage";
import AgencyPaymentType from "../container/agency/pages/paymentmanagement/AgencyPaymentType";
import AgencySkillManagement from "../container/agency/pages/skillmanagement/SkillManagement";
import JobEditPolicy from "../container/agency/pages/jobEditBeforeHour/JobEditPolicy";
import JobCancelPolicy from "../container/agency/pages/jobCancelBeforeHour/JobCancelPolicy";
import JobchatHistory from "../container/agency/pages/chatHistory/JobchatHistory";
const Routers = () => {
  const Layout = () => {
    return (
      <>
        {/* <Header /> */}
        {/* <SideBar /> */}
        {/* <Footer /> */}
        <Outlet />
      </>
    );
  };

  return (
    <>
      <Routes>
        {/* Admin Routes */}
        <Route element={<AdminPublicLayout />}>
          <Route path="/admin/">
            <Route path="" element={<AdminLogin />} />
            <Route path="forgotpassword" element={<ForgotPassword />} />
            <Route path="resetpassword" element={<ResetPassword />} />
            <Route path="otp-verification" element={<OtpVerification />} />
            <Route path="gooogle-api" element={<GoogleAddress />} />
          </Route>
        </Route>
        <Route element={<AdminPrivateLayout />}>
          <Route path="/admin" element={<LayOut />}>
            {/* dashboard */}
            <Route path="dashboard" element={<Home />} />
            <Route path="dashboard/view-report" element={<Viewreport />} />

            {/* zipocode */}
            <Route path="zipcode" element={<ZipCodeManagement />} />
            {/* jobtype */}
            <Route path="jobtype" element={<JobTypeManagement />} />
            {/* skills */}
            <Route path="skills" element={<SkillsManagement />} />

            {/* User management */}
            <Route path="usermanage" element={<UserManage />} />
            <Route path="usermanage/userhistory" element={<UserHistory />} />
            <Route
              path="usermanage/adminUserhistorydetailsofactivejob"
              element={<AdminUserHistoryDetailsOfActivejob />}
            />
            <Route
              path="usermanage/adminUserhistorydetailsofcompletedjobs"
              element={<AdminUserHistoryDetailsofcompletedjobs />}
            />
            {/* Client management */}
            <Route path="vendormanage" element={<ClientManage />} />
            <Route
              path="vendormanage/vendorhistory/:id"
              element={<ClientHistory />}
            />
            <Route
              path="vendormanage/vendormanagehistorydetailsofactivejob/:id"
              element={<AdminClientHistoryDetailsOfActivejob />}
            />
            <Route
              path="vendormanage/vendormanagehistorydetailsofcompletedjobs/:id"
              element={<AdminClientHistoryDetailsofcompletedjobs />}
            />
            {/* Officer management */}
            <Route path="officer-management" element={<OfficerManagement />} />
            <Route
              path="admin-officer-detail/:officerId"
              element={<AdminOfficerDetail />}
            />
            <Route
              path="officer-management/edit-detail"
              element={<EditOfficerDetail />}
            />
            {/* Agenct management */}
            <Route path="agentmanagement" element={<AgentManagement />} />
            <Route
              path="agentmanagement/adminagentdetails/:agentId"
              element={<AdminAgentDetails />}
            />

            <Route path="admin-job-history/:officerId" element={<AdminJobHistory />} />
            <Route
              path="admin-job-history-view/:officerId"
              element={<AdminJobHistoryView />}
            />

            <Route path="notification" element={<NotificationList />} />
            {/* extra work permit */}
            {/* <Route
              path="extra-work-permit-requests"
              element={<ExtraWorkPermitRequests />}
            /> */}

            <Route path="rating-management" element={<RatingManagement />} />
            {/* <Route
              path="admindetailsofactiveJobs"
              element={<AdminDetailsofcompletedjobs />}
            /> */}

            {/* job management */}
            <Route path="adminjobmanagement" element={<AdminJobManagement />} />
            <Route
              path="adminjobmanagement/:admindetailsofcompletedjobs"
              element={<AdminDetailsofcompletedjobs />}
            />
            <Route
              path="adminjobmanagement/admindetailsofpendingofficer"
              element={<AdminDetailsOfPendingOfficer />}
            />
            <Route
              path="detail-active-jobs"
              element={<DetailsOfActiveJobs />}
            />
            {/* <Route
              path="adminjobmanagementdetailsofcompletedjobs"
              element={<AdminJobManagementDetailsOfActivejob />}
            /> */}

            <Route
              path="adminagentdetailsactive/:agentId"
              element={<AdminAgentDetailsActive />}
            />
            <Route
              path="admingentdetailscompleted/:agentId"
              element={<AdminAgentDetailsCompleted />}
            />
            <Route
              path="adminextraworkpermit"
              element={<AdminExtraWorkPermit />}
            />
            <Route
              path="adminjobmanagementpendingjobrequest"
              element={<AdminJobManagementPendingJobRequest />}
            />
            <Route
              path="adminjobmanagementactivejobs"
              element={<AdminJobManagementActiveJobs />}
            />

            <Route
              path="adminJobmanagementcompletedjobs"
              element={<AdminJobManagementCompletedJobs />}
            />
            <Route
              path="adminratingandreviewmanagement"
              element={<AdminRatingAndReviewManagement />}
            />

            <Route
              path="payment-config"
              element={<AdminPaymentConfigManagement />}
            />
            <Route
              path="payment-percentage"
              element={<PaymentPerCentageList />}
            />

            {/* <Route path="role-management" element={<RoleList />} /> */}
            <Route path="role" element={<RoleMaster />} />
            <Route path="role/edit" element={<Edit />} />
            <Route path="role/add" element={<AddRoleMaster />} />
            {/* <Route path="add-role" element={<AddRole />} /> */}
            <Route
              path="adminpaymentmanagement"
              element={<AdminPaymentManagement />}
            />

            <Route path="adminprofile" element={<AdminProfile />} />
            <Route
              path="adminhelpandsupportrequests"
              element={<AdminHelpandSupportRequests />}
            />

            <Route
              path="admincategorymanagementplatformcategories"
              element={<AdminCategoryManagementPlatformCategories />}
            />

            {/* CMS Management */}
            <Route
              path="admincontentmanagement"
              element={<AdminContentManagement />}
            />
            {/* officer cms */}
            <Route
              path="admincontentmanagement/officer-privacy-policy"
              element={<OfficerPrivacyPolicy />}
            />
            <Route
              path="admincontentmanagement/officer-terms-conditions"
              element={<OfficerTermsAndCondition />}
            />
            <Route
              path="admincontentmanagement/officer-refund-policy"
              element={<OfficerRefundPolicy />}
            />
            {/* User cms */}
            <Route
              path="admincontentmanagement/user-privacy-policy"
              element={<UserPrivacyPolicy />}
            />
            <Route
              path="admincontentmanagement/user-terms-conditions"
              element={<UserTermsAndCondition />}
            />
            <Route
              path="admincontentmanagement/user-refund-policy"
              element={<UserRefundPolicy />}
            />
          </Route>
        </Route>

        {/* Agency Routes */}
        <Route element={<AgencyPublicLayout />}>
          <Route path="/">
            <Route path="agencysignup" element={<AgencySignUp />} />
            <Route path="change-password" element={<AgencyChangePasswordPage />} />
            <Route path="/" element={<AgencyLoginNew />} />
            <Route
              path="forgotpassword"
              element={<AgencyForgotPasswordNew />}
            />

            <Route path="resetpassword" element={<AgencyResetPasswordNew />} />
            <Route
              path="otpverification"
              element={<AgencyForgotPasswordOtpVerifiy />}
            />
            <Route path="agency-otp-verify" element={<AgencyOtpVerifiy />} />
            {/* <Route
              path="payment-config-success"
              element={<AgencyPaymentConfigureSuccess />}
            /> */}

          </Route>
        </Route>
        {/* Restricted */}
        <Route path="restrict-page" element={<Restricted />} />
        {/* Logged In Another device */}
        <Route
          path="logged-in-another-device"
          element={<UserLoggedInAnotherDevice />}
        />
        <Route element={<AgencyPrivateLayout />}>
          <Route path="/:role" element={<AgencyLayOut />}>

            {/* dashboard */}
            <Route path="dashboard" element={<AgencyHome />} />
            <Route path="agency-view-report" element={<AgencyViewreport />} />

            <Route path="notification" element={<AgencyNotification />} />

            {/* office management */}
            <Route
              path="officer-management"
              element={<AgencyOfficerManagement />}
            />
             <Route
              path="skill-management"
              element={<AgencySkillManagement />}
            />
            <Route
              path="officer-management-detail/:officerId"
              element={<DetailsOfPendingprofiles />}
            />

            {/* job management */}
            {/* <Route path="job-management" element={<JobManagement />} /> */}

            <Route
              path="agency-job-management"
              element={<AgencyJobManagement />}
            />
            <Route
              path="agency-job-management/detail-active-jobs"
              element={<AgencyJobManagementDetailActiveJobs />}
            />
            <Route
              path="agency-job-management/detail-of-completed-jobs"
              element={<AgencyDetailsofcompletedjobs />}
            />

            <Route
              path="agency-job-management/detail-of-pending-officer"
              element={<AgencyDetailsPendingOfficer />}
            />

            <Route path="work-policy" element={<WorkPolicy />} />

            <Route path="transfer-job" element={<TransferJob />} />

            <Route path="job-edit-before-hour" element={<JobEditPolicy />} />
            <Route path="job-cancel-before-hour" element={<JobCancelPolicy />} />
            <Route path="job-chat-history" element={<JobchatHistory />} />

            {/* <Route path="job-job" element={<TransferJob />} /> */}
            {/* Vendor management */}
            <Route path="vendor-management" element={<VendorManagement />} />
            <Route
              path="vendor-management/vendorhistory/:id"
              element={<VendorHistory />}
            />
            <Route
              path="vendor-management/vendorhistorydetailsofactivejob/:id"
              element={<VendorHistoryDetailsOfActivejob />}
            />
            <Route
              path="vendor-management/vendorhistorydetailsofcompletedjobs/:id"
              element={<VendorHistoryDetailsOfCompletedJobs />}
            />

            <Route
              path="rating-review-management"
              element={<RatingAndReviewManagement />}
            />
            {/* Agency User management */}
            <Route path="agency-usermanage" element={<AgencyUserManage />} />
            <Route
              path="agency-usermanage/userhistory"
              element={<AgencyUserHistory />}
            />
            <Route
              path="usermanage-adminUserhistorydetailsofactivejob"
              element={<AgencyUserHistoryDetailsOfActivejob />}
            />
            <Route
              path="agency-usermanage/adminUserhistorydetailsofcompletedjobs"
              element={<AgencyUserHistoryDetailsofcompletedjobs />}
            />
            {/* Agency role master */}
            <Route path="role" element={<ListAgency />} />
            <Route path="role/edit" element={<EditAgency />} />
            <Route path="role/add" element={<RoleAddAgency />} />

            {/* <Route
              path="detail-active-jobs"
              element={<DetailsOfActiveJobs />}
            /> */}

            <Route path="job-history/:officerId" element={<AgencyJobHistory />} />
            <Route path="job-history-view/:officerId" element={<AgencyJobHistoryView />} />
            <Route
              path="agency-payment-management"
              element={<AgencyPaymentManagement />}
            />

            <Route
              path="payment-config-success"
              element={<AgencyPaymentConfigureSuccess />}
            />

            <Route
              path="profile"
              element={<AgencyAgentProfile />}
            />
            <Route
              path="loaderPage"
              element={<AgencyPaymentLoader />}
            />

            <Route
              path="payment-type"
              element={<AgencyPaymentType />}
            />

            <Route path="officer-detail" element={<OfficerDetails />} />
            <Route
              path="edit-officer-detail/:officerId"
              element={<AgencyEditOfficerDetail />}
            />
          </Route>
        </Route>

        {/* <Route element={<PublicLayout />}>
          </Route> */}

        {/* start Admin */}

        {/* <Route element={<PrivateLayout />}>
            <Route path="user" element={<Layout />}>
              <Route path="dashboard" element={<Dashboard />} />
            </Route>
          </Route> */}
        {/* end Admin */}
      </Routes>
    </>
  );
};

export default Routers;
