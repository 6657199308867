import React from "react";
// import { Box, FormControl, Select, MenuItem, TextField, FormHelperText } from "@mui/material";
import PagesIndex from "../../../PageIndex";
import Index from "../../..";



const FormField = ({
    name,
    type = "text",
    label,
    value,
    options = [],
    onChange,
    onBlur,
    error,
    touched,
    placeholder = "",
}) => {
    return (
        console.log("options",options),
        <Index.Box className="input-box">
            <Index.FormHelperText className="form-label">{label}</Index.FormHelperText>
            {type === "dropdown" ? (
                <Index.Box className="input-box modal-input-box">
                    <Index.Box className="dropdown-box">
                        <Index.FormControl className="form-control drop-form-control">
                            <Index.Select
                                className="dropdown-select drop-select"
                                displayEmpty
                                inputProps={{ "aria-label": "Without label" }}
                                name={name}
                                value={value}
                                onChange={onChange}
                                renderValue={
                                    value ? undefined : () => <span>{placeholder}</span>
                                }
                                error={Boolean(error && touched)}
                            >
                                {options && options.length > 0
                                    ? options.map((data, index) => {
                                        return (
                                            <Index.MenuItem
                                                key={index}
                                                value={data?.title}
                                                className="drop-menuitem"
                                            // onClick={()=>getDepartmentByAgencyList(data?.title)}
                                            >
                                                {data?.title}
                                            </Index.MenuItem>
                                        );
                                    })
                                    : null}
                            </Index.Select>
                        </Index.FormControl>

                    </Index.Box>
                    {error && touched && <Index.FormHelperText error>{error}</Index.FormHelperText>}
                </Index.Box>
            ) : (
                <Index.TextField
                    name={name}
                    type={type}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    className="text-input"
                    placeholder={placeholder}
                    error={Boolean(error && touched)}
                    helperText={error && touched ? error : ""}
                    fullWidth
                />
            )}
            {error && touched && <Index.FormHelperText error>{error}</Index.FormHelperText>}
        </Index.Box>
    );
};

export default FormField;