import React, { useEffect, useRef, useState } from "react";
import Index from "../../../..";
import PagesIndex from "../../../../PageIndex";
import DataService from "../../../../../config/DataService";
import { newAgencyEditJobSchema } from "../../../../../validation/FormikSchema";
import Loader from "../../../../../common/loader/Loader";
import { useDispatch } from "react-redux";
import { getJobTypeAgency } from "../../../../../redux-toolkit/slice/agency-slice/AgencyServices";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import { toast } from "react-toastify";
import { CollectionsOutlined } from "@mui/icons-material";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
};

const JobAssignToOfficer = (props) => {
    const dispatch = useDispatch();
    const {
        agencyId,
        jobId,
        assignJobModalopen,
        handleAssignJobModalClose,
        getJobListData,
        tabValue,
    } = props;

    const [fetchOfficer, setOfficerData] = useState([]);
    const [jobSchedule, setJObScheduleData] = useState([]);
    const [jobScheduleIds, setJobScheduleIds] = useState([]);
    const [isApiPending, setApiPending] = useState(false);

    const fetchOfficerList = async (agencyId) => {
        // setLoading(true);
        try {
            if (!agencyId) {
                return false;
            }
            const response = await DataService.get(
                `${PagesIndex.Api.Agency.FETCH_OFFICER_LIST}/${agencyId}`);
            if (response?.data?.status === 200) {
                // setLoading(false);
                setOfficerData(response?.data?.data);
            }
        } catch (error) {
            // setLoading(true);
            PagesIndex.toast.error(error?.response?.data?.message);
        }
    };

    const fetchJobScheduleData = async (jobId) => {
        try {
            if (!agencyId) {
                return false;
            }
            const response = await DataService.get(
                `${PagesIndex.Api.Agency.FETCH_JOB_SCHEDULE_DATA}/${jobId}`);
            if (response?.data?.status === 200) {
                setJObScheduleData(response?.data?.data);
            }

        } catch (error) {
            console.log("62", error)
        }
    }
    PagesIndex.useEffect(() => {
        fetchOfficerList(agencyId);
        fetchJobScheduleData(jobId);
    }, [agencyId, jobId]);



    // const handleToggleJobSelection = (id) => {
    //     setJobScheduleIds((previous) => {
    //         if (previous.includes(id)) {
    //             // Remove the id if it's already selected
    //             return previous.filter(jobId => jobId !== id);
    //         } else {
    //             // Add the id if it's not already selected
    //             return [...previous, id];
    //         }
    //     });
    // };


    const handleToggleJobSelection = (id) => {
        setJobScheduleIds((previous) => {
            if (previous.includes(id)) {
                return previous.filter(jobId => jobId !== id);
            } else {
                if (previous.length > 0) {
                    PagesIndex.toast.error("Please select only one date");
                    return previous;  // Prevent adding another date
                } else {
                    return [id];  // Add the selected date
                }
            }
        });
    };


    const handleOfficerAssign = async (officerId) => {
        try {
            // return true;
            setApiPending(true);

            if (!agencyId || !jobId || !jobScheduleIds.length) {
                PagesIndex.toast.error("Please select at least one date and one officer to assign a job");
                setApiPending(false);
                return true;
            }


            if (!officerId) {
                PagesIndex.toast.error("Something went wrong.");
                setApiPending(false);
                return false;
            }
            const payload = {
                agencyId,
                jobId,
                jobScheduleIds,
                officerId
            }
            const response = await DataService.post(
                `${PagesIndex.Api.Agency.ASSIGN_OFFICER}`, payload);
            if (response?.data?.status === 200) {
                setApiPending(false);
                handleAssignJobModalClose();
                getJobListData();
                PagesIndex.toast.success(response?.data?.message);
            }

        } catch (error) {
            setApiPending(false);
            PagesIndex.toast.error(error.response?.data?.message);
        }

    }





    return (
        <>
            <Index.Modal
                open={assignJobModalopen}
                onClose={handleAssignJobModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="modal"
            >
                <Index.Box
                    sx={style}
                    className="agency-job-modal-inner-main modal-inner"
                >
                    <Index.Box className="modal-header">
                        <Index.Typography
                            id="modal-modal-title"
                            className="modal-title"
                            variant="h6"
                            component="h2"
                        >
                            {/* Edit Job {agencyEditJobData?.aliasJobId} */}
                        </Index.Typography>
                        <img
                            src={PagesIndex.Svg.closeblack}
                            className="modal-close-icon"
                            onClick={handleAssignJobModalClose}
                            alt=""
                        />
                    </Index.Box>

                    <Index.Box className="common-card job-mamangment-card">
                        <Index.Box className="admin-sub-flex">


                            <Index.Box className="common-btn-flex">
                                <Index.Box className="progress-job-box">
                                    <Index.Typography
                                        component="p"
                                        variant="p"
                                        className="status_red"
                                    >
                                        {/* {item?.isCancelled
                                        ? "Cancelled"
                                        : ""} */}
                                    </Index.Typography>
                                </Index.Box>

                                {jobSchedule.length > 0 && (
                                    jobSchedule.map((item) => {
                                        const isSelected = jobScheduleIds.includes(item?.jobId);  // Check if the item is selected
                                        return (
                                            <Index.Box className="btn-main-primary" key={item?.jobId}>
                                                <Index.PrimaryButton
                                                    btnLabel={`${PagesIndex.dayjs(
                                                        item.jobStartDate
                                                    ).format("MM-DD-YYYY")}  total Officer: ${item?.totalOfficer}`}
                                                    className={`btn-primary ${isSelected ? 'selected' : 'unselected'}`} // Toggle class for styling
                                                    onClick={() => handleToggleJobSelection(item?.jobId)}
                                                />
                                            </Index.Box>
                                        );
                                    })
                                )}
                            </Index.Box>
                        </Index.Box>
                        {
                            isApiPending ? (
                                <Loader />
                            ) : (
                                fetchOfficer.length > 0 ? (
                                    fetchOfficer.map((item, index) => (
                                        <Index.Box key={item?._id} className="card-main">
                                            <Index.Box className="card-left">
                                                <Index.Box className="officer-profile-flex">
                                                    <img
                                                        src={
                                                            item?.image
                                                                ? `${PagesIndex.ADMIN_IMAGE_ENDPOINT}/${item?.image}`
                                                                : PagesIndex.Png.usericon
                                                        }
                                                        alt=""
                                                        className="prof-img"
                                                    />
                                                    <Index.Box className="officer-detail">

                                                        <Index.Typography
                                                            component="p"
                                                            variant="p"
                                                            // className="officer-detail-title"
                                                            className="job-title-label"
                                                        >
                                                            {item?.firstName + " " + item?.lastName}
                                                            {item?.isBuild == true ? (
                                                                <>
                                                                    {item?.jobName == "Supervisor" ? (
                                                                        <Index.Box className="admin-list-pd-btn-main">
                                                                            <Index.PrimaryButton
                                                                                btnLabel="Supervisor "
                                                                                className="admin-list-pd-btn"
                                                                            />
                                                                        </Index.Box>
                                                                    ) : (
                                                                        <Index.Box className="admin-list-pd-btn-main">
                                                                            <Index.PrimaryButton
                                                                                btnLabel="Non-Supervisor"
                                                                                className="admin-list-pd-btn"
                                                                            />
                                                                        </Index.Box>
                                                                    )}
                                                                </>
                                                            ) : (null)}

                                                        </Index.Typography>

                                                        <Index.Typography
                                                            component="p"
                                                            variant="p"
                                                            className="officer-detail-email"
                                                        >
                                                            {item?.email}
                                                        </Index.Typography>

                                                        <Index.Typography
                                                            component="p"
                                                            variant="p"
                                                            className="officer-detail-email"
                                                        >
                                                            {item?.officerType}
                                                        </Index.Typography>

                                                    </Index.Box>
                                                    {/* <Index.Box className="work-title-flex">
                                                <Index.Typography
                                                    component="p"
                                                    variant="p"
                                                    className="work-title-lable"
                                                >
                                                    Posted By :&nbsp;
                                                </Index.Typography>
                                                <Index.Typography
                                                    component="p"
                                                    variant="p"
                                                    className="work-value-text"
                                                >
                                                </Index.Typography>
                                                <Index.Box className="btn-main-primary">
                                                    <Index.PrimaryButton
                                                        btnLabel="Assign"
                                                        className="btn-primary"
                                                    />
                                                </Index.Box>
                                            </Index.Box> */}




                                                </Index.Box>
                                            </Index.Box>
                                            <Index.Box className="card-right">
                                                <Index.Box className="card-right btn-main-secondary btn-main-red btn-main-orange btn-main-yellow">
                                                    <Index.Box className="btn-main-primary">
                                                        <Index.PrimaryButton
                                                            btnLabel={"Assign"}
                                                            className="btn-primary"
                                                            onClick={() => handleOfficerAssign(item?._id)}
                                                        />
                                                    </Index.Box>
                                                </Index.Box>
                                            </Index.Box>
                                        </Index.Box>

                                    ))

                                ) : (
                                    <PagesIndex.NoDataFound />

                                )
                            )


                        }



                    </Index.Box>
                </Index.Box>

            </Index.Modal>
        </>
    );
};

export default JobAssignToOfficer;
